import React, { Component } from "react"; 
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-date-picker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import Slide from '@mui/material/Slide';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProjetMenu  from "../../Layouts/ProjetMenu"; 
import Datee from "../../Components/App/Datee"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 400,
    fontSize: 14,
    border: '1px solid #dadde9',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default class Rapports extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rapports: [],
            projects:[],
            bordereaux: [],
            rapport_materiels:[],
            rapport_fournitures: [],
            approvisionnements: [],
            rapport_personnels: [],
            rapport_traitants:[],
            rapport_caisses:[],
            materiels:[],
            personnels:[],
            fournitures: [],
            traitants: [],
            rapport_pieces: [],
            pieces: [],
            appropieces: [],

            borderauselected:[],

            projetId: this.props.projetId,
            id_rapport: null,
            isDid: false,
            isNotDit:false,
            errorMateriel:false,      
            errorFourniture: false,      
            EmptyNumPrix:false,

            open:false, 
            openRapport: true,
            openMateriel:false,
            openPiece: false,
            openPersonnel: false,
            openEditPersonnel: false,
            openFourniture: false,
            openTraitant: false,
            openEditTraitant: false,
            openCaisse: false,
            openEditCaisse:false,
            loading: true,

            fait: new Date(),
            temps: "Beau",
            id_borderau:null,
            quantite: 1,
            observation:"",

            id_rapport_materiel:null,
            // materiel            
            id_materiel:null,
            hj_materiel:0,
            carburant_materiel:0,
            arret_materiel:0,
            immobilisation:0,
            compteurdepart:0,
            compteurarrive:0,
            nbvoyage:1,
            pusure:0,
            designationpusure:"",
            fait_materiel: new Date(),
            //Piece
            id_pieceusure: null,
            quantite_piece:0,
            //personnel
            id_rapportpersonnel: null,
            id_personnel: null,
            heure_personnel: 0,
            // Fourniture
            id_fourniture: null,
            quantite_fourniture: 0,
            //Sous Traitant
            id_rapport_traitant: null,
            id_traitant: null,
            quantite_traitant:0,
            // Caisse
            id_caisse: null,
            designation_caisse: "",
            somme_caisse: 0,

            valuetab:"1"
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleEditerSubmit = this.handleEditerSubmit.bind(this)
        this.handleSubmitMateriel = this.handleSubmitMateriel.bind(this)
        this.handleSubmitPersonnel = this.handleSubmitPersonnel.bind(this)
        this.handleSubmitFourniture = this.handleSubmitFourniture.bind(this)
        this.handleSubmitTraitant = this.handleSubmitTraitant.bind(this)
        this.handleSubmitCaisse = this.handleSubmitCaisse.bind(this)
        this.handleEditerCaiseSubmit = this.handleEditerCaiseSubmit.bind(this)
        this.handleEditTraitantSubmit = this.handleEditTraitantSubmit.bind(this)
        this.handleEditerPersonnelSubmit = this.handleEditerPersonnelSubmit.bind(this)
        this.handleSubmitPiece = this.handleSubmitPiece.bind(this)
        this.idchange = this.idchange.bind(this)
        this.dateChange = this.dateChange.bind(this)
        this.handleChangetab = this.handleChangetab.bind(this)
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            this.setState({ 
                rapports: response.data.rapports,
                projects: response.data.projects,
                bordereaux: response.data.bordereaux,
                rapport_materiels: response.data.rapport_materiels,
                materiels: response.data.materiels,
                rapport_fournitures: response.data.rapport_fournitures,
                approvisionnements: response.data.approvisionnements,
                rapport_personnels: response.data.rapport_personnels,
                personnels: response.data.personnels,
                fournitures: response.data.fournitures,
                rapport_traitants: response.data.rapport_traitants,
                traitants: response.data.traitants,
                rapport_caisses: response.data.rapport_caisses,
                rapport_pieces: response.data.rapport_pieces,
                pieces: response.data.pieces,
                appropieces: response.data.appropieces,
                loading: false
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleSubmit(id_borderau, date) {
        const fait = Math.floor(date.getTime() / 1000)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };        

        const bodyParameters = {
            fait: fait,
            id_projet: this.props.projetId,
            id_bordereau: id_borderau, 
            temps: this.state.temps,
            quantite: this.state.quantite,
            observation: this.state.observation
        };        
        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport', 
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)            
            this.setState({id_rapport: response.data.id, observation: response.data.observation, quantite:response.data.quantite, temps:response.data.temps });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    handleEditerSubmit(event) {
        event.preventDefault()
        const fait = Math.floor(new Date(this.state.fait).getTime() / 1000)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };        

        const bodyParameters = {
            fait: fait,
            id_projet: this.props.projetId,
            id_bordereau: this.state.id_borderau, 
            temps: this.state.temps,
            quantite: this.state.quantite,
            observation: this.state.observation
        };      
        
        axios.put( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport/' + this.state.id_rapport,
            bodyParameters,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    handleSubmitMateriel(event) {
        event.preventDefault()

        const fait = Math.floor(new Date().getTime() / 1000)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };   

        const materiel = this.state.materiels.filter(c => c["id"] === this.state.id_materiel)
        
        const bodyParameters = {
            fait: fait,
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            id_materiel: this.state.id_materiel, 
            hj: Number(this.state.hj_materiel),
            carburant: Number(this.state.carburant_materiel),
            arret: Number(this.state.arret_materiel),
            taux: materiel[0].taux,
            immobilisation: this.state.immobilisation,
            compteurdepart: this.state.compteurdepart,
            compteurarrive: this.state.compteurarrive,
            nbvoyage: this.state.nbvoyage,
            pusure: this.state.pusure,
            designation: this.state.designationpusure,
        };  

        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-materiel',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openMateriel:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });
    
    }

    handleSubmitPersonnel(event) {
        event.preventDefault()

        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };    
        
        let hoursbyday = this.state.projects.filter(c => c["id"] === this.props.projetId)[0]
        hoursbyday = hoursbyday.hoursbyday

        const personnel = this.state.personnels.filter(c => c["id"] === this.state.id_personnel)[0]
        const unite = personnel.unite

        let bodyParameters = {}

        if(unite === "Heure"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_personnel: this.state.id_personnel, 
                heures: this.state.heure_personnel,
                taux: personnel.taux,
            }
        }else if(unite === "Jour"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_personnel: this.state.id_personnel, 
                heures: this.state.heure_personnel,
                taux: (personnel.taux)/hoursbyday,
            }
        }else if(unite === "Mois"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_personnel: this.state.id_personnel, 
                heures: this.state.heure_personnel,
                taux: (personnel.taux)/(30 * hoursbyday),
            }
        }     

        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-personnel',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openPersonnel:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });
    
    }

    handleEditerPersonnelSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        let hoursbyday = this.state.projects.filter(c => c["id"] === this.props.projetId)[0]
        hoursbyday = hoursbyday.hoursbyday

        const personnel = this.state.personnels.filter(c => c["id"] === this.state.id_personnel)[0]
        const unite = personnel.unite

        let bodyParameters = {}

        if(unite === "Heure"){
            bodyParameters = {
                id_personnel: this.state.id_personnel, 
                heures: this.state.heure_personnel,
                taux: personnel.taux,
            }
        }else if(unite === "Jour"){
            bodyParameters = {
                id_personnel: this.state.id_personnel, 
                heures: this.state.heure_personnel,
                taux: (personnel.taux)/hoursbyday,
            }
        }else if(unite === "Mois"){
            bodyParameters = {
                id_personnel: this.state.id_personnel, 
                heures: this.state.heure_personnel,
                taux: (personnel.taux)/(30 * hoursbyday),
            }
        } 
        axios.put( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-personnel/' + this.state.id_rapportpersonnel,
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openEditPersonnel:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    handleSubmitFourniture(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };   

        const fourniture = this.state.fournitures.filter(c => c["id"] === this.state.id_fourniture )

        const bodyParameters = {
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            id_fourniture: this.state.id_fourniture, 
            quantite: this.state.quantite_fourniture
        };       

        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-fourniture',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openFourniture:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorFourniture: true });
            console.log("error", error)
        });
    
    }

    handleSubmitPiece(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        const bodyParameters = {
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            id_pieceusure: this.state.id_pieceusure, 
            id_materiel: this.state.id_materiel,
            quantite: this.state.quantite_piece
        };       

        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-piece',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openPiece:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorFourniture: true });
            console.log("error", error)
        });
    
    }

    handleSubmitCaisse(event) {
        event.preventDefault()

        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };         

        const bodyParameters = {
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            designation: this.state.designation_caisse,
            somme: this.state.somme_caisse
        };       

        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-caisse',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openCaisse:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });
    
    }

    handleEditerCaiseSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        const bodyParameters = {
            id_projet: this.props.projetId,
            id_rapport: this.state.id_rapport,
            designation: this.state.designation_caisse,
            somme: this.state.somme_caisse
        };
        axios.put('https://api-gn.projectmanagementsolutions.net/api/rapport-caisse/' + this.state.id_caisse,
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openEditCaisse:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    handleSubmitTraitant(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        let hoursbyday = this.state.projects.filter(c => c["id"] === this.props.projetId)[0]
        hoursbyday = hoursbyday.hoursbyday 

        const traitant = this.state.traitants.filter(c => c["id"] === this.state.id_traitant)[0]
        const unite = traitant.unite

        let bodyParameters = {}
        if(unite === "Heure"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: traitant.prix,
            }
        }else if(unite === "Jour"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: (traitant.prix)/hoursbyday,
            }
        }else if(unite === "Mois"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: (traitant.prix)/(30 * hoursbyday),
            }
        }   

        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-traitant',
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openTraitant:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    handleEditTraitantSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        let hoursbyday = this.state.projects.filter(c => c["id"] === this.props.projetId)[0]
        hoursbyday = hoursbyday.hoursbyday 

        const traitant = this.state.traitants.filter(c => c["id"] === this.state.id_traitant)[0]
        const unite = traitant.unite

        let bodyParameters = {}
        if(unite === "Heure"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: traitant.prix,
            }
        }else if(unite === "Jour"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: (traitant.prix)/hoursbyday,
            }
        }else if(unite === "Mois"){
            bodyParameters = {
                id_projet: this.props.projetId,
                id_rapport: this.state.id_rapport,
                id_traitant: this.state.id_traitant, 
                quantite: this.state.quantite_traitant,
                taux: (traitant.prix)/(30 * hoursbyday),
            }
        }   
        
        axios.put( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-traitant/' + this.state.id_rapport_traitant,
            bodyParameters,
            config
        ).then(response => {        
            console.log("return", response.data)    
            this.setState({ isDid: true, openEditTraitant:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ errorMateriel: true });
            console.log("error", error)
        });    
    }

    handleChangeDateStart(date){ 
        this.setState({fait: date})
        if(this.state.id_borderau !== null){
            this.setState({disabledButton: true})
            this.handleSubmit(this.state.id_borderau, date)
        }
    
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        if(e.target.name === "id_borderau"){
            const borderauselected = this.state.bordereaux.filter(c => c["id"] === e.target.value)
            this.setState({ borderauselected: borderauselected[0] });
            this.handleSubmit(e.target.value, this.state.fait)
        }
    }

    handleChangetab(event, newValue){
        this.setState({ valuetab: newValue});
    }

    editcaisse(id){
        const caisse = this.state.rapport_caisses.filter(c => c["id"] === id)
        this.setState({
            id_caisse: id,
            id_projet: this.props.projetId,
            designation_caisse: caisse[0].	designation,
            somme_caisse: caisse[0].somme,
            openEditCaisse: true
        })
    }

    editsoustraitant(id){
        const traitant = this.state.rapport_traitants.filter(c => c["id"] === id)
        this.setState({
            id_rapport_traitant: id,
            id_traitant: traitant[0].id_traitant,
            quantite_traitant: traitant[0].quantite,
            openEditTraitant: true
        })
    }

    editpersonnel(id){
        const item = this.state.rapport_personnels.filter(c => c["id"] === id)
        this.setState({
            id_rapportpersonnel: id,
            id_personnel: item[0].id_personnel,
            heure_personnel: item[0].heures,
            openEditPersonnel: true
        })
    }

    delete(id){ 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport/' + id, 
            config
        ).then(response => {         
            console.log("return", response.data)            
            this.setState({ isDid: true, voir:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    showRapport(id){
        const rapport = this.state.rapports.filter(c => c["id"] === id)
        this.setState({
            id_rapport: id,
            fait: new Date(rapport[0].fait *1000),
            id_borderau: rapport[0].id_bordereau, 
            temps: rapport[0].temps,
            borderauselected: this.state.bordereaux.filter(c => c["id"] === rapport[0].id_bordereau)[0],
            quantite: rapport[0].quantite,
            observation: rapport[0].observation,
            openRapport: true
        })
    }

    deleteMateriel(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-materiel/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deletepersonnel(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-personnel/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deletetraitant(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-traitant/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deletecaisse(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-caisse/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deleteFourniture(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-fourniture/' + id,
            config
        ).then(response => {             
            this.setState({ isDid: true });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deletePiece(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-piece/' + id,
            config
        ).then(response => {             
            this.setState({ isDid: true });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    deleteMateriel(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/rapport-materiel/' + id,
            config
        ).then(response => {   
            console.log("return", response.data)   
            this.setState({ isDid: true });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });
    }

    idchange(id){ 
        this.setState({
            projetId: id
        })
    }

    dateChange(date){
        this.setState({fait: date})

        if(this.state.id_borderau !== null){
            this.handleSubmit(this.state.id_borderau, date)
        }
    }


    render() {

        const { valuetab, loading, rapport_pieces, pieces, id_pieceusure, quantite_piece, appropieces, EmptyNumPrix, designationpusure, projetId, immobilisation, compteurdepart, compteurarrive, nbvoyage, pusure, openEditPersonnel, openEditTraitant, openEditCaisse, errorFourniture, quantite_fourniture, fournitures, id_fourniture, approvisionnements, openFourniture, rapport_fournitures, designation_caisse, somme_caisse,  openCaisse, rapport_caisses, id_traitant, quantite_traitant, openTraitant, traitants, rapport_traitants, heure_personnel, id_personnel, openPersonnel, personnels, rapport_personnels, errorMateriel, id_materiel, carburant_materiel, arret_materiel, hj_materiel, rapports, rapport_materiels, materiels, openMateriel, openPiece, openRapport, id_rapport, isDid, isNotDit, open, fait, temps, bordereaux, id_borderau, borderauselected, quantite, observation } = this.state

        const id_project = projetId
        
        const bordereauxToSelect = bordereaux.filter(c => c["id_projet"] === id_project)

        const rapport_materiel = () => {
            let resultat = []
            resultat = rapport_materiels.filter(c => c["id_rapport"] === id_rapport)
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_materiel === ele.id_materiel))
            return pp
        }

        const rapport_fourniture = () => {
            let resultat = []
            resultat = rapport_fournitures.filter(c => c["id_rapport"] === id_rapport) 
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_fourniture	 === ele.id_fourniture	))
            return pp
        }

        const rapport_piece = () => {
            let resultat = []
            resultat = rapport_pieces.filter(c => c["id_rapport"] === id_rapport) 
            return resultat
        }

        const rapport_personnel = () => {
            let resultat = []
            resultat = rapport_personnels.filter(c => c["id_rapport"] === id_rapport)
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_personnel === ele.id_personnel))
            return pp
        }

        const rapport_traitant = () => {
            let resultat = []
            resultat = rapport_traitants.filter(c => c["id_rapport"] === id_rapport)
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_traitant === ele.id_traitant))
            return pp
        }

        const rapport_caisse = rapport_caisses.filter(c => c["id_rapport"] === id_rapport)        

        let rapport = []
        if(id_project === null){
            rapport = []
        }else{
            rapport = rapports.filter(c => c["id_projet"] === id_project)
        }

        let materiel = []
        if(id_project === null){
            materiel = []
        }else{
            materiel = materiels.filter(c => c["id_projet"] === id_project)
        }
        

        let personnel = []
        if(id_project === null){
            personnel = []
        }else{
            personnel = personnels.filter(c => c["id_projet"] === id_project)
        }

        let traitant = []
        if(id_project === null){
            traitant = []
        }else{
            traitant = traitants.filter(c => c["id_projet"] === id_project)
        }

        let approvisionnement = []
        if(id_project === null){
            approvisionnement = []
        }else{
            approvisionnement = approvisionnements.filter(c => c["id_projet"] === id_project)
        }

        let appropiece = []
        if(id_project === null){
            appropiece = []
        }else{
            appropiece = appropieces.filter(c => c["id_projet"] === id_project)
        }

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const displayBordereau = (id_borderau) => {
            let resultat = ""
            resultat = bordereaux.filter(c => c["id"] === id_borderau)[0].n_prix           
            return "#" + resultat
        }

        const displayBordereauDesign = (id_borderau) => {
            let resultat = ""
            resultat = bordereaux.filter(c => c["id"] === id_borderau)[0].designation           
            return resultat
        }

        const displayGM = (id) => {
            let resultat = ""
            resultat = materiels.filter(c => c["id"] === id)[0].gm           
            return resultat
        }

        const displayType  = (id) => {
            let resultat = ""
            resultat = materiels.filter(c => c["id"] === id)[0].type           
            return resultat
        }

        const displaychassis = (id) => {
            let resultat = ""
            resultat = materiels.filter(c => c["id"] === id)[0].chassis           
            return resultat
        }

        const displayName  = (id) => {
            let resultat = ""
            resultat = personnels.filter(c => c["id"] === id)[0].name           
            return resultat
        }

        const displayDescr = (id) => {
            let resultat = ""
            resultat = personnels.filter(c => c["id"] === id)[0].description           
            return resultat
        }

        const displayCategorie = (id) => {
            let resultat = ""
            resultat = personnels.filter(c => c["id"] === id)[0].categorie           
            return resultat
        }

        const displayCode= (id) => {
            let resultat = ""
            resultat = traitants.filter(c => c["id"] === id)[0].code           
            return resultat
        }

        const displayDesign = (id) => {
            let resultat = ""
            resultat = traitants.filter(c => c["id"] === id)[0].designation           
            return resultat
        }

        const displayFournitureCode= (id) => {
            let resultat = ""
            resultat =  fournitures.filter(c => c["id"] === id)[0].code           
            return resultat
        }

        const displayFournitureDesign = (id) => {
            let resultat = ""
            resultat =  fournitures.filter(c => c["id"] === id)[0].designation           
            return resultat
        }

        const displayPieceDesign = (id) => {
            let resultat = ""
            if(pieces.filter(c => c["id"] === id)[0] !== undefined){
                resultat =  pieces.filter(c => c["id"] === id)[0].designation      
            }     
            return resultat
        }

        const columns = [
            { 
                field: 'fait', 
                headerName: 'Date du  rapport',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayCreation(cellValues.row.fait);}
            },
            { 
                field: 'n_prix', 
                headerName: 'N° prix',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayBordereau(cellValues.row.id_bordereau);}
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 7/5,
                renderCell: (cellValues) => {return  displayBordereauDesign(cellValues.row.id_bordereau);}
            },
            { 
                field: 'Voir', 
                headerName: '',
                flex: 1/5,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { this.showRapport(cellValues.row.id) }}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#2f4cdd', color:'white'}}}
                        >
                            Détails
                        </Button>
                    )
                }
            },
            { 
                field: 'supprimer', 
                headerName: '',
                flex: 1/5,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                }
            }
        ]

        const columnsMateriel = [
            { 
                field: 'gm', 
                headerName: 'Machine',
                flex: 1/11,
                renderCell: (cellValues) => {return  displayGM(cellValues.row.id_materiel)}
            },
            { 
                field: 'type', 
                headerName: 'Modèle',
                flex: 1/11,
                renderCell: (cellValues) => {return  displayType(cellValues.row.id_materiel)}
            },  
            { 
                field: 'chassis', 
                headerName: 'N° Chassis',
                flex: 1/11,
                renderCell: (cellValues) => {return displaychassis(cellValues.row.id_materiel)}
            },            
            { 
                field: 'carburant', 
                headerName: 'Carburant',
                flex: 1/11,
                renderCell: (cellValues) => {return  displaycar(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'hj', 
                headerName: "H. d'util",
                flex: 1/11,
                renderCell: (cellValues) => {return  displayHJ(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'arret', 
                headerName: "H. d'arrêt",
                flex: 1/11,
                renderCell: (cellValues) => {return  displayAR(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },     
            { 
                field: 'immobilisation', 
                headerName: "H. d'immo.",
                flex: 1/11,
                renderCell: (cellValues) => {return  displayImm(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },  
            { 
                field: 'compteurdepart', 
                headerName: "Cpt au dem(KM)",
                flex: 1/11,
                renderCell: (cellValues) => {return  displayDepart(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            }, 
            { 
                field: 'compteurarrive', 
                headerName: "Cpt à l'arri.(KM)",
                flex: 1/11,
                renderCell: (cellValues) => {return  displayArriver(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            }, 
            { 
                field: 'nbvoyage', 
                headerName: "Nbre de voyages",
                flex: 1/11,
                renderCell: (cellValues) => {return  displaynbvoyage(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'supprimer', 
                headerName: '',
                flex: 1/11,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deleteMateriel(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                    
                }
            }
        ]

        const columnsFourniture =  [
            { 
                field: 'code', 
                headerName: 'Code',
                flex: 2/4,                     
                renderCell: (cellValues) => {return  displayFournitureCode(cellValues.row.id_fourniture)}
            },
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 8/4,                
                renderCell: (cellValues) => {return  displayFournitureDesign(cellValues.row.id_fourniture)}
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité',
                flex: 2/4,
                renderCell: (cellValues) => {return  displayQtéMaterieaux(cellValues.row.id_fourniture, cellValues.row.id_rapport).toLocaleString()}
            },        
            { 
                field: 'supprimer', 
                headerName: '',
                flex: 1/3,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deleteFourniture(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                    
                }
            }
        ]

        const columnsPiece = [
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 2,                
                renderCell: (cellValues) => {return  displayPieceDesign(cellValues.row.id_pieceusure)}
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité',
                flex: 1,
                renderCell: (cellValues) => {return cellValues.row.quantite.toLocaleString()}
            },    
            { 
                field: 'gm', 
                headerName: 'Machine',
                flex: 1,
                renderCell: (cellValues) => {return  displayGM(cellValues.row.id_materiel)}
            },
            { 
                field: 'type', 
                headerName: 'Modèle',
                flex: 1,
                renderCell: (cellValues) => {return displayType(cellValues.row.id_materiel)}
            },    
            { 
                field: 'chassis', 
                headerName: 'N° Chassis',
                flex: 1,
                renderCell: (cellValues) => {return displaychassis(cellValues.row.id_materiel)}
            },           
            { 
                field: 'supprimer', 
                headerName: '',
                flex: 1/2,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deletePiece(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )                    
                }
            }
        ]

        const columnsPersonnel = [
            { 
                field: 'name', 
                headerName: 'Personnel',
                flex: 3/6,
                renderCell: (cellValues) => {return  displayName(cellValues.row.id_personnel)}
            },

            { 
                field: 'categorie', 
                headerName: 'Catégorie',
                flex: 2/6,
                renderCell: (cellValues) => {  return displayCategorie(cellValues.row.id_personnel)}
            },  
            { 
                field: 'description', 
                headerName: 'Description',
                flex: 3/6,
                renderCell: (cellValues) => {return  displayDescr(cellValues.row.id_personnel)}
            },           
            { 
                field: 'heures', 
                headerName: 'Heures',
                flex: 2/6,
                renderCell: (cellValues) => {return  displayHeurePersonnel(cellValues.row.id_personnel, cellValues.row.id_rapport).toLocaleString()}
            },    
            { 
                field: 'editer', 
                headerName: '',
                flex: 1/6,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => this.editpersonnel(cellValues.row.id)}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#2f4cdd', color:'white'}}}
                        >
                            Modifier
                        </Button>
                    )
                    
                }
            },          
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                flex: 1/6,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deletepersonnel(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button> 
                    )
                }
            }
        ]

        const columnsTraitant = [
            { 
                field: 'code', 
                headerName: 'Code',
                flex: 1/5,
                renderCell: (cellValues) => {return displayCode(cellValues.row.id_traitant)}
            }, 
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 4/2,
                renderCell: (cellValues) => {return  displayDesign(cellValues.row.id_traitant)}
            },                 
            { 
                field: 'quantite', 
                headerName: 'Quantité',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayQuantiteTraitant(cellValues.row.id_traitant, cellValues.row.id_rapport).toLocaleString()}
            },    
            { 
                field: 'editer', 
                headerName: '',
                flex: 1/5,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => this.editsoustraitant(cellValues.row.id)}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#2f4cdd', color:'white'}}}
                        >
                            Modifier
                        </Button>
                    )
                    
                }
            },   
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                flex: 1/3,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deletetraitant(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                }
            }
        ]

        const columnsCaisse = [
            { 
                field: 'designation', 
                headerName: 'Désignation',
                flex: 2,
                renderCell: (cellValues) => { return  cellValues.row.designation}
            },            
            { 
                field: 'somme', 
                headerName: 'Montant',
                type: 'number',
                flex: 2/4,
            },     
            { 
                field: 'editer', 
                headerName: '',
                flex: 1/4,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => this.editcaisse(cellValues.row.id)}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#2f4cdd', color:'white'}}}
                        >
                            Modifier
                        </Button>
                    )
                    
                }
            },    
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                flex: 1/4,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deletecaisse(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                }
            }
        ]

        const displayQuantiteTraitant = (id_traitant, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_traitants.filter(c => c["id_traitant"] === id_traitant )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.quantite
            })       
            return resultat
        }


        const displayQtéMaterieaux = (id_fourniture, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_fournitures.filter(c => c["id_fourniture"] === id_fourniture )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.quantite
            })       
            return resultat
        }


        const displayHeurePersonnel = (id_personnel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_personnels.filter(c => c["id_personnel"] === id_personnel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.heures
            })       
            return resultat
        }

        const displayImm = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.immobilisation
            })       
            return resultat
        }

        const displayDepart = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.compteurdepart
            })       
            return resultat
        }

        const displayArriver = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.compteurarrive
            })       
            return resultat
        }

        const displaynbvoyage = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.nbvoyage
            })       
            return resultat
        }

        const displayAR = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.arret
            })       
            return resultat
        }

        const displayHJ = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.hj
            })       
            return resultat
        }

        const displaycar = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            items.map((answer) => {                 
                resultat = resultat + answer.carburant
            })       
            return resultat
        }

        const displayMcar = () => {
            let resultat = 0
            let items = []
            let item = []
            rapport_materiel().map((answer) => { 
                item = rapport_materiels.filter(c => c["id_materiel"] === answer.id_materiel )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.carburant * answer.prix)
                })            
            })            
            return resultat
        }

        const displayMmater = () => {
            let resultat = 0
            let items = []
            let item = []
            rapport_materiel().map((answer) => { 
                item = rapport_materiels.filter(c => c["id_materiel"] === answer.id_materiel )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.hj * answer.taux)
                })            
            }) 
            return resultat
        }

        const displayMpusure = () => {
            let resultat = 0
            let item = []
            let items = []
            rapport_piece().map((answer) => { 
                resultat = resultat + (answer.quantite * answer.taux)
                // item = rapport_pieces.filter(c => c["id_pieceusure"] === answer.id_pieceusure )
                // items =  item.filter(c => c["id_rapport"] === id_rapport )
                // items.map((answer) => {                 
                //     resultat = resultat + (answer.quantite * answer.taux)
                // })            
            }) 
            return resultat
        }

        const displayMper = () => {
            let resultat = 0
            let item = []
            let items = []
            rapport_personnel().map((answer) => { 
                item = rapport_personnels.filter(c => c["id_personnel"] === answer.id_personnel )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.heures * answer.taux)
                })            
            }) 
            return resultat
        }

        const displayMfour = () => {
            let resultat = 0
            let item = []
            let items = []
            rapport_fourniture().map((answer) => { 
                item = rapport_fournitures.filter(c => c["id_fourniture"] === answer.id_fourniture )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.quantite * answer.taux)
                })            
            }) 
            return resultat
        }

        const displayMtraitant = () => {
            let resultat = 0
            let item = []
            let items = []
            rapport_traitant().map((answer) => { 
                item = rapport_traitants.filter(c => c["id_traitant"] === answer.id_traitant )
                items =  item.filter(c => c["id_rapport"] === id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.quantite * answer.taux)
                })            
            }) 
            return resultat
        }

        const displayMcaiss = () => {
            let resultat = 0
            rapport_caisse.map((answer) => {
                resultat = resultat + answer.somme
            })
            return resultat
        }
        
        return ( 
            <React.Fragment>        
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            fullWidth
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ openRapport: true })}
                        >
                            Nouveau rapport
                        </Button>
                    </Grid> 

                    <Grid item xs={12} sm={6} md={8} >
                        <Box style={{display: 'flex', flexDirection:'row'}}>
                            <ReactToPrint
                                trigger={() => {
                                    return  (
                                        <Button 
                                            variant="contained" 
                                            sx={{bgcolor:'#5d95ed', height:50}}
                                        >
                                            <PrintIcon sx={{fontSize:40}} /> 
                                        </Button>
                                    );
                                }}
                                content={() => this.componentRef1}
                            />  

                            <Tooltip 
                                title={<React.Fragment>
                                    <Typography color="inherit" sx={{fontSize:20}} >Rapports de chantier</Typography>
                                    <Typography color="inherit" sx={{fontSize:16}} >
                                    Les rapports d'activités de chaque échelon sont rédigés chaque jour sur un forulaire conformement au prix du bordereau. 
                                    Un échelon peut produire plusieurs rapports par jour selon que les opérations éffectuées concernant plusieurs prix du bordereau. 
                                    Le rapport de chantier comportent les informations de date, de prix, de quantite executées, des matériels utilisés, du personnel, 
                                    des matériaux etc .....
                                    </Typography>
                                </React.Fragment>
                                }
                            >                        
                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                >
                                    <AnnouncementOutlinedIcon/>
                                </Button>
                            </Tooltip>  
                        </Box>                        
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} >
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid> 

                    <Grid item xs={12} sm={6} md={12} >
                        <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }} ref={el => (this.componentRef1 = el)}>
                            <DataGrid
                                rows={rapport}
                                columns={columns}
                                loading={loading}
                                elevation={10}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius:4,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}                   
                            />
                        </div>
                    </Grid>
                </Grid>

                {/* Rapport big popup */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={openRapport}
                    onClose={() => this.setState({openRapport:false})}
                    TransitionComponent={Transition}
                    scroll={'paper'}
                >
                    <AppBar sx={{ position: 'relative', bgcolor: "#ffffff" }} >
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1, color:'black' }} variant="h6" component="div">Rapports de chantier </Typography>

                            <IconButton onClick={() =>  this.setState({ openRapport: false })} >   
                                <CloseIcon /> 
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <DialogContent dividers={true} ref={el => (this.componentRef = el)} >
                        <form role="form" className="text-start" onSubmit={this.handleEditerSubmit}  >                        
                            <Box style={{display: 'flex', flexDirection:'row', padding:16 }}>
                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                                    startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                                    onClick={() => this.setState(id_borderau === null ? { EmptyNumPrix: true } : { openMateriel: true })}
                                >
                                    Matériel
                                </Button>

                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50, ml:3}} 
                                    startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                                    onClick={() => this.setState(id_borderau === null ? { EmptyNumPrix: true } : { openPiece: true })}
                                >
                                    Pièce d'usure
                                </Button>

                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50, ml:3}} 
                                    startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                                    onClick={() => this.setState(id_borderau === null ? { EmptyNumPrix: true } : { openPersonnel: true })}
                                >
                                    Personnel
                                </Button>

                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50, ml:3}} 
                                    startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                                    onClick={() => this.setState(id_borderau === null ? { EmptyNumPrix: true } : { openFourniture: true })}
                                >
                                    Fourniture
                                </Button>

                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50, ml:3}} 
                                    startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                                    onClick={() => this.setState(id_borderau === null ? { EmptyNumPrix: true } : { openTraitant: true })}
                                >
                                    S-traitant
                                </Button>

                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50, ml:3}} 
                                    startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                                    onClick={() => this.setState(id_borderau === null ? { EmptyNumPrix: true } : { openCaisse: true })}
                                >
                                    Caisse
                                </Button>

                                <ReactToPrint
                                    trigger={() => {
                                        return  (
                                            <Button 
                                                variant="contained" 
                                                sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                            >
                                                <PrintIcon sx={{fontSize:40}} /> 
                                            </Button>
                                        );
                                    }}
                                    content={() => this.componentRef}
                                />
                            </Box>

                            <Grid sx={{ flexGrow: 1, p:2 }} container spacing={2}>
                                <Grid item xs={12} sm={6} md={2} > 
                                    <Datee fait={fait} onChange={this.dateChange} />
                                </Grid>

                                <Grid item xs={12} sm={6} md={10} style={{textAlign: "right"}} > 
                                    <FormControl >
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={this.handleChange}
                                        >
                                            <FormControlLabel 
                                                value="Beau" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#2f4cdd", '&.Mui-checked': {color: "#2f4cdd"}}}/>} 
                                                label="Beau" 
                                                checked={temps === "Beau"}
                                            />
                                            <FormControlLabel 
                                                value="Pluvieux" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#2f4cdd", '&.Mui-checked': {color: "#2f4cdd"}}}/>} 
                                                label="Pluvieux" 
                                                checked={temps === "Pluvieux"}
                                            />
                                            <FormControlLabel 
                                                value="Nuageux" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#2f4cdd", '&.Mui-checked': {color: "#2f4cdd"},}}/>} 
                                                label="Nuageux" 
                                                checked={temps === "Nuageux"}
                                            />
                                        </RadioGroup>                                        
                                    </FormControl>  
                                </Grid>

                                <Grid item xs={12} md={1.5} sx={{mt:2}}> 
                                    <FormControl sx={{ m: 1 }} style={{ width: '100%', marginTop:'-1px', marginLeft:'-1px' }}>
                                        <InputLabel id="demo-simple-select-helper-label">N° prix</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_borderau}
                                            label="N° prix"
                                            name="id_borderau"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {bordereauxToSelect.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.n_prix}  {answer.designation}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={8} sx={{mt:2}}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Désignation"
                                        name="designation"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        value={borderauselected.designation || ''}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={1.5} sx={{mt:2}}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite}
                                        name="quantite"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={1} sx={{mt:2}}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Unité"
                                        style={{ width: '100%' }}
                                        value={borderauselected.unite || ''}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={12} > 
                                    <TabContext value={valuetab} >
                                        <Box sx={{  borderColor: 'divider' }}>
                                            <TabList onChange={this.handleChangetab} aria-label="lab API tabs example">
                                                <Tab label="Matériel" value="1" />
                                                <Tab label="Pièce d'usure" value="2" />
                                                <Tab label="Personnel" value="3" />
                                                <Tab label="Fourniture" value="4" />
                                                <Tab label="S-traitant" value="5" />
                                                <Tab label="Caisse" value="6" />
                                            </TabList>
                                        </Box>

                                        <TabPanel value="1" sx={{p:0, pt:2}}>
                                            <div style={{ height: 350, width: '100%'}} >
                                                <DataGrid
                                                    rows={rapport_materiel()}
                                                    columns={columnsMateriel}
                                                    sx={{
                                                        '.MuiDataGrid-columnSeparator': {
                                                            //display: 'none',
                                                            },
                                                            '&.MuiDataGrid-root': {
                                                            //border: 'none',
                                                            },
                                                        '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                            color:'black',
                                                            fontWeight: 600,
                                                            fontSize:18
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </TabPanel>

                                        <TabPanel value="2" sx={{p:0, pt:2}}>
                                            <div style={{ height: 350, width: '100%'}} >
                                                <DataGrid
                                                    rows={rapport_piece()}
                                                    columns={columnsPiece}
                                                    sx={{
                                                        '.MuiDataGrid-columnSeparator': {
                                                            //display: 'none',
                                                            },
                                                            '&.MuiDataGrid-root': {
                                                            //border: 'none',
                                                            },
                                                        '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                            color:'black',
                                                            fontWeight: 600,
                                                            fontSize:18
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </TabPanel>

                                        <TabPanel value="3" sx={{p:0, pt:2}}>
                                            <div style={{ height: 350, width: '100%'}} >
                                                <DataGrid
                                                    rows={rapport_personnel()}
                                                    columns={columnsPersonnel}
                                                    sx={{
                                                        '.MuiDataGrid-columnSeparator': {
                                                            //display: 'none',
                                                            },
                                                            '&.MuiDataGrid-root': {
                                                            //border: 'none',
                                                            },
                                                        '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                            color:'black',
                                                            fontWeight: 600,
                                                            fontSize:18
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </TabPanel>

                                        <TabPanel value="4" sx={{p:0, pt:2}}>
                                            <div style={{ height: 350, width: '100%'}} >
                                                <DataGrid
                                                    rows={rapport_fourniture()}
                                                    columns={columnsFourniture}
                                                    sx={{
                                                        '.MuiDataGrid-columnSeparator': {
                                                            //display: 'none',
                                                            },
                                                            '&.MuiDataGrid-root': {
                                                            //border: 'none',
                                                            },
                                                        '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                            color:'black',
                                                            fontWeight: 600,
                                                            fontSize:18
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </TabPanel>

                                        <TabPanel value="5" sx={{p:0, pt:2}}>
                                            <div style={{ height: 350, width: '100%'}} >
                                                <DataGrid
                                                    rows={rapport_traitant()}
                                                    columns={columnsTraitant}
                                                    sx={{
                                                        '.MuiDataGrid-columnSeparator': {
                                                            //display: 'none',
                                                            },
                                                            '&.MuiDataGrid-root': {
                                                            //border: 'none',
                                                            },
                                                        '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                            color:'black',
                                                            fontWeight: 600,
                                                            fontSize:18
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </TabPanel>

                                        <TabPanel value="6" sx={{p:0, pt:2}}>
                                            <div style={{ height: 350, width: '100%'}} >
                                                <DataGrid
                                                    rows={rapport_caisse}
                                                    columns={columnsCaisse}
                                                    sx={{
                                                        '.MuiDataGrid-columnSeparator': {
                                                            //display: 'none',
                                                            },
                                                            '&.MuiDataGrid-root': {
                                                            //border: 'none',
                                                            },
                                                        '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                            color:'black',
                                                            fontWeight: 600,
                                                            fontSize:18
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </TabPanel>
                                    </TabContext>
                                </Grid>

                                <Grid item xs={12} md={12}><Typography color="inherit">Déboursé par poste</Typography></Grid>

                                <Grid item xs={12/7}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Carburant"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMcar().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12/7}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Matériel"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMmater().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12/7}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Pièce d'usure"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMpusure().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12/7} align="right">                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Personnel"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMper().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12/7} align="right">                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Fourniture"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMfour().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12/7} align="right">                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant S-traitant"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMtraitant().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12/7} align="right">                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Montant Caisse"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayMcaiss().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6*12/7} style={{marginTop:32}}> 
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Observation"
                                        multiline
                                        rows={4}
                                        value={observation}
                                        style={{ width: '100%' }}
                                        name="observation"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12/7} style={{marginTop:32}} align="right"> 
                                    <TextField
                                        id="outlined-required"
                                        label="Total déboursés"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        sx={{bgcolor:'#e9eff6'}}
                                        value={(displayMcar() + displayMmater()  + displayMpusure() + displayMper() + displayMfour() + displayMtraitant() + displayMcaiss()).toLocaleString() || ''}
                                    />

                                    <br/> 

                                    <TextField
                                        id="outlined-required"
                                        label="Prix de revient"
                                        variant="outlined"
                                        style={{ width: '100%', marginTop:16 }}
                                        sx={{bgcolor:'#e9eff6'}}
                                        value={((displayMcar() + displayMmater() + displayMpusure() + displayMper() + displayMfour() + displayMtraitant() + displayMcaiss())/quantite).toLocaleString() || ''}
                                    />
                                </Grid>
                            </Grid>                        

                            <Button  
                                type="submit" 
                                fullWidth
                                variant="contained" 
                                sx={{
                                    color:"white", 
                                    textTransform: 'none', 
                                    fontSize:20, 
                                    height:50, 
                                    backgroundColor:'#888da0',  
                                    borderRadius:0, 
                                    mt:8,
                                    ':hover':{backgroundColor:'#2f4cdd'} 
                                }}
                                endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                            >
                                Cliquer pour enregistrer le rapport
                            </Button>     
                        </form>
                    </DialogContent>
                </Dialog>

                {/* Nouveau Rapport */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={open}
                    onClose={() => this.setState({ open: false })}                    
                >
                    <DialogTitle style={{textAlign: "center"}}>Nouveau Rapport</DialogTitle>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label" style={{marginTop:"-20px"}}>Date du rapport</InputLabel>
                                        <DatePicker 
                                            selected={fait}
                                            onChange={(date) => this.handleChangeDateStart(date)}
                                            value={fait}
                                            id="demo-simple-select"
                                            maxDate={new Date()}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6} style={{textAlign: "right"}}> 
                                    <FormControl style={{marginTop:"-16px"}}>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Temps</FormLabel>

                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={this.handleChange}
                                        >
                                            <FormControlLabel 
                                                value="Beau" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#ec4b48", '&.Mui-checked': {color: "#ec4b48"},}}/>} 
                                                label="Beau" 
                                                checked={temps === "Beau"}
                                            />
                                            <FormControlLabel 
                                                value="Pluvieux" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#ec4b48", '&.Mui-checked': {color: "#ec4b48"},}}/>} 
                                                label="Pluvieux" 
                                                checked={temps === "Pluvieux"}
                                            />
                                            <FormControlLabel 
                                                value="Nuageux" 
                                                name="temps" 
                                                control={<Radio  sx={{color: "#ec4b48", '&.Mui-checked': {color: "#ec4b48"},}}/>} 
                                                label="Nuageux" 
                                                checked={temps === "Nuageux"}
                                            />
                                        </RadioGroup>                                        
                                    </FormControl>                                
                                </Grid>

                                <Grid item xs={12} md={2}> 
                                    <FormControl sx={{ m: 1 }} style={{ width: '100%', marginTop:'-1px', marginLeft:'-1px' }}>
                                        <InputLabel id="demo-simple-select-helper-label">N° prix</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_borderau}
                                            label="N° prix"
                                            name="id_borderau"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {bordereauxToSelect.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.n_prix}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>

                                </Grid>

                                <Grid item xs={12} md={4}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Désignation"
                                        name="designation"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        value={borderauselected.designation || ''}
                                    />

                                </Grid>

                                <Grid item xs={12} md={2}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Prix unitaitre"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={borderauselected.prix || ''}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite}
                                        name="quantite"
                                        onChange={this.handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Unité"
                                        style={{ width: '100%' }}
                                        value={borderauselected.unite || ''}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Observation"
                                        multiline
                                        rows={4}
                                        value={observation}
                                        style={{ width: '100%' }}
                                        name="observation"
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>

                        </DialogContent>

                        <DialogActions>
                            <Button onClick={() => this.setState({ open: false })} style={{color:"black"}} >Annuler</Button>

                            <Button  type="submit" className="bg-gradient-danger" autoFocus style={{color:"white"}}> Enregistrer </Button>
                        </DialogActions>
                    </form>
                    
                </Dialog>                

                {/* Rapport Materiel */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openMateriel}
                    onClose={() => this.setState({ openMateriel: false })}                    
                >
                     <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Rapport matériel
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openMateriel: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitMateriel}>
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Machine</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ""}
                                            label="N° GM"
                                            name="id_materiel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.gm}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Modèle</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ""}
                                            label="N° GM"
                                            name="id_materiel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.type} #{answer.chassis}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Heures d'utilisation"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={hj_materiel}
                                        name="hj_materiel"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Carburant utilisé"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={carburant_materiel}
                                        name="carburant_materiel"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Heures de panne"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={arret_materiel}
                                        name="arret_materiel"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Heures d'immobilisation"
                                        style={{ width: '100%' }}
                                        type="number"
                                        InputLabelProps={{shrink: true}}
                                        value={immobilisation}
                                        name="immobilisation"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Compteur au départ(KM)"
                                        style={{ width: '100%' }}
                                        type="number"
                                        InputLabelProps={{shrink: true}}
                                        value={compteurdepart}
                                        name="compteurdepart"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Compteur a l'arrivée(KM)"
                                        style={{ width: '100%' }}
                                        type="number"
                                        InputLabelProps={{shrink: true}}
                                        value={compteurarrive}
                                        name="compteurarrive"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Nombre de voyages"
                                        style={{ width: '100%' }}
                                        type="number"
                                        InputLabelProps={{shrink: true}}
                                        value={nbvoyage}
                                        name="nbvoyage"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Pièce d'usure"
                                        style={{ width: '100%' }}
                                        type="number"
                                        InputLabelProps={{shrink: true}}
                                        value={pusure}
                                        name="pusure"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                {pusure > 0 &&
                                    <Grid item xs={12} md={12}> 
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Justificatifs du pièce d'usure"
                                            style={{ width: '100%' }}
                                            value={designationpusure}
                                            name="designationpusure"
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </Grid>   
                                }
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                mt:8,
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer le rapport
                        </Button>                          
                    </form>
                </Dialog>

                {/* Rapport Piece */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openPiece}
                    onClose={() => this.setState({ openPiece: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Sortie pièce d'usure
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openPiece: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitPiece}>
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Machine</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ''}
                                            label="Machine"
                                            name="id_materiel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.gm}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Modèle</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ''}
                                            label="Matériel"
                                            name="id_materiel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.type} #{answer.chassis}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Désignation</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_pieceusure || ''}
                                            label="Désignation"
                                            name="id_pieceusure"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {appropiece.map((answer) => { 
                                                return  <MenuItem value={answer.id_pusure}>{displayPieceDesign(answer.id_pusure)}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite_piece}
                                        name="quantite_piece"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                mt:6,
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer le rapport
                        </Button> 
                    </form>
                </Dialog>
                
                {/* Rapport Personnel */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openPersonnel}
                    onClose={() => this.setState({ openPersonnel: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Rapport personnel
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openPersonnel: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitPersonnel}>
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Personnel</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_personnel || ""}
                                            label="Catégorie"
                                            name="id_personnel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {personnel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.name}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Catégorie</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_personnel || ""}
                                            label="Catégorie"
                                            name="id_personnel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {personnel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.categorie}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Description</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_personnel || ""}
                                            label="Catégorie"
                                            name="id_personnel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {personnel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.description}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Heures"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={heure_personnel}
                                        name="heure_personnel"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                mt:8,
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer le rapport
                        </Button> 
                    </form>
                </Dialog>

                {/* Editer Rapport Personnel */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openEditPersonnel}
                    onClose={() => this.setState({ openEditPersonnel: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier rapport personnel
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openEditPersonnel: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleEditerPersonnelSubmit}>
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Personnel</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_personnel || ""}
                                            label="Catégorie"
                                            name="id_personnel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {personnel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.name}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Catégorie</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_personnel || ""}
                                            label="Catégorie"
                                            name="id_personnel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {personnel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.categorie}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Description</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_personnel || ""}
                                            label="Catégorie"
                                            name="id_personnel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {personnel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.description}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Heures"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={heure_personnel}
                                        name="heure_personnel"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                mt:7,
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour modifier le rapport
                        </Button> 
                    </form>
                </Dialog>

                {/* Rapport Fourniture */}                
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openFourniture}
                    onClose={() => this.setState({ openFourniture: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Rapport fourniture
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openFourniture: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitFourniture}>
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Code</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_fourniture || ''}
                                            label="Catégorie"
                                            name="id_fourniture"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {approvisionnement.map((answer) => { 
                                                return  <MenuItem value={answer.id_fourniture}>{displayFournitureCode(answer.id_fourniture)}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Désignation</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_fourniture || ''}
                                            label="Désignation"
                                            name="id_fourniture"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {approvisionnement.map((answer) => { 
                                                return  <MenuItem value={answer.id_fourniture}>{displayFournitureDesign(answer.id_fourniture)}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite_fourniture}
                                        name="quantite_fourniture"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                mt:6,
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer le rapport
                        </Button> 
                    </form>
                </Dialog>

                {/* Rapport Traitant */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openTraitant}
                    onClose={() => this.setState({ openTraitant: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Rapport sous-traitant
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openTraitant: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitTraitant} >
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Code</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant || ""}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.code}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Sous-traitant</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant || ""}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.designation}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite_traitant}
                                        name="quantite_traitant"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                mt:6,
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer le rapport
                        </Button>
                    </form>
                </Dialog>
                
                {/* Editer Rapport Traitant */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openEditTraitant}
                    onClose={() => this.setState({ openEditTraitant: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier rapport sous-traitant
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openEditTraitant: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleEditTraitantSubmit} >
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Code</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant || ""}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.code}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Sous-traitant</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_traitant || ""}
                                            label="Code"
                                            name="id_traitant"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {traitant.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.designation}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Quantité"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={quantite_traitant}
                                        name="quantite_traitant"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                mt:6,
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour modifier le rapport
                        </Button> 
                    </form>
                </Dialog>

                {/* Rapport Caisse */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openCaisse}
                    onClose={() => this.setState({ openCaisse: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Rapport caisse
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openCaisse: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitCaisse} >
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Désignation"
                                        style={{ width: '100%' }}
                                        value={designation_caisse}
                                        name="designation_caisse"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Somme"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={somme_caisse}
                                        name="somme_caisse"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                mt:5,
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer le rapport
                        </Button>
                    </form>
                </Dialog>

                {/* Edit Rapport Caisse */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openEditCaisse}
                    onClose={() => this.setState({ openEditCaisse: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier rapport caisse
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openEditCaisse: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleEditerCaiseSubmit} >
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Désignation"
                                        style={{ width: '100%' }}
                                        value={designation_caisse}
                                        name="designation_caisse"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        id="outlined-required"
                                        label="Somme"
                                        style={{ width: '100%' }}
                                        type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        value={somme_caisse}
                                        name="somme_caisse"
                                        onChange={this.handleChange}
                                        sx={{bgcolor:'#e9eff6'}}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                mt:5,
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour modifier le rapport
                        </Button>
                    </form>
                </Dialog>


                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >
                        Ajouté avec succès
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >
                        Erreur d'enregistrement
                    </Alert>
                </Snackbar> 

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={errorMateriel} autoHideDuration={6000} onClose={() => this.setState({ errorMateriel: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ errorMateriel: false })} >
                        Carburant insuffisant                    
                    </Alert>
                </Snackbar> 

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={errorFourniture} autoHideDuration={6000} onClose={() => this.setState({ errorFourniture: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ errorFourniture: false })} >
                        Fourniture insuffisante                    
                    </Alert>
                </Snackbar> 

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={EmptyNumPrix} autoHideDuration={6000} onClose={() => this.setState({ EmptyNumPrix: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ EmptyNumPrix: false })} >
                        Veuillez choisir un numéro de prix                    
                    </Alert>
                </Snackbar>
            </React.Fragment>
        )
    }
} 