import React, { Component } from "react"; 
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress, { circularProgressClasses} from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import axios from 'axios';
import Card from '@mui/material/Card';
import { Chart } from "react-google-charts";
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import ProjetMenu  from "../../Layouts/ProjetMenu";
import ProjetPeriode from "../../Layouts/ProjetPeriode"

export default class Dashbord extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            carburants: [],
            rapports: [],
            rapport_materiels: [],
            bordereaux:[],
            materiels: [],
            personnels: [],
            traitants: [],
            rapport_personnels: [],
            rapport_fournitures: [],
            rapport_traitants: [],
            rapport_caisses: [],
            depenses: [],
            fournitures: [],
            approvisionnements: [],
            rapport_pieces: [],

            projetId: this.props.projetId,
            startDate: new Date(),
            endDate: new Date(),

            anchorEl: null,
            id_fourniture: null,
            role: null
        }
        this.handleClick= this.handleClick.bind(this)
        this.idchange = this.idchange.bind(this)
        this.Datechange = this.Datechange.bind(this)
    }

    componentWillMount() {
        const role = JSON.parse(localStorage.getItem("ROLE"));
        this.setState({ role: role })
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };      

        axios.get('https://api-gn.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            let projet = []
            let startDate = new Date()
            let endDate = new Date()
            if(this.state.projetId !== null){
                projet = response.data.projects.filter(c => c["id"] === this.state.projetId)[0]
                startDate = new Date(projet.start *1000)
                endDate = new Date(projet.close *1000)
            }

            this.setState({ 
                startDate: startDate,
                endDate: endDate,
                projects: response.data.projects,
                carburants: response.data.carburants,
                rapports: response.data.rapports,
                rapport_materiels: response.data.rapport_materiels,
                bordereaux: response.data.bordereaux,
                materiels: response.data.materiels,
                personnels: response.data.personnels,
                traitants: response.data.traitants,
                rapport_personnels: response.data.rapport_personnels,
                rapport_fournitures: response.data.rapport_fournitures,
                rapport_traitants: response.data.rapport_traitants,
                rapport_caisses: response.data.rapport_caisses,
                depenses: response.data.depenses,
                fournitures: response.data.fournitures,
                id_fourniture: response.data.fournitures[0].id,
                approvisionnements: response.data.allapprovisionnements,
                rapport_pieces: response.data.rapport_pieces,
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleClick(event){
        this.setState({anchorEl: event.currentTarget});
    }

    idchange(id){  
        if(this.state.projects.filter(c => c["id"] === id)[0] !== undefined){
            let projet = this.state.projects.filter(c => c["id"] === id)[0]
            this.setState(
                { 
                    projetId: id,
                    startDate: new Date(projet.start *1000),
                    endDate: new Date(projet.close *1000),
                },
                () => this.props.onChange(id, new Date(projet.start *1000), new Date(projet.close *1000))
            )
        }
        
    }

    Datechange(startDate, endDate){
        this.setState({startDate: startDate, endDate: endDate})
    }

    render() {

        const { 
            projetId,
            startDate,
            endDate,
            projects,
            carburants,
            rapports,
            rapport_materiels,
            bordereaux,
            materiels,
            personnels,
            traitants,
            rapport_personnels,
            rapport_fournitures,
            rapport_traitants,
            rapport_caisses,
            rapport_pieces,
            depenses,
            anchorEl,
            fournitures,
            id_fourniture,
            approvisionnements,
            role
        } = this.state

        const id_project = projetId

        let project = []
        let projetStart = new Date()
        let projetClose = new Date()
        let projetName= ""
        let ProjetContry = ""
        let ProjetLocation = ""
        let ProjetHoursbyday = ""
        if(projects.filter(c => c["id"] === id_project)[0] !== undefined){
            project = projects.filter(c => c["id"] === id_project)[0] 
            projetStart =  projects.filter(c => c["id"] === id_project)[0].start
            projetClose =  projects.filter(c => c["id"] === id_project)[0].close
            projetName = projects.filter(c => c["id"] === id_project)[0].name
            ProjetContry = projects.filter(c => c["id"] === id_project)[0].country
            ProjetLocation = projects.filter(c => c["id"] === id_project)[0].location
            ProjetHoursbyday = projects.filter(c => c["id"] === id_project)[0].hoursbyday
        }

        // const b = rapports.filter(c => c["fait"] >= Math.floor(new Date(startDate).getTime() / 1000))
        // const d = b.filter(c => c["fait"] <= Math.floor(new Date(endDate).getTime() / 1000) + 86400)
        // let c = b.filter(c => c["id_projet"] === id_project)
        // let rapport = c

        const aa = rapports.filter(c => c["id_projet"] === id_project)
        const  bb = aa.filter(c => c["fait"] >= Math.floor(new Date(startDate).getTime() / 1000))
        const cc = bb.filter(c => c["fait"] <= Math.floor(new Date(endDate).getTime() / 1000) + 86400)
        let rapport = cc

        let bordereau = bordereaux.filter(c => c["id_projet"] === id_project)

        let materiel = materiels.filter(c => c["id_projet"] === id_project)

        let personnel = personnels.filter(c => c["id_projet"] === id_project)

        let traitant = traitants.filter(c => c["id_projet"] === id_project)

        let approvisionnement = approvisionnements.filter(c => c["id_projet"] === id_project)

        let fourniture= "Options"
        if(id_fourniture !== null){
            fourniture = fournitures.filter(c => c["id"] === id_fourniture)[0].designation
        }

        let carburant = []
        if(id_project === null){
            carburant = []
        }else{
            carburant = carburants.filter(c => c["id_projet"] === id_project)
        }

        const getCraburant = () => {
            let x = carburant.filter(c => c["start"] >= Math.floor(new Date(startDate).getTime() / 1000))
            let y = x.filter(c => c["start"] <= Math.floor(new Date(endDate).getTime() / 1000) + 86400)
            let z = y.filter(c => c["type"] === "Gas-oil")
            return z
        }

        const getCraburantEssence = () => {
            let x = carburant.filter(c => c["start"] >= Math.floor(new Date(startDate).getTime() / 1000))
            let y = x.filter(c => c["start"] <= Math.floor(new Date(endDate).getTime() / 1000) + 86400)
            let z = y.filter(c => c["type"] === "Essence")
            return z
        }

        const getCarburantEntrant =  () => {
            let x = 0
            getCraburant().map((element) => {
                x = x + element.quantite
            })
            return x
        }

        const getCarburantSortant =  () => {
            let item = 0
            let a = []
            if(carburant.filter(c => c["type"] === "Gas-oil").length > 0){
                a = carburant.filter(c => c["type"] === "Gas-oil")[0].prix
            }else{
                a = []
            }
            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["prix"] === a)
                b.map((sss) => {
                    item = item + sss.carburant
                })       
            })
            return item
        } 
        
        const getCarburantEntrantEssence =  () => {
            let x = 0
            getCraburantEssence().map((element) => {
                x = x + element.quantite
            })
            return x
        }

        const getCarburantSortantEssence =  () => {
            let item = 0
            let a = []
            if(carburant.filter(c => c["type"] === "Essence").length > 0){
                a = carburant.filter(c => c["type"] === "Essence")[0].prix
            }else{
                a = []
            }
            rapport.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                const b = c.filter(c => c["prix"] === a)
                b.map((sss) => {
                    item = item + sss.carburant
                })       
            })
            return item
        } 

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
        }

        const dataGasoil = [
            ["Element", "en littre", { role: "style" }],
            ["Ravitaillement (litre)", getCarburantEntrant(), "#1e33F2"], // RGB value
            ["Sortie (litre)", getCarburantSortant(), "#FF6845"], // English color name
        ]

        const dataEssence = [
            ["Element", "en littre", { role: "style" }],
            ["Ravitaillement (litre)", getCarburantEntrantEssence(), "#1e33F2"], // RGB value
            ["Sortie (litre)", getCarburantSortantEssence(), "#FF6845"], // English color name
        ]

        const displayTotalmois  = () => { 
            const a = (Math.floor(new Date(endDate).getTime() / 1000) -  Math.floor(new Date(startDate).getTime() / 1000))/(30*24*60*60)
            return a
        }

        const getMateiel = ()  => {
            let item = 0
            rapport.map((answer) => {
                const a = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                const b = a.filter(c => c["fait"] >= Math.floor(new Date(startDate).getTime() / 1000))
                const c = b.filter(c => c["fait"] <= Math.floor(new Date(endDate).getTime() / 1000) + 86400)
                c.map((answerr) => {
                    item = item + (answerr.taux * answerr.hj)
                })       
            })
            return item
        }

        const getPusure = ()  => {
            let item = 0
            rapport.map((answer) => {
                const c = rapport_pieces.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + (answerr.taux * answerr.quantite)
                })       
            })
            return item
        }

        const getDCraburant = () => { 
            let item = 0
            rapport.map((answer) => {
                const a = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                const b = a.filter(c => c["fait"] >= Math.floor(new Date(startDate).getTime() / 1000))
                const c = b.filter(c => c["fait"] <= Math.floor(new Date(endDate).getTime() / 1000) + 86400)
                c.map((answerr) => {
                    item = item + (answerr.carburant * answerr.prix)
                })       
            })
            return item
        }

        const getPersonnel = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_personnels.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + (answerr.taux * answerr.heures)
                })       
            })
            return item
        }

        const getSalaire = () => { 
            let items = 0
            let c =  personnels.filter(c => c["id_projet"] === id_project)
            let b = c.filter(c => c["unite"] === "Mois")
            b.map((answerr) => {
                items = items + (displayTotalmois().toFixed(2) * (answerr.taux))
            })
            return items
        }

        const getFourniture = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_fournitures.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + (answerr.taux * answerr.quantite)
                })       
            })
            return item
        }

        const getTraitant = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_traitants.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + (answerr.taux * answerr.quantite)
                }) 
            })
            return item
        }

        const getCaisse = () => { 
            let item = 0
            rapport.map((answer) => {
                const c = rapport_caisses.filter(c => c["id_rapport"] === answer.id)
                c.map((answerr) => {
                    item = item + answerr.somme
                })       
            })
            return item
        }

        const getDepense = () => { 
            let items = 0
            let item = depenses.filter(c => c["id_projet"] === id_project)
            let ite = item.filter(c => c["start"] >= Math.floor(new Date(startDate).getTime() / 1000))
            let it = ite.filter(c => c["start"] <= Math.floor(new Date(endDate).getTime() / 1000) + 86400)
            it.map((resultat) => {
                items = items + resultat.montant
            })
            return items
        }

        const dataDepense = [
            ["Task", "Hours per Day"],
            ["Matériels", getMateiel()],
            ["Pièces d'usure", getPusure()],
            ["Carburant", getDCraburant()],
            //["Personnel", (getPersonnel() + getSalaire())],
            ["Personnel", getPersonnel()],
            ["Matériaux", getFourniture()],
            ["Sous traitant", getTraitant()],
            ["caisse", getCaisse()],
            ["Dép. mensuelles", getDepense()]
        ];

        const optionsDepense = {
            //title: "My Daily Activities",
            is3D: true,
        };

        const getfournitureEntrant =  () => { 
            let item = 0
            let x = approvisionnement.filter(c => c["start"] >= Math.floor(new Date(startDate).getTime() / 1000))
            let y = x.filter(c => c["start"] <= Math.floor(new Date(endDate).getTime() / 1000) + 86400)
            let z = y.filter(c => c["id_fourniture"] === id_fourniture)
            z.map((resultat) => {
                item = item + resultat.quantite
            })
            return item
        }

        const getfournitureSortant =  () => { 
            let item = 0
            let x = approvisionnement.filter(c => c["start"] >= Math.floor(new Date(startDate).getTime() / 1000))
            let y = x.filter(c => c["start"] <= Math.floor(new Date(endDate).getTime() / 1000) + 86400)
            let z = y.filter(c => c["id_fourniture"] === id_fourniture)
            z.map((resultat) => {
                item = item + resultat.restant
            })
            return item
        }

        const dataFourniture = [
            ["Element", "", { role: "style" }],
            ["Entrant", getfournitureEntrant(), "#1e33F2"], // RGB value
            ["Sortant", getfournitureSortant(), "#FF6845"], // English color name
        ]

        const  DelaisConsomme = () =>{
            return (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress 
                        variant="determinate" 
                        size={80} 
                        value={displayDelaiCosomme()}  
                        thickness={6} 
                        style={{
                            color: '#2f4cdd',
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }} 
                    />

                    <Box
                        sx={{
                            top: 6,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                        >{`${Math.round(displayDelaiCosomme())}%`}</Typography>
                    </Box>
                </Box>
            )
        }

        const displayDelaiCosomme = () =>{
            const ecart = projetClose - projetStart
            const today = Math.floor(new Date().getTime() / 1000) - projetStart
            let rusltat = (today * 100)/ecart
            if(rusltat  <= 100){
                return rusltat
            }else{
                return 100
            }            
        }
        
        const Avancement = () =>{
            return (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress 
                        variant="determinate" 
                        size={80} 
                        value={displayEtatAvancement()}  
                        thickness={6} 
                        style={{
                            color: '#2f4cdd',
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }} 
                    />

                    <Box
                        sx={{
                            top: 6,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="caption"
                            component="div"
                            color="text.secondary"
                        >{`${Math.round(displayEtatAvancement())}%`}</Typography>
                    </Box>
                </Box>
            )
        } 

        const displayEtatAvancement  = () =>{
            let total = 0
            bordereau.map((answer) => {
                total = total + answer.prix * answer.quantite
            })            
            //Materiel
            let mat = 0
            let rapportreel = rapports.filter(c => c["id_projet"] === id_project)
            rapportreel.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                c.map((resultat) => {
                    mat = mat + (resultat.taux * resultat.hj)
                })       
            })
            //carburant
            let car = 0
            rapportreel.map((answer) => {
                const c = rapport_materiels.filter(c => c["id_rapport"] === answer.id)
                c.map((resultat) => {
                    car = car + (resultat.carburant * resultat.prix)
                })       
            })
            //personnel
            let per = 0
            rapportreel.map((answer) => {
                const c = rapport_personnels.filter(c => c["id_rapport"] === answer.id)
                c.map((resultat) => {
                    per = per + (resultat.taux * resultat.heures)
                })       
            })
            //Fourniture
            let four = 0
            rapportreel.map((answer) => {
                const c = rapport_fournitures.filter(c => c["id_rapport"] === answer.id)
                c.map((resultat) => {
                    four = four + (resultat.taux * resultat.quantite)
                })       
            })
            //Sous-traitant
            let trai = 0
            rapportreel.map((answer) => {
                const c = rapport_traitants.filter(c => c["id_rapport"] === answer.id)
                c.map((resultat) => {
                    trai = trai + (resultat.taux * resultat.quantite)
                }) 
            })
            //Caisse
            let cai = 0
            rapportreel.map((answer) => {
                const c = rapport_caisses.filter(c => c["id_rapport"] === answer.id)
                c.map((resultat) => {
                    cai = cai + resultat.somme
                })       
            })
            //depenses mensuels
            let dep = 0
            let it = depenses.filter(c => c["id_projet"] === id_project)
            it.map((resultat) => {
                dep = dep + resultat.montant
            })
            //Salaire
            // let sal = 0
            // let pers =  personnels.filter(c => c["id_projet"] === id_project)
            // let perso = pers.filter(c => c["unite"] === "Mois")
            // perso.map((answerr) => {
            //     sal = sal + (displayTotalmois().toFixed(2) * (answerr.taux))
            // })            

            //let c = mat + car + per + four + trai + cai + dep + sal

            let c = mat + car + per + four + trai + cai + dep 
            return ((c * 100)/total)
        }

        return ( 
            <React.Fragment>
                {projetId  === null ?
                    <Grid sx={{ flexGrow: 1 }} container spacing={6} >
                        <Grid item xs={12} sm={6}  md={9.5} />

                        <Grid item xs={12} sm={6} md={2.5}>
                            <ProjetMenu projetId={id_project} onChange={this.idchange} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} >
                            <div className="card" style={{ height: 630 }} >
                                <h3 style={{margin:"auto", fontSize:"32px !important"}}>Veuillez choisir un projet</h3>
                            </div>
                        </Grid>
                    </Grid>
                :
                    <Grid sx={{ flexGrow: 1 }} container spacing={6} >
                        <Grid item xs={12} sm={6}  md={6}>                        
                            <Typography sx={{color:'#46505b', fontSize:28 }}>{ProjetContry}, {ProjetLocation}</Typography>
                            <Typography 
                                component="h1" 
                                variant="h1" 
                                sx={{color:"black", fontWeight: 400, fontSize:24}}
                            >
                                {projetName.substring(0, 60)}
                            </Typography>                        
                        </Grid>  

                        <Grid item xs={12} sm={6} md={2.5}>
                            <ProjetMenu projetId={id_project} onChange={this.idchange} />
                        </Grid> 

                        <Grid item xs={12} sm={6} md={3.5}>
                            <ProjetPeriode startDate={startDate}  endDate={endDate} onChange={this.Datechange}/>
                        </Grid>
                
                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={1}  sx={{height:140, p:4, backgroundColor:'#ffffff', borderRadius:3}}>
                                <Typography 
                                    component="h1" 
                                    variant="h1" 
                                    sx={{color:"black", fontWeight: 600, fontSize:36}}
                                >
                                    {displayCreation(projetStart)}
                                </Typography>

                                <Typography sx={{color:'#46505b', fontSize:24 }}>Date Dém.</Typography>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={1}  sx={{height:140, p:4, backgroundColor:'#ffffff', borderRadius:3}}>
                                <Typography 
                                    component="h1" 
                                    variant="h1" 
                                    sx={{color:"black", fontWeight: 600, fontSize:36}}
                                >
                                    {displayCreation(projetClose)}
                                </Typography>

                                <Typography sx={{color:'#46505b', fontSize:24 }}>Date Clôt.</Typography>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={1}  sx={{height:140, p:4, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', justifyContent:'space-between'}}>
                                <DelaisConsomme  />

                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', pl:4}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize:36}}
                                    >
                                        Délais
                                    </Typography>

                                    <Typography sx={{color:'#46505b', fontSize:24 }}>Consommé</Typography>
                                </Box>
                                
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={1}  sx={{height:140, p:4, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', justifyContent:'space-between'}}>
                                <Avancement  />

                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', pl:4}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize:36}}
                                    >
                                        État
                                    </Typography>

                                    <Typography sx={{color:'#46505b', fontSize:24 }}>D'avancement</Typography>
                                </Box>
                                
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} >
                            <Card elevation={1}  sx={{height:480, p:4, backgroundColor:'#ffffff', borderRadius:3}}>
                                <Typography 
                                    component="h1" 
                                    variant="h1" 
                                    sx={{color:"black", fontWeight: 600, fontSize:24}}
                                >Gas-oil
                                </Typography>

                                <Chart
                                    chartType="ColumnChart"
                                    width="100%"
                                    height="400px"
                                    data={dataGasoil}
                                />
                                
                            </Card>
                        </Grid>                    

                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={1}  sx={{height:140, p:4, backgroundColor:'#ffffff', borderRadius:3, display: 'flex',}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left',}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize:36}}
                                    >
                                        {bordereau.length > 10 ? bordereau.length : "0" + bordereau.length} Elemen.
                                    </Typography>

                                    <Typography sx={{color:'#46505b', fontSize:16 }}>Borderau des prix</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', pl:4, pt:2}}>
                                    <AssignmentOutlinedIcon sx={{color:"#2f4cdd", fontSize:48 }} />
                                </Box>
                                
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={1}  sx={{height:140, p:4, backgroundColor:'#ffffff', borderRadius:3, display: 'flex',}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left',}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize:36}}
                                    >
                                        {materiel.length > 10 ? materiel.length : "0" + materiel.length} Elemen.
                                    </Typography>

                                    <Typography sx={{color:'#46505b', fontSize:16 }}>Matériels</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', pl:4, pt:2}}>
                                    <LocalShippingOutlinedIcon sx={{color:"#2f4cdd", fontSize:48 }} />
                                </Box>
                                
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={1}  sx={{height:140, p:4, backgroundColor:'#ffffff', borderRadius:3, display: 'flex',}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left',}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize:36}}
                                    >
                                        {personnel.length > 10 ? personnel.length : "0" + personnel.length} Elemen.
                                    </Typography>

                                    <Typography sx={{color:'#46505b', fontSize:16 }}>Personnels</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', pl:4, pt:2}}>
                                    <PeopleAltOutlinedIcon sx={{color:"#2f4cdd", fontSize:48 }} />
                                </Box>
                                
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={1}  sx={{height:140, p:4, backgroundColor:'#ffffff', borderRadius:3, display: 'flex',}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left',}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"black", fontWeight: 600, fontSize:36}}
                                    >
                                        {traitant.length > 10 ? traitant.length : "0" + traitant.length} Elemen.
                                    </Typography>

                                    <Typography sx={{color:'#46505b', fontSize:16 }}>Sous-traitants</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', pl:4, pt:2}}>
                                    <SupervisedUserCircleOutlinedIcon sx={{color:"#2f4cdd", fontSize:48 }} />
                                </Box>
                                
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} >
                            <Card elevation={1}  sx={{height:400, p:4, backgroundColor:'#ffffff', borderRadius:3}}>
                                <Typography 
                                    component="h1" 
                                    variant="h1" 
                                    sx={{color:"black", fontWeight: 600, fontSize:24}}
                                >
                                    Répartition des dépenses</Typography>

                                    <Chart
                                        chartType="PieChart"
                                        data={dataDepense}
                                        options={optionsDepense}
                                        width={"100%"}
                                        height={"400px"}
                                    />
                                
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} >
                            <Card elevation={1}  sx={{height:400, p:4, backgroundColor:'#ffffff', borderRadius:3,}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left',}}>
                                    <Box sx= {{ display: 'flex', justifyContent:'space-between' }}>
                                        <Typography 
                                            component="h1" 
                                            variant="h1" 
                                            sx={{color:"black", fontWeight: 600, fontSize:24}}
                                        >Matériaux</Typography>

                                        <Button
                                            sx={{height:40, borderRadius:8, borderColor:'gray', borderWidth:1, border:' 1px solid', p:2 }}
                                            endIcon={<ExpandMoreIcon />}
                                            onClick={this.handleClick}
                                        >
                                            {fourniture}
                                        </Button>

                                        <Menu
                                            elevation={12}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={() => this.setState({anchorEl: null}) }
                                            PaperProps={{
                                                sx: {
                                                    //width: 300,
                                                    //backgroundColor:'gray',                                    
                                                }
                                            }}
                                        >
                                            {fournitures.map((element, index) => (
                                                <MenuItem 
                                                    disableRipple 
                                                    onClick={() => this.setState({id_fourniture: element.id, anchorEl: null })} 
                                                    sx={id_fourniture === element.id ? {color:'#2f4cdd'} : null}
                                                >
                                                    {element.code} | {element.designation}
                                                </MenuItem>
                                            )) }
                                        </Menu>
                                    </Box>

                                    <Chart
                                        chartType="ColumnChart"
                                        width="100%"
                                        height="360px"
                                        data={dataFourniture}
                                    />
                                </Box>
                                
                                
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} >
                            <Card elevation={1}  sx={{height:480, p:4, backgroundColor:'#ffffff', borderRadius:3}}>
                                <Typography 
                                    component="h1" 
                                    variant="h1" 
                                    sx={{color:"black", fontWeight: 600, fontSize:24}}
                                >Essence
                                </Typography>

                                <Chart
                                    chartType="ColumnChart"
                                    width="100%"
                                    height="400px"
                                    data={dataEssence}
                                />
                                
                            </Card>
                        </Grid>
                    </Grid>                
                }
            </React.Fragment>    
        )
    }
}