import React, { Component } from "react";
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid, useGridApiContext } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import Dropzone from 'react-dropzone'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { CSVLink } from "react-csv";
import Avatar from '@mui/material/Avatar';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import csbup from "../../Assets/Images/csbup.png"
import csbdown from '../../Assets/Images/csbdown.png'
import csvup from '../../Assets/Images/csvup.png'
import ProjetMenu  from "../../Layouts/ProjetMenu";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})



export default class Materiels extends Component {

    constructor(props) {
        super(props)
        this.state = {
            materiels:[],
            projects:[],

            open:false,
            isDid:false,
            isNotDit:false,
            importation: false,
            loading: true,

            projetId: this.props.projetId,

            id:null,
            gm: "",
            type: "",
            chassis:"",
            taux: 0,
            ctheorique:0,
            unite: "Jour",
            owner: "Somagec",
            driver: "Amadou Diarra",
            carburant: "Gas-oil",

            selectedFile: null,

            path:"",
            message: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.idchange = this.idchange.bind(this)
    }

    componentDidMount() {  
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/materiel', config)
        .then(response => {    
            this.setState({ 
                projects: response.data.projects,
                materiels: response.data.materiels,
                loading: false
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        const bodyParameters = {
            id_projet: this.state.projetId, 
            gm: this.state.gm, 
            type: this.state.type, 
            taux: this.state.taux, 
            chassis: this.state.chassis,
            unite: this.state.unite, 
            owner: this.state.owner,
            driver: this.state.driver,
            carburant: this.state.carburant,
            ctheorique: this.state.ctheorique
        };

        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/materiel',
            bodyParameters,
            config
        ).then(response => {       
            console.log("return", response.data)     
            this.setState({ message:"Matériel ajouté avec succès", isDid: true, open:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur d'enregistrement", isNotDit: true });
            console.log("error", error)
        });
    }

    fileDrop(acceptedFiles){
        this.setState({selectedFile: acceptedFiles[0],  path: acceptedFiles[0].path})
    }

    onFileUpload(){ 
        // Create an object of formData 
        const formData = new FormData(); 
        // Update the formData object 
        formData.append("File", this.state.selectedFile)
        formData.append("id_project", this.state.projetId)
        axios.post('https://api-gn.projectmanagementsolutions.net/api/materiel-import', formData)
        .then(response => {   
            this.setState({message: "Importé avec succès", isDid: true, importation:false });    
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message:"Erreur d'importation", isNotDit: true });
            console.log("error", error)
        });     
    }

    delete(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/materiel/' + id,
            config
        ).then(response => {   
            this.setState({message:"Matériel supprimé avec succès", isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message:"Erreur de suppression", isNotDit: true });
            console.log("error", error)
        });    

    }

    editTable(props){
        console.log('Ahmed', props)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        let bodyParameters = {}
        if(props.field === "gm"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                gm: props.value, 
                type: props.row.type, 
                taux: props.row.taux, 
                chassis: props.row.chassis,
                unite: props.row.unite, 
                owner: props.row.owner,
                carburant: props.row.carburant,
                ctheorique: props.row.ctheorique,
                driver: props.row.driver
            };
        }else if(props.field === "type"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                gm: props.row.gm, 
                type: props.value, 
                taux: props.row.taux, 
                chassis: props.row.chassis,
                unite: props.row.unite, 
                owner: props.row.owner,
                carburant: props.row.carburant,
                ctheorique: props.row.ctheorique,
                driver: props.row.driver
            };  
        }else if(props.field === "taux"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                gm: props.row.gm, 
                type: props.row.type, 
                taux: props.value, 
                chassis: props.row.chassis,
                unite: props.row.unite, 
                owner: props.row.owner,
                carburant: props.row.carburant,
                ctheorique: props.row.ctheorique,
                driver: props.row.driver
            };  
        }else if(props.field === "chassis"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                gm: props.row.gm, 
                type: props.row.type, 
                taux: props.row.taux, 
                chassis: props.value,
                unite: props.row.unite, 
                owner: props.row.owner,
                carburant: props.row.carburant,
                ctheorique: props.row.ctheorique,
                driver: props.row.driver
            };  
        }else if(props.field === "unite"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                gm: props.row.gm, 
                type: props.row.type, 
                taux: props.row.taux, 
                chassis: props.row.chassis,
                unite: props.value, 
                owner: props.row.owner,
                carburant: props.row.carburant,
                ctheorique: props.row.ctheorique,
                driver: props.row.driver
            };  
        }else if(props.field === "owner"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                gm: props.row.gm, 
                type: props.row.type, 
                taux: props.row.taux, 
                chassis: props.row.chassis,
                unite: props.row.unite, 
                owner: props.value,
                carburant: props.row.carburant,
                ctheorique: props.row.ctheorique,
                driver: props.row.driver
            };
        }else if(props.field === "ctheorique"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                gm: props.row.gm, 
                type: props.row.type, 
                taux: props.row.taux, 
                chassis: props.row.chassis,
                unite: props.row.unite, 
                owner: props.row.owner, 
                carburant: props.row.carburant,
                ctheorique: props.value,
                driver: props.row.driver
            }
        }else if(props.field === "driver"){
            bodyParameters = {
                id_projet: props.row.id_projet, 
                gm: props.row.gm, 
                type: props.row.type, 
                taux: props.row.taux, 
                chassis: props.row.chassis,
                unite: props.row.unite, 
                owner: props.row.owner, 
                carburant: props.row.carburant,
                ctheorique: props.row.ctheorique,
                driver: props.value
            }
        }

        axios.put( 
            'https://api-gn.projectmanagementsolutions.net/api/materiel/' + props.id,
            bodyParameters,
            config
        ).then(response => {      
            this.setState({ message:"Matériel modifié avec succès", isDid: true});               
            this.setState({ isDid: true});
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ message:"Erreur dans le modification", isNotDit: true });
            console.log("error", error)
        });  

    }

    idchange(id){ 
        this.setState({
            projetId: id
        })
    }

    render() {

        const { path, message, projetId, loading, importation, isNotDit, isDid, id, gm, chassis, type, ctheorique, taux, unite, owner, driver, carburant, open, materiels, projects } = this.state

        const id_project = projetId
        
        let materiel = []
        if(id_project === null){
            materiel = []
        }else{
            materiel = materiels.filter(c => c["id_projet"] === id_project)
        }
        

        const columns = [
            { 
                field: 'gm', 
                headerName: 'Machine',
                editable: true,
                flex: 1/20
            },
            { 
                field: 'type', 
                headerName: 'Modèle',
                editable: true,
                flex: 1/10
            },
            { 
                field: 'chassis', 
                headerName: 'N° Chassis',
                editable: true,
                flex: 1/15
            },            
            { 
                field: 'unite', 
                headerName: 'Unité',
                editable: true,
                flex: 1/20,
            },
            { 
                field: 'owner', 
                headerName: 'Propriétaire',
                editable: true,
                flex: 1/10
            },
            { 
                field: 'driver',
                headerName: 'Conducteur',
                editable: true,
                flex: 1/10
            },
            { 
                field: 'carburant', 
                headerName: 'Carburant',
                flex: 1/15
            },      
            { 
                field: 'ctheorique', 
                headerName: 'Conso. théor. (L/H)',
                editable: true,
                type: 'number',
                flex: 1/10
            }, 
            { 
                field: 'taux', 
                headerName: 'Taux',
                editable: true,
                type: 'number',
                flex: 1/20,
                renderCell: (cellValues) => {return cellValues.row.taux.toLocaleString()}
            },          
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                flex: 1/20,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                    
                }
            }
        ]

        const headers = [
            { label: "Machine", key: "gm" },
            { label: "Modèle", key: "type" },
            { label: "N° Chassis", key: "chassis" },            
            { label: "Unité", key: "unite" },
            { label: "Propriétaire", key: "owner" },
            { label: "Conducteur", key: "driver" },
            { label: "Carburant", key: "carburant" },
            { label: "Conso. théori.", key: "ctheorique" },
            { label: "Taux", key: "taux" },
        ];

        return ( 
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            fullWidth
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ open: true })}
                        >
                            Ajouter un matériel
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={8} >
                        <Box style={{display: 'flex', flexDirection:'row'}}>
                            <Button 
                                variant="contained" 
                                sx={{bgcolor:'#5d95ed', height:50, ':hover':{background:'#5d95ed'}}}
                                onClick={() => this.setState({ importation: true })}
                            >
                                <img src={csbup} style={{width:40}}/>
                            </Button>

                            <CSVLink 
                                data={materiel} 
                                headers={headers} 
                                filename={"matériels.csv"} 
                                className="btn" 
                                style={{height:50, backgroundColor:"#5d95ed", marginLeft:24, borderRadius:4, width:70}}
                            >
                                <img src={csbdown} style={{width:40, marginTop:'-6px', marginLeft:'-6px'}}/>
                            </CSVLink>

                            <ReactToPrint
                                trigger={() => {
                                    return  (
                                        <Button 
                                            variant="contained" 
                                            sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                        >
                                            <PrintIcon sx={{fontSize:40}} /> 
                                        </Button>
                                    );
                                }}
                                content={() => this.componentRef}
                            />    

                            <Tooltip 
                                title={<React.Fragment>
                                    <Typography color="inherit" sx={{fontSize:20}} >Matériel</Typography>
                                    <Typography color="inherit" sx={{fontSize:16}} >
                                    Le matériel est composé de l'ensemble des engins et véhicules du Somagec ainsi que les engins et véhicules en location.
                                    </Typography>
                                </React.Fragment>
                                }
                            >                        
                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                >
                                    <AnnouncementOutlinedIcon/>
                                </Button>
                            </Tooltip>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} >
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={12} >
                        <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }}> 
                            <DataGrid
                                rows={materiel}
                                columns={columns}
                                onCellEditCommit={(props, event) => this.editTable(props)}
                                loading={loading}
                                elevation={10}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius:4,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                            />
                        
                        </div>
                    </Grid>
                </Grid>

                <div  style={{ display: "none" }}>
                    <table className="table table-striped table-hover"  ref={el => (this.componentRef = el)}>
                        <thead>
                            <tr>
                                <th scope="col">Machine</th>
                                <th scope="col">Modèle</th>
                                <th scope="col">N° Chassis</th>                                
                                <th scope="col">Unité</th>
                                <th scope="col">Propriétaire</th>
                                <th scope="col">Conducteur</th>
                                <th scope="col">Carburant</th>
                                <th scope="col">Conso. théori.</th>
                                <th scope="col">Taux</th>
                            </tr>
                        </thead>
                        <tbody>
                            {materiel.map((answer) => {
                                return (
                                    <tr>
                                        <td>{answer.gm}</td>
                                        <td>{answer.type}</td>
                                        <td>{answer.chassis}</td>                                        
                                        <td>{answer.unite}</td>
                                        <td>{answer.owner}</td>
                                        <td>{answer.driver}</td>
                                        <td>{answer.carburant}</td>                                        
                                        <td>{answer.ctheorique}</td>
                                        <td>{answer.taux}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={() => this.setState({ open: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouveau matériel
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Machine"
                                            name="gm"
                                            placeholder="HL6536"
                                            style={{ width: '100%' }}
                                            value={gm}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Modele"
                                            name="type"
                                            style={{ width: '100%' }}
                                            value={type}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>   

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="N° Chassis"
                                            name="chassis"
                                            style={{ width: '100%' }}
                                            value={chassis}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>                                 

                                <Grid item xs={12} md={4}>                                    
                                    <FormControl  style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">unité</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={unite}
                                                label="unite"
                                                name="unite"
                                                style={{ width: '100%' }}
                                                onChange={this.handleChange}
                                                sx={{bgcolor:'#e9eff6'}}
                                            >                                        
                                                <MenuItem value="Heure">Heure</MenuItem>
                                                <MenuItem value="Jour">Jour</MenuItem>
                                                <MenuItem value="Mois">Mois</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>       

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Propriétaire"
                                            name="owner"
                                            style={{ width: '100%' }}
                                            value={owner}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>   

                                   
                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Conducteur"
                                            name="driver"
                                            style={{ width: '100%' }}
                                            value={driver}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>  

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ m: 1 }} style={{ width: '100%', marginTop:'-1px', marginLeft:'-1px' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Carburant</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={carburant}
                                                label="Carburant"
                                                name="carburant"
                                                style={{ width: '100%' }}
                                                onChange={this.handleChange}
                                                sx={{bgcolor:'#e9eff6'}}
                                            >                                        
                                                <MenuItem value="Gas-oil">Gas-oil</MenuItem>
                                                <MenuItem value="Essence">Essence</MenuItem>
                                                <MenuItem value="DDO">DDO</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>  

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Conso. théori.(L/H)"
                                            name="ctheorique"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={ctheorique}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>                                                   

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Taux"
                                            name="taux"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={taux}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>    
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                                Cliquer pour enregistrer le nouveau matériel
                        </Button>
                    </form>                    
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={importation}
                    onClose={() => this.setState({ importation: false })}
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#fafafa'}}}                              
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26, pl:16}}
                        >
                            Importer un fichier CSV 
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ importation: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <DialogContent style={{textAlign: "center",  padding:'0px 170px' }}>
                        <Dropzone onDrop={acceptedFiles  => this.fileDrop(acceptedFiles)}>
                            {({getRootProps, getInputProps}) => (
                                <section>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />

                                    <img src={csvup} style={{ width: 260, marginLeft:'-50px', marginTop:80, marginBottom:20}} />
                                    
                                    {path === "" ? <p style={{marginTop:64}}>Faites glisser et déposez le fichier ici, ou cliquez pour sélectionner le fichier</p> : <p>{path}</p>}
                                </div>
                                </section>
                            )}
                        </Dropzone>
                    </DialogContent>

                    {path === "" ? null :
                        <Button  
                            onClick={() => this.onFileUpload()}
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour Importer le fichier CSV
                        </Button>
                    }                               
                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar> 
            </React.Fragment>
            
        )
    }
}