import React, { Component } from "react"; 
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-date-picker';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import axios from "axios";
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const HtmlTooltip = styled(({ className, ...props }) => (
<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 400,
    fontSize: 14,
    border: '1px solid #dadde9',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default class Utilisations extends Component {

    constructor(props) {
        super(props)
        this.state = {
            materiels: [],
            projects: [],
            rapports: [],
            rapport_materiels: [],

            start: new Date(),
            end: new Date(),

            id_materiel: null,

            first: false,
            second: false,

            option: "un"
        }
        this.handleChange = this.handleChange.bind(this)
    }    

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            this.setState({ 
                materiels: response.data.materiels,
                projects: response.data.projects,
                rapports: response.data.rapports,
                rapport_materiels: response.data.rapport_materiels
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChangeDateStart(date){ this.setState({start: date})}

    handleChangeDateEnd(date){ this.setState({end: date})}

    visualier(){
        if(this.state.option === "un"){
            this.setState({first : true})
        }else{
            this.setState({second : true})
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }


    render() {

        const {start, end, option, id_materiel, first, second, projects, rapports, rapport_materiels} = this.state

        const id_project = this.props.projetId

        const materiels = this.state.materiels.filter(c => c["id_projet"] === id_project)

        let materiel = []
        if(id_project === null){
            materiel = []
        }else{
            materiel = materiels.filter(c => c["id_projet"] === id_project)
        }

        let name = ""
        let pays= ""
        let curency = ""
        if(projects.filter(c => c["id"] === id_project)[0] !== undefined){
            name = projects.filter(c => c["id"] === id_project)[0].name
            pays= projects.filter(c => c["id"] === id_project)[0].country
            curency = projects.filter(c => c["id"] === id_project)[0].currency
        }

        let gm = ""
        let type = ""
        let chassis = ""
        let taux = ""
        let owner = ""
        if(materiels.filter(c => c["id"] === id_materiel)[0] !== undefined){
            gm = materiels.filter(c => c["id"] === id_materiel)[0].gm
            type= materiels.filter(c => c["id"] === id_materiel)[0].type
            chassis = materiels.filter(c => c["id"] === id_materiel)[0].chassis
            taux = materiels.filter(c => c["id"] === id_materiel)[0].taux
            owner = materiels.filter(c => c["id"] === id_materiel)[0].owner
        }

        const b = rapports.filter(c => c["fait"] >= Math.floor(new Date(start).getTime() / 1000))
        const c = b.filter(c => c["fait"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
        let rapport = c

        const rapport_materiel = () => {
            let resultat = []
            let item = []
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(rapport.filter(c => c["id"] === answer.id_rapport)[0] !== undefined){
                    item = item.concat(answer)
                }               
            })
            return item
        }

        const rrr = (id_rapport) => {
            const a = rapports.filter(c => c["id"] === id_rapport)
            if(a[0] !== undefined){
                return   displayCreation(a[0].fait)
            }else{
                return 0
            }
        }

        const columnEngin = [
            { 
                field: 'fait', 
                headerName: 'Date',
                flex: 1/6,
                renderCell: (cellValues) => {return  rrr(cellValues.row.id_rapport)}
            },
            { 
                field: 'heures', 
                headerName: "Heures d'utilisation",
                flex: 1/6,
                renderCell: (cellValues) => {return  (cellValues.row.hj).toLocaleString()}
            },
            { 
                field: 'carburantl', 
                headerName: "Carburant(l)",
                flex: 1/6,
                renderCell: (cellValues) => {return  (cellValues.row.carburant).toLocaleString()}
            },
            { 
                field: 'consommation', 
                headerName: "Consom. horaire",
                flex: 1/6,
                renderCell: (cellValues) => {return  (cellValues.row.carburant/cellValues.row.hj).toLocaleString()}
            },
            { 
                field: 'Location du matériel', 
                headerName: "Location du matériel",
                flex: 1/6,
                renderCell: (cellValues) => {return  (cellValues.row.hj * taux).toLocaleString()}
            },
            { 
                field: 'Carburant', 
                headerName: "Carburant",
                flex: 1/6,
                renderCell: (cellValues) => {return  (cellValues.row.carburant * cellValues.row.prix).toLocaleString()}
            },
        ]

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const displayLocation =  () => {
            let resultat = 0
            rapport_materiel().map((answer) => {                
                resultat = resultat + (answer.hj * taux)
            })
            return resultat
        }

        const displayCarburant =  () => {
            let resultat = 0
            rapport_materiel().map((answer) => {                
                resultat = resultat + (answer.carburant * answer.prix)
            })
            return resultat
        }

        const columnMateriel = [
            { 
                field: 'gm', 
                headerName: 'N° GM',
                flex: 1/10,
                renderCell: (cellValues) => {return  cellValues.row.gm }
            },
            { 
                field: 'type', 
                headerName: 'Matériel',
                flex: 1/10,
                renderCell: (cellValues) => {return  cellValues.row.type }
            },
            { 
                field: 'owner', 
                headerName: 'Propriétaire',
                flex: 1/10,
                renderCell: (cellValues) => {return  cellValues.row.owner }
            },
            { 
                field: 'taux', 
                headerName: 'Taux',
                flex: 1/10,
                renderCell: (cellValues) => {return  (cellValues.row.taux).toLocaleString() }
            },
            { 
                field: 'hj', 
                headerName: "Heures d'utilisation",
                flex: 1/10,
                renderCell: (cellValues) => {return  displayH(cellValues.row.id).toLocaleString() }
            },
            { 
                field: 'jour', 
                headerName: "Jour",
                flex: 1/10,
                renderCell: (cellValues) => {return  displayJour(cellValues.row.id).toLocaleString() }
            },
            { 
                field: 'car', 
                headerName: "Carburant(l)",
                flex: 1/10,
                renderCell: (cellValues) => {return  displayCarbH(cellValues.row.id).toLocaleString() }
            },
            { 
                field: 'carb', 
                headerName: "Carburant/heure",
                flex: 1/10,
                renderCell: (cellValues) => {return  (displayCarbH(cellValues.row.id)/displayH(cellValues.row.id)).toLocaleString() }
            },
            { 
                field: 'LocationGM', 
                headerName: "Location",
                flex: 1/10,
                renderCell: (cellValues) => {return  (cellValues.row.taux * displayH(cellValues.row.id)).toLocaleString() }
            },
            { 
                field: 'carburan', 
                headerName: "Carburant",
                flex: 1/10,
                renderCell: (cellValues) => {return (displayPrix(cellValues.row.id) * displayCarbH(cellValues.row.id)).toLocaleString() }
            },
        ]

        const displayH =  (id_materiel) => {
            let resultat = []
            let item = []
            let items = 0   
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(rapport.filter(c => c["id"] === answer.id_rapport)[0] !== undefined){
                    item = item.concat(answer)
                }               
            })

            item.map((answer) => {                
                items = items + answer.hj 
            })
            return items
        }

        const displayJour =  (id_materiel) => {
            let resultat = []
            let item = []
            let items = 0      
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)

            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                c.map((sss) => {
                    item = item.concat(sss)
                })       
            })
            let pp = item.filter( (ele, ind) => ind === item.findIndex(elem => elem.id === ele.id))
            pp = pp.filter( (ele, ind) => ind === pp.findIndex(elem => elem.fait === ele.fait))

            console.log("rrr", pp)
            return pp.length 
        }

        const displayCarbH =  (id_materiel) => {
            let resultat = []
            let item = []
            let items = 0            
            resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            resultat.map((answer) => {
                const c = rapport.filter(c => c["id"] === answer.id_rapport)
                if(c.length > 0){
                    item = item.concat(answer)
                }
            })
            item.map((answer) => {                
                items = items + answer.carburant
            })
            return items
        }

        const displayPrix =  (id_materiel) => {
            let items = 0            
            let resultat = rapport_materiels.filter(c => c["id_materiel"] === id_materiel)
            if(resultat.length > 0){
                items = resultat[0].prix
            }
            return items
        }

        const displayTLocation  =  () => {
            let resultat = 0
            materiels.map((answer) => {
                resultat = resultat + (answer.taux * displayH(answer.id))
            })
            return resultat
        }

        const displayTCarburant =  () => {
            let resultat = 0
            materiels.map((answer) => {
                resultat = resultat + (displayPrix(answer.id) * displayCarbH(answer.id))
            })
            return resultat
        }

        return (
            <div> 
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Approvisionnement</Typography>
                            Les fournitures sont des matériaux utilisés par le chantier pour la réalisation du projet. On listera 
                            dans la table des matériaux, l'ensemble des matériaux suivi de leurs différent caractéristiques. On 
                            donnera un code pour chaque matériau.
                        </React.Fragment>
                    }
                >
                    <Button variant="outlined" startIcon={<HelpOutlineIcon />} style={{marginTop:"-150px", color:"white", backgroundColor:"black"}}>
                        Aide
                    </Button>
                </HtmlTooltip>

                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12} md={3}>  </Grid>
                    <Grid item xs={12} md={6}>
                        <div className="card" style={{ width: '100%', padding:16}} >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}> 
                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label">Du</InputLabel>
                                        <DatePicker 
                                            selected={start}
                                            onChange={(date) => this.handleChangeDateStart(date)}
                                            value={start}
                                            id="demo-simple-select"
                                            maxDate={new Date()}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label">Au</InputLabel>
                                        <DatePicker 
                                            selected={end}
                                            onChange={(date) => this.handleChangeDateEnd(date)}
                                            value={end}
                                            id="demo-simple-select"
                                            maxDate={new Date()}
                                        />
                                    </div>
                                </Grid> 

                                <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                    <FormControl >
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onChange={this.handleChange}
                                            style={{width:"100%"}}
                                        >
                                            <FormControlLabel 
                                                value="un" 
                                                name="option" 
                                                control={<Radio  sx={{color: "#ec4b48", '&.Mui-checked': {color: "#ec4b48"},}}/>} 
                                                label="Un engin" 
                                                checked={option === "un"}
                                            />
                                            <FormControlLabel 
                                                value="tous les engins" 
                                                name="option" 
                                                control={<Radio  sx={{color: "#ec4b48", '&.Mui-checked': {color: "#ec4b48"},}}/>} 
                                                label="Tous les engins" 
                                                checked={option === "tous les engins"}
                                            />                                           
                                        </RadioGroup>                                        
                                    </FormControl>                                
                                </Grid>  

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Machine</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ''}
                                            label="N° GM"
                                            name="id_materiel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.gm}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Modèle</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_materiel || ''}
                                            label="Matériel"
                                            name="id_materiel"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                        >        
                                            {materiel.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.type} #{answer.chassis}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>                           
                            
                            </Grid>

                            <div className="text-center" style={{ width: '100%'}}>
                                <button onClick={() => this.visualier()} className="btn btn-lg bg-gradient-danger btn-lg w-100 mt-4 mb-0">Visualiser</button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}>  </Grid>
                </Grid>

                <Dialog
                    fullScreen
                    open={first}
                    onClose={() => this.setState({first:false})}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }} color="error" >
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Etat d'utilisation d'un engin </Typography>

                            <Button 
                                autoFocus
                                style={{backgroundColor:"black", color:"white"}}
                                endIcon={<CloseIcon />} 
                                onClick={() => this.setState({first:false})}
                            >Fermer</Button>
                        </Toolbar>
                    </AppBar>

                    <DialogContent ref={el => (this.componentRef = el)} >
                        <Grid sx={{ flexGrow: 1 }} container spacing={2} style={{marginTop:8}}>
                            <Grid item xs={12} md={4}>
                                <h6>Projet: {name}</h6>
                                <h6>Pays: {pays}</h6>
                                <h6>Monnaie: {curency}</h6>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                    <Grid item xs={12} md={1} style={{marginTop:16}}><h6>periode du: </h6></Grid>
                                    <Grid item xs={12} md={3}>
                                        <div className="input-group input-group-outline mb-4">
                                            <DatePicker 
                                                selected={start}
                                                onChange={(date) => this.handleChangeDateStart(date)}
                                                value={start}
                                                id="demo-simple-select"
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={1} style={{marginTop:16}} align="right"><h6>au: </h6></Grid>
                                    <Grid item xs={12} md={3}>
                                        <div className="input-group input-group-outline mb-4">
                                            <DatePicker 
                                                selected={end}
                                                onChange={(date) => this.handleChangeDateEnd(date)}
                                                value={end}
                                                id="demo-simple-select"
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={4}>
                                <h6>Machine: {gm}</h6>
                                <h6>Taux: {taux}</h6>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <h6>Modèle: {type} #{chassis}</h6>
                                <h6>Consommation Téorique Horaire</h6>
                            </Grid>
                            <Grid item xs={12} md={4}><h6>Propriétaire: {owner}</h6></Grid>

                            <Grid item xs={12} md={12}>
                                <div className="card" style={{ height: 500, width: '100%', marginTop:"0px" }} >
                                    <DataGrid
                                        rows={rapport_materiel()}
                                        columns={columnEngin}
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                    />
                                </div>  
                            </Grid>

                            <Grid item xs={12} md={8}></Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    id="outlined-required"
                                    label="Total Location"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={displayLocation().toLocaleString()}
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={2}>
                                <TextField
                                    id="outlined-required"
                                    label="Total carburant"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={displayCarburant().toLocaleString()}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

                <Dialog
                    fullScreen
                    open={second}
                    onClose={() => this.setState({second:false})}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }} color="error" >
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">Etat d'utilisation du matériel</Typography>

                            <Button 
                                autoFocus
                                style={{backgroundColor:"black", color:"white"}}
                                endIcon={<CloseIcon />} 
                                onClick={() => this.setState({second:false})}
                            >Fermer</Button>
                        </Toolbar>
                    </AppBar>

                    <DialogContent ref={el => (this.componentRef = el)} >
                        <Grid sx={{ flexGrow: 1 }} container spacing={2} style={{marginTop:8}}>
                            <Grid item xs={12} md={4}>
                                <h6>Projet: {name}</h6>
                                <h6>Pays: {pays}</h6>
                                <h6>Monnaie: {curency}</h6>
                            </Grid>

                            <Grid item xs={12} md={8}>
                                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                    <Grid item xs={12} md={1} style={{marginTop:16}}><h6>periode du: </h6></Grid>
                                    <Grid item xs={12} md={3}>
                                        <div className="input-group input-group-outline mb-4">
                                            <DatePicker 
                                                selected={start}
                                                onChange={(date) => this.handleChangeDateStart(date)}
                                                value={start}
                                                id="demo-simple-select"
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={1} style={{marginTop:16}} align="right"><h6>au: </h6></Grid>
                                    <Grid item xs={12} md={3}>
                                        <div className="input-group input-group-outline mb-4">
                                            <DatePicker 
                                                selected={end}
                                                onChange={(date) => this.handleChangeDateEnd(date)}
                                                value={end}
                                                id="demo-simple-select"
                                                maxDate={new Date()}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <div className="card" style={{ height: 500, width: '100%', marginTop:"0px" }} >
                                    <DataGrid
                                        rows={materiels}
                                        columns={columnMateriel}
                                        pageSize={10}
                                        rowsPerPageOptions={[10]}
                                    />
                                </div>  
                            </Grid>

                            <Grid item xs={12} md={8}></Grid>
                            <Grid item xs={12} md={2}>
                                <TextField
                                    id="outlined-required"
                                    label="Total Location"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={displayTLocation().toLocaleString()}
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={2}>
                                <TextField
                                    id="outlined-required"
                                    label="Total carburant"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={displayTCarburant().toLocaleString()}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>



            </div>
        )
    }
}