import React, { Component } from "react"; 
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-date-picker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip  from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import Avatar from '@mui/material/Avatar';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProjetMenu  from "../../Layouts/ProjetMenu"; 

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class GestCarburants extends Component { 

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            carburants: [],
            voir: false,
            open:false,
            isDid: false,
            isNotDit:false,
            openEdit:false,
            loading: true,

            projetId: this.props.projetId,

            id:null,
            type: "Gas-oil",
            start: new Date(),
            prix: 0,
            quantite:0,
            restant:0,
            message:''
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.idchange = this.idchange.bind(this)
    }

    componentDidMount() { 
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/carburant', config)
        .then(response => {
            this.setState({ 
                projects: response.data.projects,
                carburants: response.data.carburants,
                loading: false
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeDateStart(date){ this.setState({start: date})}

    handleSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        const datestart = Math.floor(new Date(this.state.start).getTime() / 1000)

        const bodyParameters = {
            id_projet: this.state.projetId, 
            type: this.state.type, 
            start: datestart, 
            prix: this.state.prix,
            quantite: this.state.quantite,
            restant: this.state.quantite
        };

        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/carburant',
            bodyParameters,
            config
        ).then(response => {       
            console.log("return", response.data)    
            this.setState({ message:"Carburant ajouté avec succès", isDid: true, open:false }); 
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur d'enregistrement", isNotDit: true });
            console.log("error", error)
        });
    }

    delete(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/carburant/' + id,
            config
        ).then(response => {     
            this.setState({message:"Carburant supprimé avec succès", isDid: true }); 
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message:"Erreur de suppression", isNotDit: true });
            console.log("error", error)
        });
    }

    editTable(props){
        console.log('Ahmed', props)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };    
        let bodyParameters = {}
        if(props.field === "type"){            
            bodyParameters = {
                id_projet: props.row.id_projet, 
                type: props.value, 
                start: props.row.start, 
                prix:props.row.prix,
                quantite: props.row.quantite,
                restant: props.row.quantite
            };
        }else if(props.field === "ravitaillement"){ 
            const datestart = Math.floor(new Date(props.value).getTime() / 1000)
            bodyParameters = {
                id_projet: props.row.id_projet, 
                type: props.row.type, 
                start: datestart, 
                prix:props.row.prix,
                quantite: props.row.quantite,
                restant: props.row.quantite
            };
        }else if(props.field === "prix"){ 
            bodyParameters = {
                id_projet: props.row.id_projet, 
                type: props.row.type, 
                start: props.row.start, 
                prix:props.value,
                quantite: props.row.quantite,
                restant: props.row.quantite
            };
        }else if(props.field === "quantite"){ 
            bodyParameters = {
                id_projet: props.row.id_projet, 
                type: props.row.type, 
                start: props.row.start, 
                prix:props.row.prix,
                quantite: props.value,
                restant: props.row.quantite
            };
        }
        axios.put( 
            'https://api-gn.projectmanagementsolutions.net/api/carburant/' + props.id,
            bodyParameters,
            config
        ).then(response => {   
            this.setState({ message:"Carburant modifié avec succès", isDid: true});
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ message:"Erreur dans le modification", isNotDit: true });
            console.log("error", error)
        });
    }

    idchange(id){ 
        this.setState({
            projetId: id
        })
    }

    render() {

        const {type, loading, projetId, start, prix, quantite, message, open, carburants, isDid, isNotDit} = this.state

        const id_project = projetId

        let carburant = []
        if(id_project === null){
            carburant = []
        }else{
            carburant = carburants.filter(c => c["id_projet"] === id_project)
        }

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const columns = [
            { 
                field: 'ravitaillement', 
                headerName: 'Date de ravitaillement',
                editable: true,
                type: 'date',
                flex: 2/8,
                renderCell: (cellValues) => {return  displayCreation(cellValues.row.start);}
            },

            { 
                field: 'type', 
                headerName: 'Carburant',
                flex: 1/16
            },
            { 
                field: 'quantite', 
                headerName: 'Quantite',
                editable: true,
                type: 'number',
                flex: 1/16,
                renderCell: (cellValues) => {return cellValues.row.quantite.toLocaleString()}
            },    
            { 
                field: 'prix', 
                headerName: 'Prix unitaire',
                flex: 1/16,
                editable: true,
                type: 'number',
                renderCell: (cellValues) => {return cellValues.row.prix.toLocaleString()}
            },   
            { 
                headerName: 'Montant',
                flex: 1/16,
                type: 'number',
                renderCell: (cellValues) => {return (cellValues.row.prix * cellValues.row.quantite).toLocaleString()}
            },   
            { 
                field: 'restant',
                headerName: 'Quantité restante',
                flex: 1/16,
                type: 'number',
                renderCell: (cellValues) => {return cellValues.row.restant.toLocaleString()}
            },
            { 
                field: 'supprimer', 
                headerName: '',
                flex: 1/16,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                }
            }
        ]

        return (
            <React.Fragment>   
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            fullWidth
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ open: true })}
                        >
                            Ajouter un ravitai.
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={8} >
                        <Box style={{display: 'flex', flexDirection:'row'}}>
                            <ReactToPrint
                                trigger={() => {
                                    return  (
                                        <Button 
                                            variant="contained" 
                                            sx={{bgcolor:'#5d95ed', height:50}}
                                        >
                                            <PrintIcon sx={{fontSize:40}} /> 
                                        </Button>
                                    );
                                }}
                                content={() => this.componentRef}
                            />  

                            <Tooltip 
                                title={<React.Fragment>
                                    <Typography color="inherit" sx={{fontSize:20}} >Ravitaillement</Typography>
                                    <Typography color="inherit" sx={{fontSize:16}} >
                                    Le personnel est composé de l'ensemble des personnes employées par le chantier. Il est subdivisé en catégorie, d'où l'on distingue 1ere, 2ème, "ème, 4ème ...
                                    HC ... etc. Il s'agira ici de lister les differentes catégories avec leurs caractéristiques.
                                    </Typography>
                                </React.Fragment>
                                }
                            >                        
                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#5d95ed', height:50, ml:3}}
                                >
                                    <AnnouncementOutlinedIcon/>
                                </Button>
                            </Tooltip>  
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} >
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid> 

                    <Grid item xs={12} sm={6} md={12} >
                        <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }}>
                            <DataGrid
                                rows={carburant}
                                columns={columns}
                                onCellEditCommit={(props, event) => this.editTable(props)}
                                loading={loading}
                                elevation={10}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius:4,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                            />
                        </div>
                    </Grid>                      
                </Grid>

                <div  style={{ display: "none" }}>
                    <table className="table table-striped table-hover"  ref={el => (this.componentRef = el)}>
                        <thead>
                            <tr>
                                <th scope="col">Date du ravitaillement</th>
                                <th scope="col">Carburant</th>
                                <th scope="col">Quantite</th>
                                <th scope="col">Prix unitaire</th>
                                <th scope="col">Montant</th>
                                <th scope="col">Quantite restante</th>                               
                            </tr>
                        </thead>
                        <tbody>
                            {carburant.map((answer) => { 
                                return (
                                    <tr>
                                        <td>{displayCreation(answer.start)}</td>
                                        <td>{answer.type}</td>
                                        <td>{answer.quantite.toLocaleString()}</td>
                                        <td>{answer.prix.toLocaleString()}</td>
                                        <td>{(answer.prix * answer.quantite).toLocaleString()}</td>
                                        <td>{answer.restant.toLocaleString()}</td>                                        
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={() => this.setState({ open: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouveau ravitaillement
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <FormControl sx={{ m: 1 }} style={{ width: '100%', marginTop:'-1px', marginLeft:'-1px' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Carburant</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={type}
                                            label="Carburant"
                                            name="type"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >                                        
                                            <MenuItem value="Gas-oil">Gas-oil</MenuItem>
                                            <MenuItem value="Essence">Essence</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label" style={{marginTop:"-20px"}}>Date de ravitaillement</InputLabel>
                                        <DatePicker 
                                            selected={start}
                                            //onSelect={(date) => this.handleChangeDateStart(date)}
                                            onChange={(date) => this.handleChangeDateStart(date)}
                                            value={start}
                                            id="demo-simple-select"
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Prix unitaire"
                                            name="prix"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={prix}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>    

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Quantite"
                                            name="quantite"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={quantite}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>                                                          
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer le nouveau ravitaillement
                        </Button>
                    </form>                    
                </Dialog>                

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar> 
            </React.Fragment>
        )
        
    }
}