import React, { Component } from "react"; 
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-date-picker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import Avatar from '@mui/material/Avatar';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProjetMenu  from "../../Layouts/ProjetMenu"; 
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Chip from '@mui/material/Chip';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Commande extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orders: [],
            oderproducts: [],
            fournitures: [],            

            open: false,            
            projetId: this.props.projetId,
            numbon: "",
            start: new Date(),            

            addProduit: false,
            id_fourniture: null,
            id_order: null,
            quantite: 0,

            openproduct: false,
            loading: true,

            commandeName: "",

            isDid: false,
            isNotDit:false,
            message: ''
        }
        this.handleChange = this.handleChange.bind(this)
        this.idchange = this.idchange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitProduit = this.handleSubmitProduit.bind(this)
    }

    componentDidMount() {     
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/orders', config)
        .then(response => {    
            this.setState({ 
                orders: response.data.orders,
                oderproducts: response.data.oderproducts,
                fournitures: response.data.fournitures,
                loading: false
            });
        }).catch(error => {
            console.log("error", error)
        });
    } 

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    idchange(id){ 
        this.setState({
            projetId: id
        })
    }

    handleChangeDateStart(date){ this.setState({start: date})}
    
    handleSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        const fait = Math.floor(new Date(this.state.start).getTime() / 1000)
        const bodyParameters = {
            id_projet: this.state.projetId,
            numbon: this.state.numbon,
            start: fait
        };
        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/order',
            bodyParameters,
            config
        ).then(response => {  
            console.log("hhh", response.data)
            this.setState({ message:"Commande ajoutée avec succès", isDid: true, open:false }); 
            this.componentDidMount()            
        }).catch(error => {
            console.log("hhh", error)
            this.setState({ message:"Erreur d'enregistrement", isNotDit: true });
        });
    }

    editTable(props){
        console.log('Ahmed', props)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };    
        let bodyParameters = {}
        if(props.field === "numbon"){
            bodyParameters = {
                numbon: props.value,
                start: props.row.start
            }
        }else if(props.field === "start"){            
            const datestart = Math.floor(new Date(props.value).getTime() / 1000)
            bodyParameters = {
                numbon: props.row.numbon,
                start: datestart
            }
        }
        axios.put( 
            'https://api-gn.projectmanagementsolutions.net/api/order/' + props.id,
            bodyParameters,
            config
        ).then(response => {                     
            this.setState({ message:"L'expression du besion modifiée avec succès", isDid: true});  
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ message:"Erreur dans le modification", isNotDit: true });
        });
    }

    delete(id){
        this.setState({ loading: true });
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/order/' + id,
            config
        ).then(response => {      
            this.setState({message:"Commande supprimée avec succès", isDid: true });  
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur de suppression", isNotDit: true });
            this.setState({ loading: true });
        });
    }

    lookthis(id) {
        const item = this.state.orders.filter(c => c["id"] === id)
        this.setState({
            id_order: id,
            commandeName: item[0].numbon,
            openproduct: true
        })
    }

    handleSubmitProduit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        const bodyParameters = {
            id_order: this.state.id_order,
            id_fourniture: this.state.id_fourniture,
            quantite: this.state.quantite
        };
        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/orderproduct',
            bodyParameters,
            config
        ).then(response => {  
            this.setState({ message:"Fourniture ajouté avec succès", isDid: true, addProduit:false }); 
            this.componentDidMount()            
        }).catch(error => {
            console.log("hhh", error)
            this.setState({ message:"Erreur d'enregistrement", isNotDit: true });
        });
    }

    deletep(id){
        this.setState({ loading: true });
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/orderproduct/' + id,
            config
        ).then(response => {      
            this.setState({message:"Fourniture supprimé avec succès", isDid: true });  
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur de suppression", isNotDit: true });
            this.setState({ loading: true });
        });
    }

    valide(id){
        this.setState({ loading: true });
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        const bodyParameters = {
            id: id
        }
        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/ordervalided',
            bodyParameters,
            config
        ).then(response => {  
            console.log("gggg", response.data)
            this.setState({message:"Besoin validé avec succès", isDid: true });  
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ message:"Erreur de validation", isNotDit: true });
            this.setState({ loading: false });
        });
    }

    render() {

        const {
            orders,
            oderproducts,
            fournitures,
            id_fourniture,
            projetId,
            id_order,
            quantite,
            numbon,
            start,
            openproduct,
            addProduit,
            open,
            loading,
            commandeName,
            isDid,
            isNotDit,
            message
        } = this.state

        const displayCode = (id) => {
            let resultat = []
            resultat = fournitures.filter(c => c["id"] === id)[0]
            return resultat.code
        }

        const displaydesignation = (id) => {
            let resultat = []
            resultat = fournitures.filter(c => c["id"] === id)[0]
            return resultat.designation
        }

        const id_project = projetId

        let order = []
        if(id_project !== null){
            order = orders.filter(c => c["id_projet"] === id_project)
        }

        let oderproduct = []
        if(id_order !== null){
            oderproduct = oderproducts.filter(c => c["id_order"] === id_order)
        }

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const getStauts = (id) => {
            let status = false
            status = orders.filter(c => c["id"] === id)[0].isvalided
            return status
        }

        const column = [
            { 
                field: 'name', 
                headerName: '',
                width: 60,
                renderCell: (cellValues) => { 
                    return ( 
                        <ContentPasteIcon 
                                onClick={() => { this.lookthis(cellValues.row.id) }}
                                sx={{ cursor: 'pointer', fontSize: 40, color: '#4777f4', mr:1 }} 
                        /> 
                    )
                }
            },
            { 
                field: 'numbon', 
                headerName: 'Libellé',
                editable: true,
                flex: 1,
            },
            { 
                field: 'start', 
                headerName: "Date d'émission",
                flex: 1/2,
                editable: true,
                type: 'date',
                renderCell: (cellValues) => {return  displayCreation(cellValues.row.start);}
            },
            { 
                field: 'status', 
                headerName: 'Statut',
                width: 140,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => { return <Chip label={cellValues.row.isvalided ? "Déja validé" : 'En attente'} sx={cellValues.row.isvalided ? {bgcolor:"#306bff", color:'white'} : {bgcolor:"gray", color:'white'}} /> }
            },
            { 
                field: 'accpter', 
                headerName: 'Accepter',
                width: 140,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            disabled={cellValues.row.isvalided}
                            onClick={() => { if (window.confirm('Êtes-vous sûr de valider la requette ?')){ this.valide(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'green', color:'white'}}}
                        >
                            Valider
                        </Button>
                    )
                }
            },
            { 
                field: 'supprimer', 
                headerName: 'Supprimer',
                width: 140,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            disabled={cellValues.row.isvalided}
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                }
            }

        ]

        return ( 
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item xs={12}  md={10} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ open: true })}
                        >
                            Expression du besion
                        </Button>          
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} >
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid> 

                    <Grid item md={openproduct ? 7 : 12} >
                        <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }}>
                            <DataGrid
                                rows={order}
                                columns={column}
                                onCellEditCommit={(props, event) => this.editTable(props)}
                                loading={loading}
                                elevation={10}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius:4,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                            />
                        </div>
                    </Grid>

                    {openproduct && 
                        <Grid item md={4.8} sx={{bgcolor:'white',ml:2, borderRadius:4,  boxShadow: 2}} mt={3}>
                            <Box style={{display: 'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', padding:0}} > 
                                {!getStauts(id_order) &&
                                    <Tooltip 
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit" sx={{fontSize:16}} >
                                                    Ajouter un produit à la liste de bon de commande
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    > 
                                        <IconButton 
                                            onClick={() => this.setState({ addProduit: true })}
                                            style={{backgroundColor:'#4777f4', }}
                                            sx={{width:20, height:20, p:2}}
                                        >
                                            <AddIcon sx={{color:"white"}} />
                                        </IconButton >
                                    </Tooltip>
                                }

                                <Typography 
                                    component="h1" 
                                    variant="h1" 
                                    sx={{color:"#313339", fontSize:26}}
                                >
                                    {commandeName}
                                </Typography>

                                <IconButton onClick={() =>  this.setState({ openproduct: false })} style={{backgroundColor:'#4777f4', }} sx={{width:20, height:20, m:2, p:2}}>   
                                    <CloseIcon sx={{color:"white"}} /> 
                                </IconButton>
                            </Box>

                            <table className="table table-hover"  style={{width: '100% !important'}}>
                                <thead>
                                    <tr>
                                        <th scope="col">Code</th>
                                        <th scope="col">Désignation</th>
                                        <th scope="col">Quantite</th>
                                        {!getStauts(id_order) ?
                                            <th scope="col">Supprimer</th>  
                                        :
                                            null
                                        }                                                                
                                    </tr>
                                </thead>
                            
                                <tbody>
                                    {oderproduct.map((answer) => { 
                                        return (
                                            <tr>
                                                <td>{displayCode(answer.id_fourniture)}</td>
                                                <td>{displaydesignation(answer.id_fourniture)}</td>
                                                <td>{answer.quantite.toLocaleString()}</td>
                                                {!getStauts(id_order) ?
                                                    <td>
                                                        <Button
                                                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deletep(answer.id) }}}
                                                            variant="contained"
                                                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                                                        >
                                                            Supprimer
                                                        </Button>
                                                    </td>
                                                :
                                                    null
                                                }
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Grid>                    
                    }
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={() => this.setState({ open: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouvel expression du besion
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2} mt={-3}>
                                <Grid item xs={12}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Libellé"
                                            name="numbon"
                                            style={{ width: '100%' }}
                                            value={numbon}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid> 

                                <Grid item xs={12}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label" style={{marginTop:"-16px"}}>Date d'émission</InputLabel>
                                        <DatePicker 
                                            selected={start}
                                            onChange={(date) => this.handleChangeDateStart(date)}
                                            value={start}
                                            id="demo-simple-select"
                                            maxDate={new Date()}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>    
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer la Nouvelle commande
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={addProduit}
                    onClose={() => this.setState({ addProduit: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:24}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouvau produit
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ addProduit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitProduit}>
                        <DialogContent>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <FormControl disabled style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Libellé</InputLabel>
                                        <Select
                                            required
                                            value={id_order || ''}
                                            label="Libellé"
                                            name="id_order"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {orders.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.numbon}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Code fourniture</InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_fourniture || ''}
                                            label="Code fourniture"
                                            name="id_fourniture"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {fournitures.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.code}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Désignation</InputLabel>
                                        <Select
                                            required
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={id_fourniture || ''}
                                            label="Code fourniture"
                                            name="id_fourniture"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >        
                                            {fournitures.map((answer) => { 
                                                return  <MenuItem value={answer.id}>{answer.designation}</MenuItem>
                                            })}       
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Quantite"
                                            name="quantite"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            value={quantite}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid> 
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                            Cliquer pour enregistrer le nouveau produit
                        </Button>
                    </form>
                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )

    }

}
