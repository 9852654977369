import React, { Component } from "react"; 
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-date-picker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PrintIcon from '@mui/icons-material/Print';
import DeleteIcon from '@mui/icons-material/Delete';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Projets extends Component { 

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            project: [],
            voir: false,
            open:false,
            isDid: false,
            isNotDit:false, 
            edit:false,
            openEdit:false,
            loading: true,

            id:null,
            name: "",
            hoursbyday: "",
            country: "",
            location: "",
            budget: "",
            currency: "F CFA",
            bureaucontrole: "",
            maitredouvrage: "",
            maitredouvragedeleg: "",
            maitredouvragemissioncontrole: "",
            start: new Date(),
            close: new Date(),
            message:'',
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleEditerSubmit = this.handleEditerSubmit.bind(this)
    }

    componentDidMount() {       
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/projects', config)
        .then(response => {
            this.setState({ projects: response.data.projects, loading:false });
        }).catch(error => {
            alert(error);
        });
    }

    lookthis(id) {
        const project = this.state.projects.filter(c => c["id"] === id)
        this.setState({
            id: id,
            name: project[0].name,
            hoursbyday: project[0].hoursbyday,
            country: project[0].country,
            location: project[0].location,
            budget: project[0].budget,
            currency: project[0].currency,
            bureaucontrole: project[0].bureaucontrole,
            maitredouvrage: project[0].maitredouvrage,
            maitredouvragedeleg: project[0].maitredouvragedeleg,
            maitredouvragemissioncontrole: project[0].maitredouvragemissioncontrole,
            start: project[0].start,
            close: project[0].close,
            voir: true
        })
    }

    closeVoir(){
        this.setState({
            start: new Date(),
            close: new Date(),
            voir:false
        })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeDateStart(date){ this.setState({start: date})}

    handleChangeDateClose(date){ this.setState({close: date})}

    handleSubmit(event) {
        event.preventDefault()
        const datestart = Math.floor(new Date(this.state.start).getTime() / 1000)
        const dateClose = Math.floor(new Date(this.state.close).getTime() / 1000)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };        
        const bodyParameters = {
            name: this.state.name,
            hoursbyday: this.state.hoursbyday,
            country: this.state.country,
            location: this.state.location,
            budget: this.state.budget,
            currency: this.state.currency,
            bureaucontrole: this.state.bureaucontrole,
            maitredouvrage: this.state.maitredouvrage,
            maitredouvragedeleg: this.state.maitredouvragedeleg,
            maitredouvragemissioncontrole: this.state.maitredouvragemissioncontrole,
            start: datestart,
            close: dateClose
        };        
        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/project',
            bodyParameters,
            config
        ).then(response => {            
            this.setState({ message:"Projet ajouté avec succès", isDid: true, open:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message:"Erreur d'enregistrement", isNotDit: true });
            console.log("error", error)
        });
    }

    edit(id){
        this.setState({
            start: new Date(),
            close: new Date(),
            voir: false,
        })        
        const project = this.state.projects.filter(c => c["id"] === id)
        this.setState({
            id: id,
            name: project[0].name,
            hoursbyday: project[0].hoursbyday,
            country: project[0].country,
            location: project[0].location,
            budget: project[0].budget,
            currency: project[0].currency,
            bureaucontrole: project[0].bureaucontrole,
            maitredouvrage: project[0].maitredouvrage,
            maitredouvragedeleg: project[0].maitredouvragedeleg,
            maitredouvragemissioncontrole: project[0].maitredouvragemissioncontrole,
            start: new Date(project[0].start *1000),
            close: new Date(project[0].close *1000),
            openEdit: true
        })
    }

    handleEditerSubmit(event) {
        event.preventDefault()
        const datestart = Math.floor(new Date(this.state.start).getTime() / 1000)
        const dateClose = Math.floor(new Date(this.state.close).getTime() / 1000)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };        
        const bodyParameters = {
            name: this.state.name,
            hoursbyday: this.state.hoursbyday,
            country: this.state.country,
            location: this.state.location,
            budget: this.state.budget,
            currency: this.state.currency,
            bureaucontrole: this.state.bureaucontrole,
            maitredouvrage: this.state.maitredouvrage,
            maitredouvragedeleg: this.state.maitredouvragedeleg,
            maitredouvragemissioncontrole: this.state.maitredouvragemissioncontrole,
            start: datestart,
            close: dateClose
        };   
        axios.put( 
            'https://api-gn.projectmanagementsolutions.net/api/project/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {                     
            this.setState({ isDid: true, openEdit:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
            console.log("error", error)
        });    
    }

    delete(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/project/' + this.state.id,
            config
        ).then(response => {                     
            this.setState({message:"Projet supprimé avec succès", isDid: true, voir:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message:"Erreur de suppression", isNotDit: true });
            console.log("error", error)
        });    

    }

    editTable(props){
        console.log('Ahmed', props)
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };    
        let bodyParameters = {}
        if(props.field === "name"){
            bodyParameters = {
                name:props.value,
                hoursbyday: props.row.hoursbyday,
                country: props.row.country,
                location: props.row.location,
                budget: props.row.budget,
                currency: props.row.currency,
                bureaucontrole: props.row.bureaucontrole,
                maitredouvrage: props.row.maitredouvrage,
                maitredouvragedeleg: props.row.maitredouvragedeleg,
                maitredouvragemissioncontrole: props.row.maitredouvragemissioncontrole,
                start: props.row.start,
                close: props.row.close
            }  
        }else if(props.field === "hoursbyday"){ 
            bodyParameters = {
                name:props.row.name,
                hoursbyday: props.value,
                country: props.row.country,
                location: props.row.location,
                budget: props.row.budget,
                currency: props.row.currency,
                bureaucontrole: props.row.bureaucontrole,
                maitredouvrage: props.row.maitredouvrage,
                maitredouvragedeleg: props.row.maitredouvragedeleg,
                maitredouvragemissioncontrole: props.row.maitredouvragemissioncontrole,
                start: props.row.start,
                close: props.row.close
            }   
        }else if(props.field === "country"){
            bodyParameters = {
                name:props.row.name,
                hoursbyday: props.row.hoursbyday,
                country: props.value,
                location: props.row.location,
                budget: props.row.budget,
                currency: props.row.currency,
                bureaucontrole: props.row.bureaucontrole,
                maitredouvrage: props.row.maitredouvrage,
                maitredouvragedeleg: props.row.maitredouvragedeleg,
                maitredouvragemissioncontrole: props.row.maitredouvragemissioncontrole,
                start: props.row.start,
                close: props.row.close
            }   
        }else if(props.field === "location"){
            bodyParameters = {
                name:props.row.name,
                hoursbyday: props.row.hoursbyday,
                country: props.row.country,
                location: props.value,
                budget: props.row.budget,
                currency: props.row.currency,
                bureaucontrole: props.row.bureaucontrole,
                maitredouvrage: props.row.maitredouvrage,
                maitredouvragedeleg: props.row.maitredouvragedeleg,
                maitredouvragemissioncontrole: props.row.maitredouvragemissioncontrole,
                start: props.row.start,
                close: props.row.close
            }   
        }else if(props.field === "start"){
            const datestart = Math.floor(new Date(props.value).getTime() / 1000)
            bodyParameters = {
                name:props.row.name,
                hoursbyday: props.row.hoursbyday,
                country: props.row.country,
                location: props.row.location,
                budget: props.row.budget,
                currency: props.row.currency,
                bureaucontrole: props.row.bureaucontrole,
                maitredouvrage: props.row.maitredouvrage,
                maitredouvragedeleg: props.row.maitredouvragedeleg,
                maitredouvragemissioncontrole: props.row.maitredouvragemissioncontrole,
                start: datestart,
                close: props.row.close
            }   
        }else if(props.field === "close"){
            const datestart = Math.floor(new Date(props.value).getTime() / 1000)
            bodyParameters = {
                name:props.row.name,
                hoursbyday: props.row.hoursbyday,
                country: props.row.country,
                location: props.row.location,
                budget: props.row.budget,
                currency: props.row.currency,
                bureaucontrole: props.row.bureaucontrole,
                maitredouvrage: props.row.maitredouvrage,
                maitredouvragedeleg: props.row.maitredouvragedeleg,
                maitredouvragemissioncontrole: props.row.maitredouvragemissioncontrole,
                start: props.row.start,
                close: datestart
            }   
        }
        axios.put( 
            'https://api-gn.projectmanagementsolutions.net/api/project/' + props.id,
            bodyParameters,
            config
        ).then(response => {             
            this.setState({ message:"Projet modifié avec succès", isDid: true});
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({message:"Erreur dans le modification",  isNotDit: true });
            console.log("error", error)
        });    
    }

    render() {

        const { openEdit, message, id, edit, isDid, isNotDit, open, projects, loading, voir, name, hoursbyday, country, location, budget, currency, bureaucontrole, maitredouvrage, maitredouvragedeleg, maitredouvragemissioncontrole, start, close } = this.state

        const displayStatut = (close) => {
            let result = 0
            let currentTimeInSeconds = Math.floor(Date.now() / 1000)
            result = currentTimeInSeconds - close
            if (result < 0) {
                return <Button
                            variant="contained"
                            sx={{background:'#1db50f', height:30, ':hover':{background:'#1db50f'}}}
                        >
                            EN COURS
                        </Button>
            } else {
                return <Button
                            variant="contained"
                            sx={{background:'#e6374c', height:30, ':hover':{background:'#e6374c'}}}
                        >
                            Terminer
                        </Button>
            }
        }

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const displayModification = (timestamp) => {
            let date = new Date(timestamp);
            let dateMDY = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            return dateMDY
        }

        const columns = [
            { 
                field: 'name', 
                headerName: 'Libellé',
                cellClassName: 'columnClasse',
                editable: true,
                flex: 4/9
            },
            { 
                field: 'country', 
                headerName: 'Pays',
                editable: true,
                flex: 1/9
            },
            { 
                field: 'location', 
                headerName: 'Localité',
                editable: true,
                flex: 1/9
            },
            { 
                field: 'start', 
                headerName: 'Date Dém.',
                type: 'date',
                editable: true,
                flex: 1/9,
                renderCell: (cellValues) => {
                    return displayCreation(cellValues.row.start);
                }
            },
            { 
                field: 'close', 
                headerName: 'Date Clôt.',
                type: 'date',
                editable: true,
                flex: 1/9,
                renderCell: (cellValues) => {
                    return displayCreation(cellValues.row.close);
                }
            },
            
            { 
                field: 'hoursbyday', 
                headerName: 'Heures:Jours',
                editable: true,
                type: 'number',
                flex: 1/9,
                renderCell: (cellValues) => {
                    return cellValues.row.hoursbyday + " H/J" ;
                }
            },
            
            { 
                field: 'statut', 
                headerName: '',
                flex: 1/9,
                renderCell: (cellValues) => {
                    return displayStatut(cellValues.row.close);
                }
            },

            { 
                field: 'Voir', 
                headerName: '',
                flex: 1/9,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { this.lookthis(cellValues.row.id) }}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#2f4cdd', color:'white'}}}
                        >
                            Détails
                        </Button>
                    )
                    
                }
            }
        ];

        const grids = [
            [{ value: 1 }, { value: 3 }],
            [{ value: 2 }, { value: 4 }],
        ]

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            fullWidth
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ open: true })}
                        >
                            Ajouter un projet
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={1} >
                        <Tooltip 
                            title={<React.Fragment>
                                <Typography color="inherit" sx={{fontSize:20}} >Projet</Typography>
                                <Typography color="inherit" sx={{fontSize:16}} >
                                    Les informations générales sur le chantier (Projet, Pays, Localité, Budjet, Bureau de contrôle, Maître d'ouvrage, Maître d'ouvrage délégué,
                                    Maître d'ouvrage (Mission de contôle), Heures de travail/jour, Date de démarrage, Date de clôture) sont Enregistrées via le Bouton <stong>NOUVEAU</stong> ci-contre 
                                </Typography>
                            </React.Fragment>
                            }
                        >                        
                            <Button 
                                variant="contained" 
                                sx={{bgcolor:'#5d95ed', height:50}}
                            >
                                <AnnouncementOutlinedIcon/>
                            </Button>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={false} sm={false} md={9} />

                    <Grid item xs={12} sm={6} md={12} >
                        <div className="card" style={{ height: 640, width: '100%',borderRadius:16 }}>
                            <DataGrid
                                rows={projects}
                                columns={columns}
                                onCellEditCommit={(props, event) => this.editTable(props)}
                                loading={loading}
                                elevation={10}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius:4,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                      },
                                      '&.MuiDataGrid-root': {
                                        border: 'none',
                                      },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                                // pageSize={10}
                                // rowsPerPageOptions={[10]}
                            />
                        </div>    
                    </Grid>
                </Grid>              

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={voir}
                    onClose={() => this.closeVoir()}
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#fafafa'}}}    
                >
                    <DialogContent style={{display: 'flex', flexDirection:'column'}}>
                        <Box style={{display: 'flex', flexDirection:'column', padding:8}} ref={el => (this.componentRef = el)}>
                            <Box style={{width: "100%", display: 'flex', justifyContent:'flex-end'}}>
                                <Button
                                    sx={{height:40, borderRadius:8, color:'gray', borderWidth:1, border:' 1px solid gray', p:2, mr:2 }}
                                    startIcon={<EditIcon /> }
                                    onClick={() => this.edit(id)}
                                >   
                                    Mod.
                                </Button>

                                <IconButton onClick={() => this.closeVoir()}>
                                    <CloseIcon /> 
                                </IconButton>
                            </Box>

                            <Typography component="h1" variant="h1" sx={{fontSize:20, color:'gray'}} >Projet </Typography>

                            <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{name}</Typography>

                            <Box style={{width: "100%", display: 'flex', justifyContent:'space-between', marginTop:16, padding:8}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left'}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"gray", fontSize:18}}
                                    >
                                        Pays
                                    </Typography>

                                    <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{country}</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column'}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"gray", fontSize:18}}
                                    >
                                        Localité
                                    </Typography>

                                    <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{location}</Typography>
                                </Box>
                            </Box>

                            <Box style={{width: "100%", display: 'flex', justifyContent:'space-between', marginTop:16, padding:8}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', width:"50"}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"gray", fontSize:18}}
                                    >
                                        Budget
                                    </Typography>

                                    <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{budget.toLocaleString()}</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', width:"50"}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"gray", fontSize:18}}
                                    >
                                        Bureau de contrôle
                                    </Typography>

                                    <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{bureaucontrole}</Typography>
                                </Box>
                            </Box>

                            <Divider variant="middle" />

                            <Box style={{width: "100%", display: 'flex', justifyContent:'space-between', marginTop:16, padding:8}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', width:"50"}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"gray", fontSize:18}}
                                    >
                                        Maître d'ouvrage
                                    </Typography>

                                    <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{maitredouvrage}</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', width:"50"}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"gray", fontSize:18}}
                                    >
                                        Maître d'ouv. délég.</Typography>

                                    <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{maitredouvragedeleg}</Typography>
                                </Box>
                            </Box>

                            <Box style={{width: "100%", display: 'flex', justifyContent:'space-between', marginTop:16, padding:8}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', width:"50"}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"gray", fontSize:18}}
                                    >
                                        Maître d'ouv. (Mission de con.)
                                    </Typography>

                                    <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{maitredouvragemissioncontrole}</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', width:"50"}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"gray", fontSize:18}}
                                    >
                                        Heures de travail
                                    </Typography>

                                    <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{hoursbyday}</Typography>
                                </Box>
                            </Box>

                            <Box style={{width: "100%", display: 'flex', justifyContent:'space-between', marginTop:16, padding:8}}>
                                <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', width:"50"}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"gray", fontSize:18}}
                                    >
                                        Date de démarrage
                                    </Typography>

                                    <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{displayCreation(start)}</Typography>
                                </Box>

                                <Box sx= {{display: 'flex', flexDirection:'column', width:"50"}}>
                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"gray", fontSize:18}}
                                    >
                                        Date de clôture
                                    </Typography>

                                    <Typography component="h2" variant="h2" sx={{fontSize:16, color:'#3d3f45', mt:1}} >{displayCreation(close)}</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box style={{width: "100%", display: 'flex', justifyContent:'space-between', marginTop:16, padding:8}}>
                            <Box sx= {{display: 'flex', flexDirection:'column', alignItems: 'left', width:"50"}}>
                                {displayStatut(close)}
                            </Box>

                            <Box sx= {{display: 'flex', justifyContent:'flex-end', width:"50"}}>
                                <Button
                                    sx={{height:40, borderRadius:8, color:'gray', borderWidth:1, border:' 1px solid gray', p:2, mr:2 }}
                                    startIcon={<DeleteIcon /> }
                                    onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(id) }}}
                                >   
                                    supprimer.
                                </Button>

                                <ReactToPrint
                                    trigger={() => {
                                        return  <Button
                                                    sx={{height:40, borderRadius:8, color:'gray', borderWidth:1, border:' 1px solid gray', p:2 }}
                                                    startIcon={<PrintIcon /> }
                                                >   
                                                    Imprimer
                                                </Button>
                                    }}
                                    content={() => this.componentRef}
                                />                                
                            </Box>
                        </Box>
                    </DialogContent>                    
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={() => this.setState({ open: false })}   
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#fafafa'}}}                     
                >

                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouveau projet
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Projet"
                                            name="name"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Heures de travail/jour"
                                            name="hoursbyday"
                                            style={{ width: '100%' }}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>   

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Pays"
                                            name="country"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>       

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Localité"
                                            name="location"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>     

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Chifre d'affaire"
                                            name="budget"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>    

                                <Grid item xs={12} md={4}>
                                    <FormControl sx={{ m: 1 }} style={{ width: '100%', marginTop:'-1px', marginLeft:'-1px' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Monnaie</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={currency}
                                            label="Monnaie"
                                            name="currency"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >                                        
                                            <MenuItem value="F CFA">F CFA</MenuItem>
                                            <MenuItem value="USD">USD</MenuItem>
                                            <MenuItem value="EURO">EURO</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>       

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            id="outlined-required"
                                            label="Bureau de contrôle"
                                            name="bureaucontrole"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            id="outlined-required"
                                            label="Maître d'ouvrage"
                                            name="maitredouvrage"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            id="outlined-required"
                                            label="Maître d'ouvrage délégué"
                                            name="maitredouvragedeleg"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            id="outlined-required"
                                            label="Maître d'ouvrage (Mission de contrôle)"
                                            name="maitredouvragemissioncontrole"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label" style={{marginTop:"-20px"}}>Date de démarrage</InputLabel>
                                        <DatePicker 
                                            selected={start}
                                            //onSelect={(date) => this.handleChangeDateStart(date)}
                                            onChange={(date) => this.handleChangeDateStart(date)}
                                            value={start}
                                            id="demo-simple-select"
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label" style={{marginTop:"-20px"}}>Date de clôture</InputLabel>
                                        <DatePicker 
                                            selected={close}
                                            //onSelect={(date) => this.handleChangeDateClose(date)}
                                            value={close}
                                            onChange={(date) => this.handleChangeDateClose(date)}
                                            id="demo-simple-select"
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                    
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                                Cliquer pour enregistrer le nouveau projet
                        </Button>
                        
                    </form>                
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openEdit}
                    onClose={() => this.setState({ openEdit: false })}         
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#fafafa'}}}               
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier le projet
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openEdit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleEditerSubmit}>
                        
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Projet"
                                            name="name"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            value={name}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Heures de travail/jour"
                                            name="hoursbyday"
                                            style={{ width: '100%' }}
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={this.handleChange}
                                            value={hoursbyday}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>   

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Pays"
                                            name="country"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            value={country}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>       

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Localité"
                                            name="location"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            value={location}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>     

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Chifre d'affaire"
                                            name="budget"
                                            type="number"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            value={budget}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>    

                                <Grid item xs={12} md={4}>
                                <FormControl sx={{ m: 1 }} style={{ width: '100%', marginTop:'-1px', marginLeft:'-1px' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Monnaie</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            value={currency}
                                            label="Monnaie"
                                            name="currency"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        >                                        
                                            <MenuItem value="F CFA">F CFA</MenuItem>
                                            <MenuItem value="USD">USD</MenuItem>
                                            <MenuItem value="EURO">EURO</MenuItem>
                                        </Select>
                                        </FormControl>
                                </Grid>       

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            id="outlined-required"
                                            label="Bureau de contrôle"
                                            name="bureaucontrole"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            value={bureaucontrole}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            id="outlined-required"
                                            label="Maître d'ouvrage"
                                            name="maitredouvrage"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            value={maitredouvrage}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            id="outlined-required"
                                            label="Maître d'ouvrage délégué"
                                            name="maitredouvragedeleg"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            value={maitredouvragedeleg}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            id="outlined-required"
                                            label="Maître d'ouvrage (Mission de contrôle)"
                                            name="maitredouvragemissioncontrole"
                                            style={{ width: '100%' }}
                                            onChange={this.handleChange}
                                            value={maitredouvragemissioncontrole}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label" style={{marginTop:"-20px"}}>Date de démarrage</InputLabel>
                                        <DatePicker 
                                            selected={start}
                                            onChange={(date) => this.handleChangeDateStart(date)}
                                            value={start}
                                            id="demo-simple-select"
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <div className="input-group input-group-outline mb-4">  
                                        <InputLabel id="demo-simple-select-label" style={{marginTop:"-20px"}}>Date de clôture</InputLabel>
                                        <DatePicker 
                                            selected={close}
                                            value={close}
                                            onChange={(date) => this.handleChangeDateClose(date)}
                                            id="demo-simple-select"
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                    
                                </Grid>
                            </Grid>


                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                                Cliquer pour modifier le projet
                        </Button>
                        
                    </form>                
                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
            
        )
    }
}

const styles = {
    conlumn: {
       color: "red"
    }
}