import React, { Component } from "react"; 
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import axios from "axios";
import ProjetMenu  from "../../Layouts/ProjetMenu";
import ProjetPeriode from "../../Layouts/ProjetPeriode"
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TextField from '@mui/material/TextField';

export default class Rdepenses extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rapport_materiels: [],
            rapport_personnels: [],
            rapports: [],
            projects: [],
            bordereaux: [],
            rapport_fournitures: [],
            rapport_traitants: [],
            rapport_caisses: [],
            rapport_pieces: [],

            projetId: this.props.projetId,

            start: this.props.startDate,
            end: this.props.endDate,

            option: "Tout",
            anchorEl: null,
        }
        this.handleChange = this.handleChange.bind(this)
        this.idchange = this.idchange.bind(this)
        this.Datechange = this.Datechange.bind(this)
        this.handleClick= this.handleClick.bind(this)
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            this.setState({ 
                rapports: response.data.rapports,
                projects: response.data.projects,
                bordereaux: response.data.bordereaux,
                rapport_materiels: response.data.rapport_materiels,
                rapport_fournitures: response.data.rapport_fournitures,
                rapport_personnels: response.data.rapport_personnels,
                rapport_traitants: response.data.rapport_traitants,
                rapport_caisses: response.data.rapport_caisses,
                rapport_pieces: response.data.rapport_pieces
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    idchange(id){  
        let projet = this.state.projects.filter(c => c["id"] === id)[0]

        this.setState(
            { 
                projetId: id,
                start: new Date(projet.start *1000),
                end: new Date(projet.close *1000),
            },
            () => this.props.onChange(id)
        )
    }

    Datechange(startDate, endDate){
        this.setState({start: startDate, end: endDate})
    }

    handleClick(event){
        this.setState({anchorEl: event.currentTarget});
    }

    render() {

        const {rapport_caisses, anchorEl, projetId, rapport_pieces, rapport_traitants, rapport_fournitures, rapport_personnels, rapport_materiels, rapports, start, end, option, projects, bordereaux} = this.state

        const id_project = projetId

        const bordereau = bordereaux.filter(c => c["id_projet"] === id_project)

        let name = ""
        let pays= ""
        let curency = ""
        let localite = ""
        if(projects.filter(c => c["id"] === id_project)[0] !== undefined){
            name = projects.filter(c => c["id"] === id_project)[0].name
            pays= projects.filter(c => c["id"] === id_project)[0].country
            curency = projects.filter(c => c["id"] === id_project)[0].currency
            localite = projects.filter(c => c["id"] === id_project)[0].location
        }

        const b = rapports.filter(c => c["fait"] >= Math.floor(new Date(start).getTime() / 1000))
        const c = b.filter(c => c["fait"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
        let rapport = c        

        const columnsBordereau = [
            { 
                field: 'n_prix', 
                headerName: 'N° prix',
                flex: 1/15,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return <strong>{cellValues.row.n_prix}</strong>
                    }else{
                        return cellValues.row.n_prix
                    }                                       
                }
            },
            { 
                field: 'unite', 
                headerName: 'Unité',
                flex: 1/28,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return cellValues.row.unite
                    }                                       
                }
            },
            { 
                field: 'quantite', 
                headerName: 'Qté exécutée',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayQté(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'prix', 
                headerName: 'P. U.',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return (cellValues.row.prix).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'jr', 
                headerName: 'Nb jour',
                flex: 1/21,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayNbjour(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'materiel', 
                headerName: 'Matériel',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayMpu(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'carburant', 
                headerName: 'Carburant',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayMcarb(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'pusure', 
                headerName: 'P. usure',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayMpusure(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'personnel', 
                headerName: 'Personnel',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayPersonnel(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'fourniture', 
                headerName: 'Matériaux',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayMateriaux(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'traitant', 
                headerName: 'S-traitant',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayTraitant(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'caisse', 
                headerName: 'Caisse',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayCaisse(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'treef', 
                headerName: 'T. dépenses',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayMpusure(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id)).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'tbudget', 
                headerName: 'Prix de vente',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return (cellValues.row.prix * displayQté(cellValues.row.id)).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'tpercentt', 
                headerName: '',
                flex: 1/100,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        if((cellValues.row.prix * displayQté(cellValues.row.id)) - (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayMpusure(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id)) >= 0){
                            return (
                                <span style={{color:'green'}}> <TrendingUpIcon sx={{fontSize:26}} /></span> 
                            )
                             
                        }else{
                            return <span style={{color:'red'}}> <TrendingDownIcon sx={{fontSize:26}} /></span> 
                        }
                    }                                       
                }
            }, 
            { 
                field: 'tpercent', 
                headerName: 'T. Marge',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        if((cellValues.row.prix * displayQté(cellValues.row.id)) - (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayMpusure(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id)) >= 0){
                            return (
                                <span style={{color:'green'}}> {((cellValues.row.prix * displayQté(cellValues.row.id)) - (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayMpusure(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id))).toLocaleString()}</span> 
                            )
                             
                        }else{
                            return <span style={{color:'red'}}> {((cellValues.row.prix * displayQté(cellValues.row.id)) - (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayMpusure(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id))).toLocaleString()}</span> 
                        }
                    }                                       
                }
            } 
            
        ]

        const columnsBordereauPrix = [
            { 
                field: 'n_prix', 
                headerName: 'N° prix',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return <strong>{cellValues.row.n_prix}</strong>
                    }else{
                        return cellValues.row.n_prix
                    }                                       
                }
            },
            { 
                field: 'unite', 
                headerName: 'Unité',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return cellValues.row.unite
                    }                                       
                }
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayQté(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'prix', 
                headerName: 'Prix',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return (cellValues.row.prix).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'jr', 
                headerName: 'Nb jour',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayNbjour(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'materiel', 
                headerName: 'Matériel',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayMpu(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'carburant', 
                headerName: 'Carburant',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayMcarb(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'personnel', 
                headerName: 'Personnel',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayPersonnel(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'fourniture', 
                headerName: 'Matériaux',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayMateriaux(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'traitant', 
                headerName: 'S-traitant',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayTraitant(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'caisse', 
                headerName: 'Caisse',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return displayCaisse(cellValues.row.id).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'treef', 
                headerName: 'T. Réef',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id)).toLocaleString()
                    }                                       
                }
            },
            { 
                field: 'tbudget', 
                headerName: 'T. Budget',
                flex: 1/13,
                renderCell: (cellValues) => {
                    if( (cellValues.row.n_prix % 100) === 0 ) {
                        return ""
                    }else{
                        return (cellValues.row.prix * displayQté(cellValues.row.id)).toLocaleString()
                    }                                       
                }
            }
        ]

        const columnsBoservation = [
            { 
                field: 'fait',
                headerName: 'Date du  rapport',
                flex: 1/3,
                renderCell: (cellValues) => {return  displayCreation(cellValues.row.fait)}
            },
            { 
                field: 'id_bordereau', 
                headerName: 'Bordereau',
                flex: 2/3,
                renderCell: (cellValues) => {return  displayBordereau(cellValues.row.id_bordereau)}
            },
            { 
                field: 'observation', 
                headerName: 'Observation',
                flex: 1
            },
        ]

        const displayBordereau = (id_bordereau) => {
            let resultat = ""
            resultat = bordereaux.filter(c => c["id"] === id_bordereau)[0].designation           
            return resultat
        }

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const displayCaisse = (id_bordereau) => {
            let resultat = 0
            let item = []
            let items = []
            item = rapport.filter(c => c["id_bordereau"] === id_bordereau )
            item.map((answer) => {     
                items = rapport_caisses.filter(c => c["id_rapport"] === answer.id )     
                items.map((answer) => {      
                   resultat = resultat +  answer.somme
                })
            })       
            return resultat
        }

        const displayTraitant = (id_bordereau) => {
            let resultat = 0
            let item = []
            let items = []
            item = rapport.filter(c => c["id_bordereau"] === id_bordereau )
            item.map((answer) => {     
                items = rapport_traitants.filter(c => c["id_rapport"] === answer.id )     
                items.map((answer) => {      
                   resultat = resultat + (answer.quantite * answer.taux)
                })
            })       
            return resultat
        }

        const displayMateriaux = (id_bordereau) => {
            let resultat = 0
            let item = []
            let items = []
            item = rapport.filter(c => c["id_bordereau"] === id_bordereau )
            item.map((answer) => {     
                items = rapport_fournitures.filter(c => c["id_rapport"] === answer.id )     
                items.map((answer) => {      
                   resultat = resultat + (answer.quantite * answer.taux)
                })
            })       
            return resultat
        }

        const displayPersonnel = (id_bordereau) => {
            let resultat = 0
            let item = []
            let items = []
            item = rapport.filter(c => c["id_bordereau"] === id_bordereau )
            item.map((answer) => {     
                items = rapport_personnels.filter(c => c["id_rapport"] === answer.id )     
                items.map((answer) => {      
                   resultat = resultat + (answer.heures * answer.taux)
                })
            })       
            return resultat
        }

        const displayMcarb = (id_bordereau) => {
            let resultat = 0
            let item = []
            let items = []
            item = rapport.filter(c => c["id_bordereau"] === id_bordereau )
            item.map((answer) => {     
                items = rapport_materiels.filter(c => c["id_rapport"] === answer.id )     
                items.map((answer) => {      
                   resultat = resultat + (answer.prix * answer.carburant)
                })
            })       
            return resultat
        }

        const displayMpusure = (id_bordereau) => {
            let resultat = 0
            let item = []
            let items = []
            item = rapport.filter(c => c["id_bordereau"] === id_bordereau )
            item.map((answer) => {     
                items = rapport_pieces.filter(c => c["id_rapport"] === answer.id )     
                items.map((answer) => {      
                   resultat = resultat + (answer.quantite * answer.taux)
                })
            })       
            return resultat
        }

        const displayMpu = (id_bordereau) => {
            let resultat = 0
            let item = []
            let items = []
            item = rapport.filter(c => c["id_bordereau"] === id_bordereau )
            item.map((answer) => {     
                items = rapport_materiels.filter(c => c["id_rapport"] === answer.id )     
                items.map((answer) => {      
                   resultat = resultat + (answer.hj * answer.taux)
                })
            })       
            return resultat
        }

        const displayQté = (id_bordereau) => {
            let resultat = 0
            let item = []
            item = rapport.filter(c => c["id_bordereau"] === id_bordereau )
            item.map((answer) => {                 
                resultat = resultat + answer.quantite
            })       
            return resultat
        }

        const displayNbjour = (id_bordereau) => {
            let item = []
            item = rapport.filter(c => c["id_bordereau"] === id_bordereau )
            return item.length
        }

        const getTq = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + displayQté(element.id)
            })
            return total
        }

        const getTPu = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + element.prix
            })
            return total
        }

        const getTNj = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + displayNbjour(element.id)
            })
            return total
        }

        const getTMa = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + displayMpu(element.id)
            })
            return total
        }

        const getTCa = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + displayMcarb(element.id)
            })
            return total
        }

        const getTPU = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + displayMpusure(element.id)
            })
            return total
        }

        const getTPe = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + displayPersonnel(element.id)
            })
            return total
        }

        const getTFo = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + displayMateriaux(element.id)
            })
            return total
        }

        const getTSt = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + displayTraitant(element.id)
            })
            return total
        }

        const getTCai = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + displayCaisse(element.id)
            })
            return total
        }

        const getTPv  = ()  => {
            let total = 0
            bordereau.map(element => {
                total = total + element.prix * displayQté(element.id)
            })
            return total
        }

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2 }} container spacing={6} >
                    <Grid item xs={12} sm={6}  md={5}>                        
                        <Typography sx={{color:'#46505b', fontSize:28 }}>{pays}, {localite}</Typography>

                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"black", fontWeight: 400, fontSize:24}}
                        >
                            {name.substring(0, 60)}
                        </Typography>                                              
                    </Grid>  

                    <Grid item xs={12} sm={6} md={2}>
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <ProjetPeriode startDate={start}  endDate={end} onChange={this.Datechange}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <Card elevation={1}  sx={{height:70, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                            <Typography sx={{color:'#46505b', pl:1}}>{option}</Typography>

                            <Box sx={{ flexGrow: 1 }} />

                            <IconButton onClick={this.handleClick} >
                                <ExpandMoreIcon />
                            </IconButton>

                            <Menu
                                elevation={12}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => this.setState({anchorEl: null}) }
                                PaperProps={{
                                    sx: {
                                        //width: 300,
                                        //backgroundColor:'gray',                                    
                                    }
                                }}
                            >
                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({ option: "Tout", anchorEl: null })} 
                                    sx={option === "Tout" ? {color:'#2f4cdd'} : null}
                                >
                                    Tout
                                </MenuItem>

                                <MenuItem 
                                    disableRipple 
                                    onClick={() => this.setState({ option: "Observation", anchorEl: null })} 
                                    sx={option === "Observation" ? {color:'#2f4cdd'} : null}
                                >
                                    Observation
                                </MenuItem>                                
                            </Menu>
                            
                         </Card>
                    </Grid>

                    {option === "Tout" ? 
                        <React.Fragment>
                            <Grid item xs={12} sm={12} md={12}>
                                <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                                    <DataGrid
                                        rows={bordereau}
                                        columns={columnsBordereau}
                                        elevation={10}
                                        sx={{
                                            boxShadow: 2,
                                            borderRadius:4,
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}                                    
                                    />        
                                </div>                        
                            </Grid>    

                            <Grid item xs={12} sm={12} md={12}>
                                <Grid sx={{ flexGrow: 1, mb:2 }} container spacing={2} > 
                                    <Grid item xs={12} md={12/10}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Matériel"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={getTMa().toLocaleString() || ''}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/10}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Carburant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={getTCa().toLocaleString() || ''}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/10}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. P. usure"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={getTPU().toLocaleString() || ''}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/10}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Personnel"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={getTPe().toLocaleString() || ''}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/10}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Matériaux"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={getTFo().toLocaleString() || ''}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/10}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. S-traitant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={getTSt().toLocaleString() || ''}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/10}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Caisse"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={getTCai().toLocaleString() || ''}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/10}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Dépenses"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={(getTMa() + getTCa() + getTPU() + getTPe() + getTFo() + getTSt() + getTCai()).toLocaleString() || ''}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/10}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Prix de vente"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={getTPv().toLocaleString() || ''}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/10}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Marge"
                                                variant="outlined"
                                                style={{ width: '100%'}}
                                                sx={ (getTPv() - (getTMa() + getTCa() + getTPU() + getTPe() + getTFo() + getTSt() + getTCai())) >=0 ? { input:{ color: 'green', bgcolor:'#ffffff' } } : { input:{ color: 'red', bgcolor:'#ffffff' } }}  
                                                value={(getTPv() - (getTMa() + getTCa() + getTPU() + getTPe() + getTFo() + getTSt() + getTCai())).toLocaleString() || ''}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>                       
                        
                        : 
                        
                        <Grid item xs={12} sm={12} md={12}>
                            <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }}>
                                <DataGrid
                                    rows={rapport}
                                    columns={columnsBoservation}
                                    elevation={10}
                                    sx={{
                                        boxShadow: 2,
                                        borderRadius:4,
                                        '.MuiDataGrid-columnSeparator': {
                                            display: 'none',
                                            },
                                            '&.MuiDataGrid-root': {
                                            border: 'none',
                                            },
                                        '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                            color:'black',
                                            fontWeight: 600,
                                            fontSize:18
                                        }
                                    }}                            
                                />        
                            </div>                        
                        </Grid>
                    }
                </Grid>
            </React.Fragment>
        )
    }
}