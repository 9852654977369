import React, { Component } from "react"; 
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import axios from "axios";
import ProjetMenu  from "../../Layouts/ProjetMenu";
import ProjetPeriode from "../../Layouts/ProjetPeriode"
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

export default class Prmoyens extends Component {

    constructor(props) {
        super(props)
        this.state = {
            bordereaux: [],
            projects: [],
            rapports: [],
            rapport_materiels:[],
            materiels: [],
            rapport_personnels: [],
            personnels: [],
            rapport_fournitures:[], 
            fournitures: [],
            rapport_traitants: [],
            traitants: [],
            rapport_caisses:[],
            rapport_pieces: [],
            pieces: [],

            projetId: this.props.projetId,

            id_bordereau: null,
            id_fourniture: null,

            start: this.props.startDate,
            end: this.props.endDate, 

            open: false,
            valuetab:"1",
            valuetab2: "1"
        }
        this.handleChange = this.handleChange.bind(this)
        this.idchange = this.idchange.bind(this)
        this.Datechange = this.Datechange.bind(this)
        this.handleChangetab = this.handleChangetab.bind(this)
        this.handleChangetab2 = this.handleChangetab2.bind(this)
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            this.setState({ 
                rapports: response.data.rapports,
                projects: response.data.projects,
                bordereaux: response.data.bordereaux,
                rapport_materiels: response.data.rapport_materiels,
                materiels: response.data.materiels,
                rapport_fournitures: response.data.rapport_fournitures,
                rapport_personnels: response.data.rapport_personnels,
                personnels: response.data.personnels,
                fournitures: response.data.fournitures,
                rapport_traitants: response.data.rapport_traitants,
                traitants: response.data.traitants,
                rapport_caisses: response.data.rapport_caisses,
                rapport_pieces: response.data.rapport_pieces,
                pieces: response.data.pieces
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    idchange(id){  
        let projet = this.state.projects.filter(c => c["id"] === id)[0]

        this.setState(
            { 
                projetId: id,
                start: new Date(projet.start *1000),
                end: new Date(projet.close *1000),
            },
            () => this.props.onChange(id)
        )
    }

    Datechange(startDate, endDate){
        this.setState({start: startDate, end: endDate})
    }

    handleChangetab(event, newValue){
        this.setState({ valuetab: newValue});
    }

    handleChangetab2(event, newValue){
        this.setState({ valuetab2: newValue});
    }

    render() {

        const {rapport_caisses, valuetab2, valuetab, projetId, rapport_pieces, pieces, traitants, rapport_traitants, personnels, fournitures, rapport_fournitures, start, end, id_bordereau, bordereaux, open, projects, rapports, rapport_materiels, materiels, rapport_personnels} = this.state

        const id_project = projetId
        
        const bordereau = bordereaux.filter(c => c["id_projet"] === id_project)

        let name = ""
        let pays= ""
        let curency = ""
        let localite =""
        if(projects.filter(c => c["id"] === id_project)[0] !== undefined){
            name = projects.filter(c => c["id"] === id_project)[0].name
            pays= projects.filter(c => c["id"] === id_project)[0].country
            curency = projects.filter(c => c["id"] === id_project)[0].currency
            localite =  projects.filter(c => c["id"] === id_project)[0].location
        }
        let designation = ""
        let pUCredit = 0
        if(bordereaux.filter(c => c["id"] === id_bordereau)[0] !== undefined){
            designation = bordereaux.filter(c => c["id"] === id_bordereau)[0].designation
            pUCredit = bordereaux.filter(c => c["id"] === id_bordereau)[0].prix
        }

        const displayQuantiteCumule = () => {
            let resultat = 0
            rapport.map((answer) => {
                resultat = resultat + answer.quantite
            })
            return resultat
        }

        const a = rapports.filter(c => c["id_projet"] === id_project)
        const d = a.filter(c => c["id_bordereau"] === id_bordereau)  
        const b = d.filter(c => c["fait"] >= Math.floor(new Date(start).getTime() / 1000))
        const c = b.filter(c => c["fait"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
        let rapport = c

        const rapport_materiel = () => {
            let resultat = []
            let item ={}
            let filtered = []
            let mapped = []
            rapport.map((answer) => {
                item = rapport_materiels.filter(c => c["id_rapport"] === answer.id)                              
                resultat = resultat.concat(item)
            })
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_materiel === ele.id_materiel))
            return pp
        }

        const rapport_personnel = () => {
            let resultat = []
            let item ={}
            let filtered = []
            let mapped = []
            rapport.map((answer) => {
                item = rapport_personnels.filter(c => c["id_rapport"] === answer.id)                              
                resultat = resultat.concat(item)
            })
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_personnel === ele.id_personnel))
            return pp
        }

        const rapport_fourniture = () => {
            let resultat = []
            let item ={}
            let filtered = []
            let mapped = []
            rapport.map((answer) => {
                item = rapport_fournitures.filter(c => c["id_rapport"] === answer.id)                              
                resultat = resultat.concat(item)
            })
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_fourniture	 === ele.id_fourniture	))
            return pp
        }

        const rapport_piece = () => {
            let resultat = []
            let item ={}
            let filtered = []
            let mapped = []
            rapport.map((answer) => {
                item = rapport_pieces.filter(c => c["id_rapport"] === answer.id)                              
                resultat = resultat.concat(item)
            })
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_pieceusure	 === ele.id_pieceusure	))
            return pp
        }

        const rapport_traitant = () => {
            let resultat = []
            let item ={}
            let filtered = []
            let mapped = []
            rapport.map((answer) => {
                item = rapport_traitants.filter(c => c["id_rapport"] === answer.id)                              
                resultat = resultat.concat(item)
            })
            let pp = resultat.filter( (ele, ind) => ind === resultat.findIndex( elem => elem.id_traitant	 === ele.id_traitant	))
            return pp
        }

        const rapport_caisse = () => {
            let resultat = []
            let item ={}
            rapport.map((answer) => {
                item = rapport_caisses.filter(c => c["id_rapport"] === answer.id)                              
                resultat = resultat.concat(item)
            })
            return resultat
        }      
        
        const displayPieceDesign = (id) => {
            let resultat = ""
            if(pieces.filter(c => c["id"] === id)[0] !== undefined){
                resultat =  pieces.filter(c => c["id"] === id)[0].designation      
            }     
            return resultat
        }

        const colomnMateriel =  [
            { 
                field: 'gm', 
                headerName: 'Machine',
                flex: 1/8,
                renderCell: (cellValues) => {return  displayGM(cellValues.row.id_materiel)}
            },
            { 
                field: 'type', 
                headerName: 'Modèle',
                flex: 1/8,
                renderCell: (cellValues) => {return  displayType(cellValues.row.id_materiel)}
            },
            { 
                field: 'chassis', 
                headerName: 'N° Chassis',
                flex: 1/8,
                renderCell: (cellValues) => {return displaychassis(cellValues.row.id_materiel)}
            }, 
            { 
                field: 'taux', 
                headerName: 'Tarif',
                type: 'number',
                flex: 1/8,
                renderCell: (cellValues) => {return  displayTaux(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'hj', 
                headerName: 'Heure/J',
                type: 'number',
                flex: 1/8,
                renderCell: (cellValues) => {return  displayHJ(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'carburant', 
                headerName: 'Carb. (litre)',
                type: 'number',
                flex: 1/8,
                renderCell: (cellValues) => {return  displaycar(cellValues.row.id_materiel, cellValues.row.id_rapport).toLocaleString()}
            },  
            { 
                field: 'location', 
                headerName: 'Location',
                type: 'number',
                flex: 1/8,
                renderCell: (cellValues) => {return  (displayHJ(cellValues.row.id_materiel, cellValues.row.id_rapport) * displayTaux(cellValues.row.id_materiel, cellValues.row.id_rapport)).toLocaleString()}
            },
            { 
                field: 'montcarburant', 
                headerName: 'Carburant',
                type: 'number',
                flex: 1/8,
                renderCell: (cellValues) => {return  (displaycar(cellValues.row.id_materiel, cellValues.row.id_rapport) * displayPrix(cellValues.row.id_materiel, cellValues.row.id_rapport)).toLocaleString()}
            },  
        ]

        const colomnPersonnel =  [
            { 
                field: 'name', 
                headerName: 'Personnel',
                flex: 2/6,
                renderCell: (cellValues) => {return  displayName(cellValues.row.id_personnel)}
            },
            { 
                field: 'categorie', 
                headerName: 'Catégorie',
                flex: 1/6,
                renderCell: (cellValues) => {return  displayCategorie(cellValues.row.id_personnel)}
            },
            { 
                field: 'description', 
                headerName: 'Personnel',
                flex: 2/6,
                renderCell: (cellValues) => {return  displayDescription(cellValues.row.id_personnel)}
            },
            { 
                field: 'taux', 
                headerName: 'Tarif',
                type: 'number',
                flex: 1/6,
                renderCell: (cellValues) => {return  displayTauxPersonnel(cellValues.row.id_personnel, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'heures', 
                headerName: 'Heure/J',
                type: 'number',
                flex: 1/6,
                renderCell: (cellValues) => {return  displayHeurePersonnel(cellValues.row.id_personnel, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                type: 'number',
                flex: 2/6,
                renderCell: (cellValues) => {return  (displayTauxPersonnel(cellValues.row.id_personnel, cellValues.row.id_rapport) * displayHeurePersonnel(cellValues.row.id_personnel, cellValues.row.id_rapport)).toLocaleString()}
            }, 
            
        ]
        
        const columnsFourniture = [
            { 
                field: 'code', 
                headerName: 'Code',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayFournitureCode(cellValues.row.id_fourniture)}
            },
            { 
                field: 'description', 
                headerName: 'Matériaux',
                flex: 2/5,
                renderCell: (cellValues) => {return  displayMaterieaux(cellValues.row.id_fourniture)}
            },
            { 
                field: 'taux', 
                headerName: 'P.U',
                flex: 1/5,
                type: 'number',
                renderCell: (cellValues) => {return  displayTauxMaterieaux(cellValues.row.id_fourniture, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité',
                type: 'number',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayQtéMaterieaux(cellValues.row.id_fourniture, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                type: 'number',
                flex: 2/5,
                renderCell: (cellValues) => {return  (displayQtéMaterieaux(cellValues.row.id_fourniture, cellValues.row.id_rapport) * displayTauxMaterieaux(cellValues.row.id_fourniture, cellValues.row.id_rapport)).toLocaleString()}
            } 
        ]

        const columnsPiece = [            
            { 
                field: 'description', 
                headerName: 'Désignation',
                flex: 2/5,
                renderCell: (cellValues) => {return  displayPieceDesign(cellValues.row.id_pieceusure)}
            },
            { 
                field: 'taux', 
                headerName: 'P.U',
                flex: 1/5,
                type: 'number',
                renderCell: (cellValues) => {return  displayTauxPusure(cellValues.row.id_pieceusure, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité',
                type: 'number',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayQtéPusure(cellValues.row.id_pieceusure, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                type: 'number',
                flex: 1/5,
                renderCell: (cellValues) => {return  (displayQtéPusure(cellValues.row.id_pieceusure, cellValues.row.id_rapport) * displayTauxPusure(cellValues.row.id_pieceusure, cellValues.row.id_rapport)).toLocaleString()}
            } 
        ]

        const columnsTraitant = [
            { 
                field: 'code', 
                headerName: 'Code',
                flex: 1/5,
                renderCell: (cellValues) => {return  displayTraitantCode(cellValues.row.id_traitant)}
            },
            { 
                field: 'designation', 
                headerName: 'Sous-traitant',
                flex: 2/5,
                renderCell: (cellValues) => {return  displayTraitantDesignation(cellValues.row.id_traitant)}
            },
            { 
                field: 'taux', 
                headerName: 'P.U',
                flex: 1/5,
                type: 'number',
                renderCell: (cellValues) => {return  displayTauxTraitant(cellValues.row.id_traitant, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'quantite', 
                headerName: 'Qté',
                flex: 1/5,
                type: 'number',
                renderCell: (cellValues) => {return  displayQuantiteTraitant(cellValues.row.id_traitant, cellValues.row.id_rapport).toLocaleString()}
            },
            { 
                field: 'montant', 
                headerName: 'Montant',
                flex: 2/5,
                type: 'number',
                renderCell: (cellValues) => {return  (displayQuantiteTraitant(cellValues.row.id_traitant, cellValues.row.id_rapport) * displayTauxTraitant(cellValues.row.id_traitant, cellValues.row.id_rapport)).toLocaleString()}
            }
        ]   

        const columnsCaisse = [
            { 
                field: 'designation', 
                headerName: 'Code',
                flex: 1
            },
            { 
                field: 'somme', 
                headerName: 'Montant',
                type: 'number',
                flex: 1/2,
                renderCell: (cellValues) => {return  cellValues.row.somme.toLocaleString()}
            }
        ]

        const displayQuantiteTraitant = (id_traitant, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_traitants.filter(c => c["id_traitant"] === id_traitant )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.quantite
                n = n + 1
            })       
            return resultat/n
        }
        
        const displayTauxTraitant = (id_traitant, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_traitants.filter(c => c["id_traitant"] === id_traitant )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.taux
                n = n + 1
            })       
            return resultat/n
        }
        
        const displayTraitantCode= (id) => {
            let resultat = ""
            resultat = traitants.filter(c => c["id"] === id)[0].code           
            return resultat
        }

        const displayTraitantDesignation= (id) => {
            let resultat = ""
            resultat = traitants.filter(c => c["id"] === id)[0].designation           
            return resultat
        }

        const displayQtéMaterieaux = (id_fourniture, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_fournitures.filter(c => c["id_fourniture"] === id_fourniture )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.quantite
                n = n + 1
            })       
            return resultat/n
        }

        const displayQtéPusure = (id_pieceusure, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_pieces.filter(c => c["id_pieceusure"] === id_pieceusure )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.quantite
                n = n + 1
            })       
            return resultat/n
        }

        const displayTauxMaterieaux  = (id_fourniture, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_fournitures.filter(c => c["id_fourniture"] === id_fourniture )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.taux
                n = n + 1
            })       
            return resultat/n
        }

        const displayTauxPusure = (id_pieceusure, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_pieces.filter(c => c["id_pieceusure"] === id_pieceusure )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.taux
                n = n + 1
            })       
            return resultat/n
        }

        const displayMaterieaux= (id) => {
            let resultat = ""
            resultat =  fournitures.filter(c => c["id"] === id)[0].designation           
            return resultat
        } 

        const displayFournitureCode= (id) => {
            let resultat = ""
            resultat =  fournitures.filter(c => c["id"] === id)[0].code           
            return resultat
        } 

        const displayDescription = (id) => {
            let resultat = ""
            resultat = personnels.filter(c => c["id"] === id)[0].description           
            return resultat
        }

        const displayCategorie = (id) => {
            let resultat = ""
            resultat = personnels.filter(c => c["id"] === id)[0].categorie           
            return resultat
        }

        const displayName = (id) => {
            let resultat = ""
            resultat = personnels.filter(c => c["id"] === id)[0].name           
            return resultat
        }

        const displayGM = (id) => {
            let resultat = ""
            resultat = materiels.filter(c => c["id"] === id)[0].gm           
            return resultat
        }

        const displayType  = (id) => {
            let resultat = ""
            resultat = materiels.filter(c => c["id"] === id)[0].type           
            return resultat
        }

        const displaychassis  = (id) => {
            let resultat = ""
            resultat = materiels.filter(c => c["id"] === id)[0].chassis           
            return resultat
        }

        const displayTaux  = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.taux
                n = n + 1
            })       
            return resultat/n
        }

        const displayHJ = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.hj
                n = n + 1
            })       
            return resultat/n
        }

        const displayPusure = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.pusure
                n = n + 1
            })       
            return resultat/n
        }

        const displaycar = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.carburant
                n = n + 1
            })       
            return resultat/n
        }

        const displayPrix = (id_materiel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_materiels.filter(c => c["id_materiel"] === id_materiel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.prix
                n = n + 1
            })       
            return resultat/n
        }

        const displayMcar = () => {
            let resultat = 0
            let item = []
            let items= []
            rapport_materiel().map((answer) => {                
                let n = 0               
                item = rapport_materiels.filter(c => c["id_materiel"] === answer.id_materiel )
                items =  item.filter(c => c["id_rapport"] === answer.id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + answer.carburant
                    n = n + 1
                }) 
                resultat = resultat/n
            })                
            return resultat
        }

        const displayMlocation = () => {
            let resultat = 0
            let item = []
            let items= []            
            rapport_materiel().map((answer) => { 
                let n = 0               
                item = rapport_materiels.filter(c => c["id_materiel"] === answer.id_materiel )
                items =  item.filter(c => c["id_rapport"] === answer.id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.taux * answer.hj)
                    n = n + 1                    
                }) 
                resultat = resultat/n
            })                
            return resultat
        }

        const displayMpusure = () => {
            let resultat = 0
            let item = []
            let items= []            
            rapport_piece().map((answer) => {  
                let n = 0   
                item = rapport_pieces.filter(c => c["id_pieceusure"] === answer.id_pieceusure )
                items =  item.filter(c => c["id_rapport"] === answer.id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.quantite * answer.taux)
                    n = n + 1                    
                }) 
                resultat = resultat/n
            })                
            return resultat
        }

        const displayMcarbu = () => {
            let resultat = 0
            let item = []
            let items= []
            rapport_materiel().map((answer) => { 
                let n = 0              
                item = rapport_materiels.filter(c => c["id_materiel"] === answer.id_materiel )
                items =  item.filter(c => c["id_rapport"] === answer.id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.carburant * answer.prix)
                    n = n + 1                    
                }) 
                resultat = resultat/n
            })                
            return resultat
        }

        const displayTauxPersonnel = (id_personnel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_personnels.filter(c => c["id_personnel"] === id_personnel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.taux
                n = n + 1
            })       
            return resultat/n
        }

        const displayHeurePersonnel = (id_personnel, id_rapport) => {
            let resultat = 0
            let item = []
            let items= []
            item = rapport_personnels.filter(c => c["id_personnel"] === id_personnel )
            items =  item.filter(c => c["id_rapport"] === id_rapport )
            let n = 0
            items.map((answer) => {                 
                resultat = resultat + answer.heures
                n = n + 1
            })       
            return resultat/n
        }

        const displayMPersonnel = () => {
            let resultat = 0
            let item = []
            let items= []            
            rapport_personnel().map((answer) => {  
                let n = 0   
                item = rapport_personnels.filter(c => c["id_personnel"] === answer.id_personnel )
                items =  item.filter(c => c["id_rapport"] === answer.id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.heures * answer.taux)
                    n = n + 1                    
                }) 
                resultat = resultat/n
            })                
            return resultat
        }

        const displayMFourniture  = () => {
            let resultat = 0
            let item = []
            let items= []            
            rapport_fourniture().map((answer) => {  
                let n = 0   
                item = rapport_fournitures.filter(c => c["id_fourniture"] === answer.id_fourniture )
                items =  item.filter(c => c["id_rapport"] === answer.id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.quantite * answer.taux)
                    n = n + 1                    
                }) 
                resultat = resultat/n
            })                
            return resultat
        }

        const displayMTraitante = () => {
            let resultat = 0
            let item = []
            let items= []            
            rapport_traitant().map((answer) => {  
                let n = 0   
                item = rapport_traitants.filter(c => c["id_traitant"] === answer.id_traitant )
                items =  item.filter(c => c["id_rapport"] === answer.id_rapport )
                items.map((answer) => {                 
                    resultat = resultat + (answer.quantite * answer.taux)
                    n = n + 1                    
                }) 
                resultat = resultat/n
            })                
            return resultat
        }

        const displayMCaisse = () => {
            let resultat = 0
            rapport_caisse().map((answer) => {
                resultat = resultat + answer.somme
            })
            return resultat
        }  

        const Tdepense = () => {
            return (displayMlocation() + displayMpusure() + displayMcarbu() + displayMPersonnel() + displayMFourniture() + displayMTraitante() + displayMCaisse())
        }  

        const PrixRevient  = () => {
            return (Tdepense()/displayQuantiteCumule()).toFixed(2)
        }

        return ( 
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2 }} container spacing={6} >
                    <Grid item xs={12} sm={6}  md={6}>                        
                        <Typography sx={{color:'#46505b', fontSize:28 }}>{pays}, {localite}</Typography>

                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"black", fontWeight: 400, fontSize:24}}
                        >
                            {name.substring(0, 60)}
                        </Typography>                                              
                    </Grid>  

                    <Grid item xs={12} sm={6} md={2.5}>
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid> 

                    <Grid item xs={12} sm={6} md={3.5}>
                        <ProjetPeriode startDate={start}  endDate={end} onChange={this.Datechange}/>
                    </Grid>

                    <Grid item xs={12} md={12}><Typography color="inherit" sx={{color:"black", fontSize:20}}>Cumul des dépenses au prix</Typography></Grid>

                    <Grid item xs={12} md={2} sx={{mt:'-30px'}}>                        
                        <FormControl sx={{ m: 1 }} style={{ width: '100%', marginTop:'-1px', marginLeft:'-1px' }}>
                            <div className="card" style={{ width: '100%'}} >
                                <InputLabel id="demo-simple-select-helper-label">N° prix</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={id_bordereau}
                                    label="N° prix"
                                    name="id_bordereau"
                                    style={{ width: '100%' }}
                                    onChange={this.handleChange}
                                    sx={{bgcolor:'#ffffff'}}
                                >        
                                    {bordereau.map((answer) => { 
                                        return  <MenuItem value={answer.id}>{answer.n_prix}  {answer.designation}</MenuItem>
                                    })}       
                                </Select>
                            </div>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={8} sx={{mt:'-30px'}}> 
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Désignation"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={designation || ''}
                                sx={{bgcolor:'#ffffff'}}
                            />    
                        </div>                        
                    </Grid>

                    <Grid item xs={12} md={2} sx={{mt:'-30px'}}> 
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Quantité cumulée"
                                style={{ width: '100%' }}
                                type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                value={displayQuantiteCumule().toLocaleString() || ''}
                                sx={{bgcolor:'#ffffff'}}
                            />    
                        </div>                        
                    </Grid>

                    <Grid item xs={12} sm={6} md={12} sx={{mt:'-30px'}} > 
                        <TabContext value={valuetab} >
                            <Box sx={{  borderColor: 'divider' }}>
                                <TabList onChange={this.handleChangetab} aria-label="lab API tabs example">
                                    <Tab label="Matériel" value="1" />
                                    <Tab label="Pièce d'usure" value="2" />
                                    <Tab label="Personnel" value="3" />
                                    <Tab label="Fourniture" value="4" />
                                    <Tab label="S-traitant" value="5" />
                                    <Tab label="Caisse" value="6" />
                                </TabList>
                            </Box>

                            <TabPanel value="1" sx={{p:0, pt:2}}>
                                <div className="card" style={{ height: 350, width: '100%'}} >                               
                                    <DataGrid
                                        rows={rapport_materiel()}
                                        columns={colomnMateriel}
                                        sx={{
                                            '.MuiDataGrid-columnSeparator': {
                                                //display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                //border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}
                                    />
                                </div>
                            </TabPanel>

                            <TabPanel value="2" sx={{p:0, pt:2}}>
                                <div className="card" style={{ height: 350, width: '100%'}} >                               
                                    <DataGrid
                                        rows={rapport_piece()}
                                        columns={columnsPiece}
                                        sx={{
                                            '.MuiDataGrid-columnSeparator': {
                                                //display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                //border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}
                                    />
                                </div>
                            </TabPanel>

                            <TabPanel value="3" sx={{p:0, pt:2}}>
                                <div className="card" style={{ height: 350, width: '100%'}} >                               
                                    <DataGrid
                                        rows={rapport_personnel()}
                                        columns={colomnPersonnel}
                                        sx={{
                                            '.MuiDataGrid-columnSeparator': {
                                                //display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                //border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}
                                    />
                                </div>
                            </TabPanel>

                            <TabPanel value="4" sx={{p:0, pt:2}}>
                                <div className="card" style={{ height: 350, width: '100%'}} >                               
                                    <DataGrid
                                        rows={rapport_fourniture()}
                                        columns={columnsFourniture}
                                        sx={{
                                            '.MuiDataGrid-columnSeparator': {
                                                //display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                //border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}
                                    />
                                </div>
                            </TabPanel>

                            <TabPanel value="5" sx={{p:0, pt:2}}>
                                <div className="card" style={{ height: 350, width: '100%'}} >                               
                                    <DataGrid
                                        rows={rapport_traitant()}
                                        columns={columnsTraitant}
                                        sx={{
                                            '.MuiDataGrid-columnSeparator': {
                                                //display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                //border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}
                                    />
                                </div>
                            </TabPanel>

                            <TabPanel value="6" sx={{p:0, pt:2}}>
                                <div className="card" style={{ height: 350, width: '100%'}} >                               
                                    <DataGrid
                                        rows={rapport_caisse()}
                                        columns={columnsCaisse}
                                        sx={{
                                            '.MuiDataGrid-columnSeparator': {
                                                //display: 'none',
                                                },
                                                '&.MuiDataGrid-root': {
                                                //border: 'none',
                                                },
                                            '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                color:'black',
                                                fontWeight: 600,
                                                fontSize:18
                                            }
                                        }}
                                    />
                                </div>
                            </TabPanel>
                        </TabContext>                
                    </Grid>

                    <Grid item xs={12} md={12} sx={{mt:'-30px'}}><Typography color="inherit" sx={{color:"black", fontSize:20}}>Déboursé par poste</Typography></Grid>

                    <Grid item xs={12} md={12/8} sx={{mt:'-30px'}}>                                    
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Total Carburant(l)"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={displayMcar().toLocaleString() || ''}
                                sx={{bgcolor:'#ffffff'}}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12/8} sx={{mt:'-30px'}}>                                    
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Montant Location"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={displayMlocation().toLocaleString() || ''}
                                sx={{bgcolor:'#ffffff'}}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12/8} sx={{mt:'-30px'}}>                                    
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Montant Pièce d'usure"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={displayMpusure().toLocaleString() || ''}
                                sx={{bgcolor:'#ffffff'}}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12/8} sx={{mt:'-30px'}}>                                    
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Montant carburant"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={displayMcarbu().toLocaleString() || ''}
                                sx={{bgcolor:'#ffffff'}}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12/8} sx={{mt:'-30px'}}>                                    
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Montant personnel"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={displayMPersonnel().toLocaleString() || ''}
                                sx={{bgcolor:'#ffffff'}}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12/8} sx={{mt:'-30px'}}>                                    
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Montant matériaux"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={displayMFourniture().toLocaleString() || ''}
                                sx={{bgcolor:'#ffffff'}}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12/8} sx={{mt:'-30px'}}>                                    
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Montant sous-traitant"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={displayMTraitante().toLocaleString() || ''}
                                sx={{bgcolor:'#ffffff'}}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12/8} sx={{mt:'-30px'}}>                                    
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Montant caisse"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={displayMCaisse().toLocaleString() || ''}
                                sx={{bgcolor:'#ffffff'}}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={4.5} >
                        <Card elevation={6}  sx={{p:4, backgroundColor:'#ffffff', borderRadius:3}}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={4} >
                                <Grid item xs={12} md={4}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Total dépenses"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={Tdepense().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Quantité cumulée"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayQuantiteCumule().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Prix de revients"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={PrixRevient().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Prix de vente"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={(displayQuantiteCumule() * pUCredit).toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Quantité cumulée"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={displayQuantiteCumule().toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={4}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Prix unitaire"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            value={pUCredit.toLocaleString() || ''}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12}>                                    
                                    <div className="card" style={{ width: '100%'}} >
                                        <TextField
                                            id="outlined-required"
                                            label="Marge"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            sx={{bgcolor:'#e9eff6'}}
                                            value={((displayQuantiteCumule() * pUCredit) - Tdepense()).toLocaleString() + ' soit un taux de ' + (( (displayQuantiteCumule() * pUCredit) - Tdepense())/Tdepense()).toFixed(2) + ' %'|| ''}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={7.5} >
                        <Card elevation={6}  sx={{height:296, p:4, backgroundColor:'#ffffff', borderRadius:3}}>
                            <TabContext value={valuetab2} >
                                <Box sx={{  borderColor: 'divider' }}>
                                    <TabList onChange={this.handleChangetab2} aria-label="lab API tabs example">
                                        <Tab label="Décomposition balance chantier" value="1" />
                                        <Tab label="Décomposition balance budget" value="2" />
                                        <Tab label="Controle budgetaire" value="3" />
                                    </TabList>
                                </Box>

                                <TabPanel value="1" sx={{p:0, pt:2}}>
                                    <Grid sx={{ flexGrow: 1, mt:1 }} container spacing={2}> 
                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Matériel"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={((displayMlocation()/displayQuantiteCumule()).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Carburant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={((displayMcarbu()/displayQuantiteCumule()).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="P. usure"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                sx={{bgcolor:'#e9eff6'}}
                                                value={((displayMpusure()/displayQuantiteCumule()).toFixed(2)).toLocaleString() || ''}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Personnel"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={((displayMPersonnel()/displayQuantiteCumule()).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Matériaux"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={((displayMFourniture()/displayQuantiteCumule()).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Sous-traitant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={((displayMTraitante()/displayQuantiteCumule()).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Caisse"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={((displayMCaisse()/displayQuantiteCumule()).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Prix"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={PrixRevient().toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value="2" sx={{p:0, pt:2}}>
                                    <Grid sx={{ flexGrow: 1, mt:1 }} container spacing={2}> 
                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Matériel"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={(((displayMlocation()/displayQuantiteCumule()) / pUCredit).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Carburant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={(((displayMcarbu()/displayQuantiteCumule()) / pUCredit).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="P. usure"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                sx={{bgcolor:'#e9eff6'}}
                                                value={(((displayMpusure()/displayQuantiteCumule()) / pUCredit).toFixed(2)).toLocaleString() || ''}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Personnel"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={(((displayMPersonnel()/displayQuantiteCumule()) / pUCredit).toFixed(2)).toLocaleString() || ''}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Matériaux"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={(((displayMFourniture()/displayQuantiteCumule()) / pUCredit).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Sous-traitant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={(((displayMTraitante()/displayQuantiteCumule()) / pUCredit).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Caisse"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={((( displayMCaisse()/displayQuantiteCumule()) / pUCredit).toFixed(2)).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Prix"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={pUCredit.toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>

                                <TabPanel value="3" sx={{p:0, pt:2}}>
                                    <Grid sx={{ flexGrow: 1, mt:1 }} container spacing={2}> 
                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Matériel"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={"Div/0!"}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Carburant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={"Div/0!"}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                        <TextField
                                            id="outlined-required"
                                            label="P. usure"
                                            variant="outlined"
                                            style={{ width: '100%' }}
                                            sx={{bgcolor:'#e9eff6'}}
                                            value={(((displayMpusure()/displayQuantiteCumule()) / pUCredit).toFixed(2)).toLocaleString() || ''}
                                        />
                                    </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Personnel"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={"Div/0!"}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Matériaux"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={"Div/0!"}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Sous-traitant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={"Div/0!"}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Caisse"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={"Div/0!"}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12/8}>
                                            <TextField
                                                id="outlined-required"
                                                label="Prix"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={(PrixRevient()/pUCredit).toLocaleString() || ''}
                                                sx={{bgcolor:'#e9eff6'}}
                                            />
                                        </Grid>
                                    </Grid>
                                </TabPanel>
                            </TabContext>
                        </Card>                        
                    </Grid>
                </Grid>            
            </React.Fragment>
        )
    }
}