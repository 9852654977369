import React, { Component } from "react";
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import axios from "axios";
import ProjetMenu  from "../../Layouts/ProjetMenu";
import ProjetPeriode from "../../Layouts/ProjetPeriode"
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown'; 

export default class Rdprix extends Component {

    constructor(props) {
        super(props)
        this.state = {
            bordereaux: [],
            projects: [],
            rapports: [],
            rapport_materiels:[],
            materiels: [],
            rapport_personnels: [],
            personnels: [],
            rapport_fournitures:[], 
            fournitures: [],
            rapport_traitants: [],
            traitants: [],
            rapport_caisses:[],
            rapport_pieces: [],

            projetId: this.props.projetId,
            id_bordereau: null,
            id_fourniture: null,

            start: this.props.startDate,
            end: this.props.endDate,

            open: false,
            anchorEl: null,
        }
        this.handleChange = this.handleChange.bind(this)
        this.idchange = this.idchange.bind(this)
        this.Datechange = this.Datechange.bind(this)
        this.handleClick= this.handleClick.bind(this)
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            this.setState({ 
                rapports: response.data.rapports,
                projects: response.data.projects,
                bordereaux: response.data.bordereaux,
                rapport_materiels: response.data.rapport_materiels,
                materiels: response.data.materiels,
                rapport_fournitures: response.data.rapport_fournitures,
                rapport_personnels: response.data.rapport_personnels,
                personnels: response.data.personnels,
                fournitures: response.data.fournitures,
                rapport_traitants: response.data.rapport_traitants,
                traitants: response.data.traitants,
                rapport_caisses: response.data.rapport_caisses,
                rapport_pieces: response.data.rapport_pieces
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    idchange(id){  
        let projet = this.state.projects.filter(c => c["id"] === id)[0]

        this.setState(
            { 
                projetId: id,
                start: new Date(projet.start *1000),
                end: new Date(projet.close *1000),
            },
            () => this.props.onChange(id)
        )
    }

    Datechange(startDate, endDate){
        this.setState({start: startDate, end: endDate})
    }

    handleClick(event){
        this.setState({anchorEl: event.currentTarget});
    }

    render() {

        const {rapport_caisses, projetId, anchorEl, rapport_pieces, rapport_traitants, rapport_fournitures, rapport_personnels, rapport_materiels, rapports,projects, start, end, id_bordereau, bordereaux, open} = this.state

        const id_project = projetId
        
        const bordereau = bordereaux.filter(c => c["id_projet"] === id_project)

        let name = ""
        let pays= ""
        let curency = ""
        let localite = ""
        if(projects.filter(c => c["id"] === id_project)[0] !== undefined){
            name = projects.filter(c => c["id"] === id_project)[0].name
            pays= projects.filter(c => c["id"] === id_project)[0].country
            curency = projects.filter(c => c["id"] === id_project)[0].currency
            localite = projects.filter(c => c["id"] === id_project)[0].location
        }

        let designation = ""
        let unite = ""
        let prix = 0
        if(bordereaux.filter(c => c["id"] === id_bordereau)[0] !== undefined){
            designation = bordereaux.filter(c => c["id"] === id_bordereau)[0].designation
            unite = bordereaux.filter(c => c["id"] === id_bordereau)[0].unite
            prix = bordereaux.filter(c => c["id"] === id_bordereau)[0].prix
        }

        const a = rapports.filter(c => c["id_projet"] === id_project)
        const d = a.filter(c => c["id_bordereau"] === id_bordereau)  
        const b = d.filter(c => c["fait"] >= Math.floor(new Date(start).getTime() / 1000))
        const c = b.filter(c => c["fait"] <= Math.floor(new Date(end).getTime() / 1000) + 86400)
        let rapport = c

        const columns = [
            { 
                field: 'fait', 
                headerName: 'Date',
                flex: 1/12,
                renderCell: (cellValues) => {return  displayCreation(cellValues.row.fait)}
            },
            { 
                field: 'quantite', 
                headerName: 'Quantité exécutée',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return  cellValues.row.quantite.toLocaleString()}
            },
            { 
                field: 'prix', 
                headerName: 'Prix unitaire',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => { return displayPrixunitaire(cellValues.row.id_bordereau).toLocaleString() }
            },
            { 
                field: 'materiel', 
                headerName: 'Matériel',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return  displayMpu(cellValues.row.id).toLocaleString()}
            },
            { 
                field: 'carburant', 
                headerName: 'Carburant',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return  displayMcarb(cellValues.row.id).toLocaleString()}
            },
            { 
                field: 'pusure', 
                headerName: 'P. usure',
                flex: 1/15,
                type: 'number',
                renderCell: (cellValues) => {return displayMpusure(cellValues.row.id).toLocaleString()}
            },
            { 
                field: 'personnel', 
                headerName: 'Personnel',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return  displayPersonnel(cellValues.row.id).toLocaleString()}
            },
            { 
                field: 'fourniture', 
                headerName: 'Matériaux',
                flex: 1/12,
                renderCell: (cellValues) => {return  displayMateriaux(cellValues.row.id).toLocaleString()}
            },
            { 
                field: 'traitant', 
                headerName: 'S-traitant',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return  displayTraitant(cellValues.row.id).toLocaleString()}
            },
            { 
                field: 'caisse', 
                headerName: 'Caisse',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return  displayCaisse(cellValues.row.id).toLocaleString()}
            },
            { 
                field: 'treef', 
                headerName: 'T. dépenses',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayMpusure(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id)).toLocaleString()}
            },
            { 
                field: 'tbudget', 
                headerName: 'Prix de vente',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {return (cellValues.row.quantite * displayQté(cellValues.row.id_bordereau)).toLocaleString()}
            },
            { 
                field: 'tpercentt', 
                headerName: '',
                flex: 1/100,
                type: 'number',
                renderCell: (cellValues) => {
                    if(((cellValues.row.quantite * displayQté(cellValues.row.id_bordereau)) - (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayMpusure(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id))) >=0){
                        return <span style={{color:'green'}}><TrendingUpIcon sx={{fontSize:26}} /></span>
                    }
                    else{
                        return <span style={{color:'red'}}><TrendingDownIcon sx={{fontSize:26}} /></span>
                    }
                }
            },
            { 
                field: 'tpercent', 
                headerName: 'T. Marge',
                flex: 1/12,
                type: 'number',
                renderCell: (cellValues) => {
                    if(((cellValues.row.quantite * displayQté(cellValues.row.id_bordereau)) - (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayMpusure(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id))) >=0){
                        return <span style={{color:'green'}}>{((cellValues.row.quantite * displayQté(cellValues.row.id_bordereau)) - (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayMpusure(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id)))}</span>
                    }
                    else{
                        return <span style={{color:'red'}}>{((cellValues.row.quantite * displayQté(cellValues.row.id_bordereau)) - (displayCaisse(cellValues.row.id) + displayMpu(cellValues.row.id) + displayMcarb(cellValues.row.id) + displayMpusure(cellValues.row.id) + displayPersonnel(cellValues.row.id) +  displayMateriaux(cellValues.row.id) + displayTraitant(cellValues.row.id)))}</span>
                    }
                }
            }
        ]

        const displayPrixunitaire = (id_bordereau) => {
            let resultat = 0
            let item = []
            item = bordereaux.filter(c => c["id"] === id_bordereau )[0].prix
            resultat = item
            return resultat
        }

        const displayQté = (id_bordereau) => {
            let resultat = 0
            let item = []
            item = bordereaux.filter(c => c["id"] === id_bordereau )[0]
            resultat = item.prix
            return resultat
        }

        const displayCaisse = (id) => {
            let resultat = 0
            let items = []
            items = rapport_caisses.filter(c => c["id_rapport"] === id )     
            items.map((answer) => {      
                resultat = resultat +  answer.somme
            })   
            return resultat
        }

        const displayTraitant = (id) => {
            let resultat = 0
            let items = []
            items = rapport_traitants.filter(c => c["id_rapport"] === id )     
            items.map((answer) => {      
                resultat = resultat + (answer.quantite * answer.taux)
            })  
            return resultat
        }

        const displayMateriaux = (id) => {
            let resultat = 0
            let items = []
            items = rapport_fournitures.filter(c => c["id_rapport"] === id )     
            items.map((answer) => {      
                resultat = resultat + (answer.quantite * answer.taux)
            })
            return resultat
        }

        const displayPersonnel = (id) => {
            let resultat = 0
            let items = []
            items = rapport_personnels.filter(c => c["id_rapport"] === id )     
            items.map((answer) => {      
                resultat = resultat + (answer.heures * answer.taux)
            })  
            return resultat
        }

        const displayMcarb = (id) => {
            let resultat = 0
            let items = []
            items = rapport_materiels.filter(c => c["id_rapport"] === id )     
            items.map((answer) => {      
                resultat = resultat + (answer.prix * answer.carburant)
            })
            return resultat
        }

        const displayMpusure = (id) => {
            let resultat = 0
            let items = []
            items = rapport_pieces.filter(c => c["id_rapport"] === id )     
            items.map((answer) => {      
                resultat = resultat + (answer.quantite * answer.taux)
            })
            return resultat
        }

        const displayMpu = (id) => {
            let resultat = 0
            let items = []
            items = rapport_materiels.filter(c => c["id_rapport"] === id )     
            items.map((answer) => {      
                resultat = resultat + (answer.hj * answer.taux)
            })
            return resultat
        }

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        const TotalQte = () => {
            let resultat = 0
            rapport.map((answer) => {
                resultat = resultat + answer.quantite
            })
            return resultat
        }

        const TotalMateriel = () => {
            let resultat = 0
            rapport.map((answer) => {                
                resultat = resultat + displayMpu(answer.id)
            })
            return resultat
        }

        const TotalMCarb = () => {
            let resultat = 0
            rapport.map((answer) => {                
                resultat = resultat + displayMcarb(answer.id)
            })
            return resultat
        }

        const TotalMPUsure = () => {
            let resultat = 0
            rapport.map((answer) => {                
                resultat = resultat + displayMpusure(answer.id)
            })
            return resultat
        }

        const TotalPersonnel = () => {
            let resultat = 0
            rapport.map((answer) => {                
                resultat = resultat + displayPersonnel(answer.id)
            })
            return resultat
        }

        const TotalMateriaux = () => {
            let resultat = 0
            rapport.map((answer) => {                
                resultat = resultat + displayMateriaux(answer.id)
            })
            return resultat
        }

        const TotalTraitant = () => {
            let resultat = 0
            rapport.map((answer) => {                
                resultat = resultat + displayTraitant(answer.id)
            })
            return resultat
        }

        const TotalCaisse = () => {
            let resultat = 0
            rapport.map((answer) => {                
                resultat = resultat + displayCaisse(answer.id)
            })
            return resultat
        }

        const TotalRef = () => {
            let resultat = 0
            rapport.map((answer) => {                
                resultat = resultat +  displayCaisse(answer.id) + displayMpu(answer.id) + displayMcarb(answer.id) + displayMpusure(answer.id) + displayPersonnel(answer.id) + displayMateriaux(answer.id) + displayTraitant(answer.id)
            })
            return resultat
        }

        const TotalBud  = () => {
            let resultat = 0
            rapport.map((answer) => {                
                resultat = resultat + (answer.quantite *  displayQté(answer.id_bordereau))
            })
            return resultat
        }

        const displayNumPrix = (id) => {
            let name = ""
            if(bordereau.filter(c => c["id"] === id_bordereau)[0] !== undefined){
                name =  bordereau.filter(c => c["id"] === id_bordereau)[0].n_prix + " | " + bordereau.filter(c => c["id"] === id_bordereau)[0].designation
            }
            return name
        }

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2 }} container spacing={6} >
                    <Grid item xs={12} sm={6}  md={5}>
                        <Typography sx={{color:'#46505b', fontSize:28 }}>{pays}, {localite}</Typography>

                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"black", fontWeight: 400, fontSize:24}}
                        >
                            {name.substring(0, 60)}
                        </Typography>                                              
                    </Grid>  

                    <Grid item xs={12} sm={6} md={2}>
                        <ProjetMenu projetId={id_project} onChange={this.idchange} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <ProjetPeriode startDate={start}  endDate={end} onChange={this.Datechange}/>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                        <Card elevation={1}  sx={{height:70, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                            <Box sx={{display: 'flex', flexDirection:'column', alignItems: 'left', }} >
                                <Typography sx={{color:'#46505b', pl:1}}>N° prix</Typography>
                                <Typography sx={{color:'gray', pl:1, fontSize:14}}>{displayNumPrix(id_bordereau).substring(0, 20)}</Typography>
                            </Box>

                            <Box sx={{ flexGrow: 1 }} />

                            <IconButton onClick={this.handleClick} >
                                <ExpandMoreIcon />
                            </IconButton>

                            <Menu
                                elevation={12}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => this.setState({anchorEl: null}) }
                                PaperProps={{
                                    sx: {
                                        //width: 300,
                                        //backgroundColor:'gray',                                    
                                    }
                                }}
                            >
                                {bordereau.map((answer) => { 
                                    return  (
                                        <MenuItem 
                                            disableRipple 
                                            onClick={() => this.setState({id_bordereau: answer.id, anchorEl: null })} 
                                            sx={id_bordereau === answer.id ? {color:'#2f4cdd'} : null}
                                        >
                                            {answer.n_prix} #{answer.designation}
                                        </MenuItem>)
                                })}    
                            </Menu>

                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <div className="card" style={{ height: 470, width: '100%',borderRadius:16 }}>
                            <DataGrid
                                rows={rapport}
                                columns={columns}
                                elevation={10}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius:4,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}                                    
                            />     
                        </div>                        
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Grid sx={{ flexGrow: 1, mb:2 }} container spacing={2} > 
                            <Grid item xs={12} md={10}>
                                <Grid sx={{ flexGrow: 1, mb:2 }} container spacing={2} > 
                                    <Grid item xs={12} md={12/5}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="Total Qté"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={TotalQte()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/5}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="Prix unitaire"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={prix.toLocaleString() || ''}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/5}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. matériel"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={TotalMateriel().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/5}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. carburant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={TotalMCarb().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/5}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. P. usure"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={TotalMPUsure().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/5}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. personnel"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={TotalPersonnel().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/5}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. matériaux"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={TotalMateriaux().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/5}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. sous-traitant"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={TotalTraitant().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/5}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. caisse"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={TotalCaisse().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12/5}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Dépenses"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={TotalRef().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Grid sx={{ flexGrow: 1, mb:2 }} container spacing={2} > 
                                    <Grid item xs={12} md={12}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Prix de vente"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                value={TotalBud().toLocaleString()}
                                                sx={{bgcolor:'#ffffff'}}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <div className="card" style={{ width: '100%'}} >
                                            <TextField
                                                id="outlined-required"
                                                label="T. Marge"
                                                variant="outlined"
                                                style={{ width: '100%' }}
                                                sx={(TotalBud() - TotalRef()) > 0 ? { input:{ color: 'green', bgcolor:'#ffffff' } } : { input:{ color: 'red', bgcolor:'#ffffff' } }}
                                                value={(TotalBud() - TotalRef()).toLocaleString()}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>     
                        </Grid>
                    </Grid>
                </Grid>             
            </React.Fragment>
        )
    } 
} 