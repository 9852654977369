import React, { Component } from "react"; 
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import DatePicker from 'react-date-picker';
import MuiAlert from '@mui/material/Alert';
import ReactToPrint from 'react-to-print';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PrintIcon from '@mui/icons-material/Print';
import Slide from '@mui/material/Slide';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'black',
    maxWidth: 400,
    fontSize: 14,
    border: '1px solid #dadde9',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default class Carburants extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rapport_materiels: [],
            materiels:[],
            rapports:[],
            bordereaux: [],
            bordereauxToSelect: [],

            id_project: null,
            id_materiel: null,

            fait: new Date()
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.setState({ id_project: JSON.parse(localStorage.getItem("ID")) });
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/rapport', config)
        .then(response => {
            this.setState({ 
                rapport_materiels: response.data.rapport_materiels,
                bordereaux: response.data.bordereaux,
                rapports: response.data.rapports,
                materiels: response.data.materiels
            });
        }).catch(error => {
            console.log("error", error)
        });
        this.getProjectId()
    }

    getProjectId = () => {
        this.interval = setInterval(() => {
            this.setState({ id_project: JSON.parse(localStorage.getItem("ID")) })
            if (window.location.pathname !== "/carburants") {
                this.stopCountDown();
            }
        }, 1000)
    }

    stopCountDown = () => {
        clearInterval(this.interval); 
    }

    handleChangeDateStart(date){ this.setState({fait: date})}

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    render() {

        const {id_project, fait, rapport_materiels, materiels, rapports, bordereaux, id_materiel} = this.state

        const a = rapport_materiels.filter(c => c["id_projet"] === id_project)
        const b = a.filter(c => c["fait"] >= Math.floor(new Date(fait).getTime() / 1000))
        const c = b.filter(c => c["fait"] <= Math.floor(new Date(fait).getTime() / 1000) + 86400)
        let rapport_materiel =[]
        if(id_materiel == null){
            rapport_materiel = c
        }else{
            rapport_materiel = c.filter(c => c["id_materiel"] ===id_materiel)
        }

        const materiel = materiels.filter(c => c["id_projet"] === id_project)


        

        const displayNprix = (id) => {
            if(rapports.filter(c => c["id"] === id)[0] === undefined){
                return "Borderau suprimé"
            }else{
                const id_bordereau  = rapports.filter(c => c["id"] === id)[0].id_bordereau
                if(bordereaux.filter(c => c["id"] === id_bordereau)[0] === undefined){
                    return "Borderau suprimé"
                }
                else{
                    return bordereaux.filter(c => c["id"] === id_bordereau)[0].n_prix
                }                
            }  
        }

        const displayGM = (id) => {
            let resultat = ""
            resultat = materiels.filter(c => c["id"] === id)[0].gm           
            return resultat
        }
        
        const displayType  = (id) => {
            let resultat = ""
            resultat = materiels.filter(c => c["id"] === id)[0].type           
            return resultat
        }

        const displayCarburant = (id) => {
            let resultat = 0
            rapport_materiel.map((answer) => {
                resultat = resultat + answer.carburant
            })
            return resultat
        }

        const columns = [
            { 
                field: 'id_rapport', 
                headerName: 'N° prix',
                flex: 1/4,
                renderCell: (cellValues) => {return  displayNprix(cellValues.row.id_rapport)}
            },
            { 
                field: 'gm', 
                headerName: 'N° GM',
                flex: 1/4,
                renderCell: (cellValues) => {return  displayGM(cellValues.row.id_materiel)}
            },
            { 
                field: 'type', 
                headerName: 'Type',
                flex: 2/4,
                renderCell: (cellValues) => {return  displayType(cellValues.row.id_materiel)}
            },
            { 
                field: 'carburant', 
                headerName: 'Carburant',
                flex: 1/4,
            }
        ]

        const displayCreation = (timestamp) => {
            var date = new Date(timestamp * 1000);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        }

        return ( 
            <div>
                {id_project === null ? 
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <Typography color="inherit">Rapports de chantier</Typography>
                                Les rapports d'activités de chaque échelon sont rédigés chaque jour sur un forulaire conformement au prix du bordereau. 
                                Un échelon peut produire plusieurs rapports par jour selon que les opérations éffectuées concernant plusieurs prix du bordereau. 
                                Le rapport de chantier comportent les informations de date, de prix, de quantite executées, des matériels utilisés, du personnel, 
                                des matériaux etc .....
                            </React.Fragment>
                        }
                    >
                        <Button variant="outlined" startIcon={<HelpOutlineIcon />} style={{marginTop:"-150px", color:"white", backgroundColor:"black"}}>
                            Aide
                        </Button>
                    </HtmlTooltip>
                : 
                    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                        <Grid item xs={12} md={2} style={{marginTop:"-80px"}}>
                            <div className="input-group input-group-outline mb-4">  
                                <DatePicker 
                                    selected={fait}
                                    onChange={(date) => this.handleChangeDateStart(date)}
                                    value={fait}
                                    id="demo-simple-select"
                                    maxDate={new Date()}
                                    style={{height:50,  marginTop:"-20px"}}
                                />
                            </div>
                        </Grid>  
                        <Grid item xs={12} md={2} style={{marginTop:"-80px"}}>
                            <FormControl style={{ width: '100%'}}>
                                <InputLabel id="demo-simple-select-helper-label">N° GM</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={id_materiel || ''}
                                    name="id_materiel"
                                    style={{ width: '100%' }}
                                    onChange={this.handleChange}
                                >   
                                    <MenuItem value={null}>Null</MenuItem>     
                                    {materiel.map((answer) => { 
                                        return  <MenuItem value={answer.id}>{answer.gm} {answer.type}</MenuItem>
                                    })}  
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={4}> 
                            <ReactToPrint
                                trigger={() => {
                                    return  (<Button variant="contained"  startIcon={<PrintIcon />}  style={{marginTop:"-130px", color:"white", marginLeft:16, backgroundColor:"black"}}>
                                    Imprimer
                                </Button>);
                                }}
                                content={() => this.componentRef}
                            />
                            <HtmlTooltip
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">Rapports de chantier</Typography>
                                        Les rapports d'activités de chaque échelon sont rédigés chaque jour sur un forulaire conformement au prix du bordereau. 
                                        Un échelon peut produire plusieurs rapports par jour selon que les opérations éffectuées concernant plusieurs prix du bordereau. 
                                        Le rapport de chantier comportent les informations de date, de prix, de quantite executées, des matériels utilisés, du personnel, 
                                        des matériaux etc .....
                                    </React.Fragment>
                                }
                            >
                                <Button variant="outlined" startIcon={<HelpOutlineIcon />} style={{marginTop:"-130px", color:"white", marginLeft:16, backgroundColor:"black"}}>
                                    Aide
                                </Button>
                            </HtmlTooltip>
                        </Grid> 
                    </Grid> 
                }

                <Grid item xs={12} md={12}>
                    <div className="card" style={{ height: 350, width: '100%'}} >
                        <DataGrid
                            rows={rapport_materiel}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                        />
                    </div>                    
                </Grid>

                
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12} md={9}>  </Grid>

                    <Grid item xs={12} md={3} align="right" style={{marginTop:18}}>                                    
                        <div className="card" style={{ width: '100%'}} >
                            <TextField
                                id="outlined-required"
                                label="Quantité totale de carburant servie dans une journée"
                                variant="outlined"
                                style={{ width: '100%' }}
                                value={displayCarburant().toLocaleString() || ''}
                            />
                        </div>
                    </Grid>
                </Grid>

                <div  style={{ display: "none" }}>
                    <Grid sx={{ flexGrow: 1 }} container spacing={2} ref={el => (this.componentRef = el)}>
                        <table className="table table-striped table-hover"  >
                            <thead>
                                <tr>
                                    <th scope="col">Date</th>
                                    <th scope="col">N° GM</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Carburant</th>                              
                                </tr>
                            </thead>
                            <tbody>
                                {rapport_materiel.map((answer) => { 
                                    return (
                                        <tr>
                                            <td>{displayCreation(answer.fait)}</td>
                                            <td>{displayGM(answer.id_materiel)}</td>
                                            <td>{displayType(answer.id_materiel)}</td>
                                            <td>{answer.carburant.toLocaleString()}</td>                                   
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    
                        <Grid item xs={12} md={9}>  </Grid>

                        <Grid item xs={12} md={3} align="right" style={{marginTop:18}}>                                    
                            <div className="card" style={{ width: '40%'}} >
                                <TextField
                                    id="outlined-required"
                                    label="Quantité totale de carburant servie dans une journée"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={displayCarburant().toLocaleString() || ''}
                                />
                            </div>
                        </Grid>
                    </Grid> 
                </div>
                
                


                
            </div>
        )
    }
}