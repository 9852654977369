import React, { Component } from "react";
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            //maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 800,
            marginLeft:260
        },
    },
};

export default class Utilisateurs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: null,

            users: [],
            projects: [],
            projectsSelect: [],

            name: "",
            email: "",
            role:"Administrateur",

            isNotDit: false,
            isDid: false,
            open: false,
            openEdit:false,
            loading:true

        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleEditerSubmit = this.handleEditerSubmit.bind(this)
        this.handleChangeAcces = this.handleChangeAcces.bind(this)
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('https://api-gn.projectmanagementsolutions.net/api/users', config)
        .then(response => {    
            console.log("error", response.data.users)
            this.setState({ 
                users: response.data.users,
                projects: response.data.projects,
                loading: false
                
            });
        }).catch(error => {
            console.log("error", error)
        });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeAcces(event){
        const { target: { value },} = event;
        this.setState({ projectsSelect: typeof value === 'string' ? value.split(',') : value });        
    }

    handleSubmit(event) {
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };

        let projet = []

        this.state.projectsSelect.map((elem) => {
            const b = {id: elem}
            projet = projet.concat(b)
        })

        const bodyParameters = {
            name: this.state.name, 
            email: this.state.email, 
            role: this.state.role,
            password: "admin123",
            password_confirmation: "admin123",
            projet: JSON.stringify(projet)
        };

        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/register',
            bodyParameters
        ).then(response => {       
            console.log("return", response.data)     
            this.setState({ isDid: true, open:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ isNotDit: true });
        });
    }

    handleEditerSubmit(event){
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        let projet = []

        this.state.projectsSelect.map((elem) => {
            const b = {id: elem}
            projet = projet.concat(b)
        })

        const bodyParameters = {
            name: this.state.name, 
            email: this.state.email, 
            role: this.state.role,
            projet: JSON.stringify(projet)
        };

        axios.put( 
            'https://api-gn.projectmanagementsolutions.net/api/register/' + this.state.id,
            bodyParameters,
            config
        ).then(response => {                     
            this.setState({ isDid: true, openEdit:false });
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ isNotDit: true });
        });
    }

    edit(id){
        const user = this.state.users.filter(c => c["id"] === id)[0]

        let projet = []
        
        if(user.projet){
            projet = JSON.parse(user.projet)
        }
        
        let projectsSelect = []

        projet.map((elem) => {
            const b = elem.id
            projectsSelect = projectsSelect.concat(b)
        }) 

        this.setState({
            id: id,
            name: user.name,
            email: user.email,
            projectsSelect: projectsSelect,       
            openEdit: true
        }) 
    }

    delete(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/register/' + id,
            config
        ).then(response => {                     
            this.setState({ isDid: true, voir:false });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ isNotDit: true });
        });    

    }

    render() {

        const {loading, projectsSelect, projects, isDid, isNotDit, users, open, name, email, role, openEdit} = this.state

        const columns = [
            { 
                field: 'name', 
                headerName: 'Nom',
                flex: 5/5
            },
            { 
                field: 'email', 
                headerName: 'E-mail',
                flex: 3/5
            },
            { 
                field: 'role', 
                headerName: 'Rôle',
                flex: 3/5,
            },
            { 
                field: 'editer', 
                headerName: '',
                flex: 1/5,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => this.edit(cellValues.row.id)}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#2f4cdd', color:'white'}}}
                        >
                            Modifier
                        </Button>
                    )
                    
                }
            },
            { 
                field: 'supprimer', 
                headerName: '',
                flex: 1/5,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                    
                }
            }
        ]

        const displaythem = (selected) => {
            let resultat = ""
            selected.map((elem) => {
                if(projects.filter(c => c["id"] === elem)[0]){
                    resultat = resultat + projects.filter(c => c["id"] === elem)[0].name + ", "    
                }                
            })

            return resultat
        }

        return (
            <React.Fragment>  
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item xs={12} sm={6} md={2} >
                        <Button 
                            variant="contained" 
                            sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                            fullWidth
                            startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                            onClick={() => this.setState({ open: true })}
                        >
                            Ajouter un utilisateur
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={10} >
                        <Tooltip 
                            title={<React.Fragment>
                                <Typography color="inherit" sx={{fontSize:20}} >Caisse</Typography>
                                <Typography color="inherit" sx={{fontSize:16}} >
                                Le personnel est composé de l'ensemble des personnes employées par le chantier. Il est subdivisé en catégorie, d'où l'on distingue 1ere, 2ème, "ème, 4ème ...
                                HC ... etc. Il s'agira ici de lister les differentes catégories avec leurs caractéristiques.
                                </Typography>
                            </React.Fragment>
                            }
                        >                        
                            <Button 
                                variant="contained" 
                                sx={{bgcolor:'#5d95ed', height:50}}
                            >
                                <AnnouncementOutlinedIcon/>
                            </Button>
                        </Tooltip> 
                    </Grid>

                    <Grid item xs={12} sm={6} md={12} >
                        <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }}>
                            <DataGrid
                                rows={users}
                                columns={columns}
                                onCellEditCommit={(props, event) => this.editTable(props)}
                                loading={loading}
                                elevation={10}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius:4,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={open}
                    onClose={() => this.setState({ open: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Créer un nouveau compte
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nom"
                                            name="name"
                                            style={{ width: '100%' }}
                                            value={name}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="E-mail"
                                            name="email"
                                            style={{ width: '100%' }}
                                            value={email}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid> 

                                <Grid item xs={12} md={6}>
                                    <FormControl  style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Rôle</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={role}
                                                label="role"
                                                name="role"
                                                style={{ width: '100%' }}
                                                onChange={this.handleChange}
                                                sx={{bgcolor:'#e9eff6'}}
                                            >                                        
                                                <MenuItem value="Administrateur">Administrateur</MenuItem>
                                                <MenuItem value="Gestion des matériels">Gestion des matériels</MenuItem>
                                                <MenuItem value="Gestion des personnels">Gestion des personnels</MenuItem>
                                                <MenuItem value="Gestion des matériaux">Gestion des matériaux</MenuItem>
                                                <MenuItem value="Gestion des sous-traitant">Gestion des sous-traitant</MenuItem>
                                                <MenuItem value="Gestion de la caisse">Gestion de la caisse</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl  style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Accès aux projets</InputLabel>

                                        <Select 
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={projectsSelect}
                                            input={<OutlinedInput label="Accès aux projets" />}
                                            renderValue={(selected) => displaythem(selected)}
                                            sx={{bgcolor:'#e9eff6'}}
                                            onChange={this.handleChangeAcces}
                                            MenuProps={MenuProps}
                                        >
                                            {projects.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>
                                                    <Checkbox checked={projectsSelect.indexOf(answer.id) > -1} sx={{color:'#2f4cdd', '&.Mui-checked': {color:'#2f4cdd'}}} />
                                                    <ListItemText primary={answer.name} />
                                                  </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:4,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                                Cliquer pour enregistrer le nouveau compte
                        </Button>
                    </form>

                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={openEdit}
                    onClose={() => this.setState({ openEdit: false })}                    
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier un compte
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ openEdit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box> 

                    <form role="form" className="text-start" onSubmit={this.handleEditerSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nom"
                                            name="name"
                                            style={{ width: '100%' }}
                                            value={name}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <div className="input-group input-group-outline mb-4">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="E-mail"
                                            name="email"
                                            style={{ width: '100%' }}
                                            value={email}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid> 

                                <Grid item xs={12} md={6}>
                                    <FormControl  style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Rôle</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                value={role}
                                                label="role"
                                                name="role"
                                                style={{ width: '100%' }}
                                                onChange={this.handleChange}
                                                sx={{bgcolor:'#e9eff6'}}
                                            >                                        
                                                <MenuItem value="Administrateur">Administrateur</MenuItem>
                                                <MenuItem value="Gestion des matériels">Gestion des matériels</MenuItem>
                                                <MenuItem value="Gestion des personnels">Gestion des personnels</MenuItem>
                                                <MenuItem value="Gestion des matériaux">Gestion des matériaux</MenuItem>
                                                <MenuItem value="Gestion des sous-traitant">Gestion des sous-traitant</MenuItem>
                                                <MenuItem value="Gestion de la caisse">Gestion de la caisse</MenuItem>
                                            </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl  style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Accès aux projets</InputLabel>

                                        <Select 
                                            labelId="demo-multiple-checkbox-label"
                                            id="demo-multiple-checkbox"
                                            multiple
                                            value={projectsSelect}
                                            input={<OutlinedInput label="Accès aux projets" />}
                                            renderValue={(selected) => displaythem(selected)}
                                            sx={{bgcolor:'#e9eff6'}}
                                            onChange={this.handleChangeAcces}
                                            MenuProps={MenuProps}
                                        >
                                            {projects.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>
                                                    <Checkbox checked={projectsSelect.indexOf(answer.id) > -1} sx={{color:'#2f4cdd', '&.Mui-checked': {color:'#2f4cdd'}}} />
                                                    <ListItemText primary={answer.name} />
                                                  </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:4,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                                Cliquer pour modifier le compte
                        </Button>
                    </form>

                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >
                        Ajouté avec succès
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >
                        Erreur d'enregistrement
                    </Alert>
                </Snackbar> 
            </React.Fragment>  
        )
    }
}