import React, { Component } from "react";
import TextField from '@mui/material/TextField';
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import logo from "../../Assets/Images/logo.png"
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import background from "../../Assets/Images/login-bg.png"
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';


const theme = createTheme();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Login extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",

            isConnected: false,
            isError: false,
            loader: false,
            showPassword:false,
            forget: false,
            emailnotexist: false,
            emailexist: false,
            message: ""
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitreset = this.handleSubmitreset.bind(this)
    }

    componentDidMount() { localStorage.clear()}

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({loader: true })

        const email = this.state.email

        const password = this.state.password
    
        axios({
            method: 'post',
            responseType: 'json',
            url: 'https://api-gn.projectmanagementsolutions.net/api/login', 
            data: {
                email,
                password
            }
        }).then(res => {
            localStorage.setItem("USER", JSON.stringify(res.data.user));
            localStorage.setItem("TOKEN", JSON.stringify(res.data.access_token));
            localStorage.setItem("ROLE", JSON.stringify(res.data.user.role));
            localStorage.setItem("PROJETS", JSON.stringify(res.data.user.projet));
            localStorage.setItem("isAuthUser", true)
            this.setState({ isConnected: true})
            setTimeout(() => {
                window.location.href = '/dashboard';                
            }, 500);

        }).catch(error => {
            this.setState({ isError: true, loader: false  })
        });
    }

    handleSubmitreset(event){
        event.preventDefault();
        this.setState({loader: true })

        const email = this.state.email
    
        axios({
            method: 'post',
            responseType: 'json',
            url: 'https://api-gn.projectmanagementsolutions.net/api/resetpassword', 
            data: {
                email
            }
        }).then(res => {
            console.log("res.data", res.data)
            if(res.data.message === "email doesnt exist"){
                this.setState({
                    emailnotexist: true,
                    message: "Cette adresse n'est pas valide ou n'est pas associée à un compte d'utilisateur personnel.",
                    loader: false
                })
            }else{
                this.setState({
                    emailexist: true,
                    message: "Nous venons d'envoyer votre code d'authentification par e-mail à " + email,
                    loader: false
                })

                setTimeout(() => {this.setState({forget: false})}, 5000);
            }
        }).catch(error => {
            console.log("error",  error.response.data)
            this.setState({
                emailnotexist: true,
                message: "Cette adresse n'est pas valide ou n'est pas associée à un compte d'utilisateur personnel.",
                loader: false
            })
        });
    }

    render() {

        const { loader, isConnected, isError, showPassword, forget, emailnotexist, emailexist, message} = this.state

        return (
            <ThemeProvider theme={theme}>
                <Grid 
                    container 
                    component={Paper}
                    sx={{ 
                        height: '100vh',
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top',
                    }}
                    className="first-main"
                >
                    <CssBaseline />

                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={6}
                    >
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid 
                                item 
                                xs={false} 
                                sm={false} 
                                md={4}
                            />

                            <Grid 
                                item 
                                xs={12} 
                                sm={12} 
                                md={8}
                            >
                                <Box
                                    sx={{
                                    pt: 8,
                                    mx: 4,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'left',
                                    }}
                                >
                                    <div style={{backgroundColor:'white', borderRadius:16, width: 204, padding:2}}>
                                        <img src={logo} alt="main_logo" style={{ width: 200 }} />
                                    </div>                                    

                                    <Typography component="h1" variant="h1" sx={{color:"white", fontWeight: 400, fontSize:64, pt:10}}>
                                        Un logiciel pensé pour simplifier la gestion des entreprises du Bâtiment et des Travaux Publics (BTP)
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>  
                    </Grid>                    

                    <Grid item xs={12} sm={8} md={6}   elevation={0} square>
                        {!forget ?
                            <Box pt={20}  ml={16}>
                                <Typography component="h1" variant="h1" sx={{color:"black", fontWeight: 700, fontSize:48 }}>
                                    Project</Typography>
                                <Typography component="h1" variant="h1" sx={{color:"black", fontWeight: 700, fontSize:48}}>
                                    Management</Typography>
                                <Typography component="h1" variant="h1" sx={{color:"black", fontWeight: 700, fontSize:48}}>
                                Solutions
                                </Typography>

                                <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{ mt: 4, width:400 }}>
                                    <div className="card" style={{width: '100%', borderRadius:16}}>
                                        <TextField
                                            //margin="normal"
                                            required
                                            fullWidth
                                            label="Adresse email"
                                            name="email"
                                            placeholder="exemple@gmail.xyz"
                                            autoComplete="email"
                                            autoFocus
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>

                                    <div className="card" style={{width: '100%',borderRadius:16, marginTop:32 }}>
                                        <TextField
                                            //margin="normal"
                                            required
                                            fullWidth
                                            id="outlined-required"
                                            label="Password"
                                            name="password"
                                            placeholder="Admin2022$"
                                            onChange={this.handleChange}
                                            type={showPassword ? 'text' : 'password'}
                                            autoComplete="current-password"
                                            sx={{bgcolor:'#e9eff6'}}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => this.setState({ showPassword:! this.state.showPassword})} 
                                                        >
                                                            {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                        </IconButton>
                                                    </InputAdornment>                                        
                                            }}
                                        />
                                    </div>

                                    <Card elevation={24}  sx={{p:0, mt: 5, borderRadius:3}}>
                                        <Button                                    
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ textTransform: 'none', fontSize:16, height:50, boxShadow: 12, borderRadius:2, background:'linear-gradient(to right, #7435ff, #306bff)' }}
                                            endIcon={ loader ? <CircularProgress size={24} ml={2} sx={{color:'white'}} value={100} /> : null}
                                            disabled={loader}
                                        >
                                            Connexion
                                        </Button> 
                                    </Card>

                                    <Grid container mt={4}>
                                        <Grid item xs>
                                            <Link 
                                                variant="subtitle1"
                                                underline="none"
                                                onClick={() => this.setState({forget: true})}
                                                sx={{cursor: 'pointer'}}
                                            >
                                                Mot de passe oublié ?
                                            </Link>
                                        </Grid>
                                    </Grid>                        

                                    <Typography variant="body2" color="text.secondary"  sx={{position: 'fixed', bottom:64}} >
                                        Copyright © {new Date().getFullYear()} Project Management Solutions. Tous droits réservés.
                                    </Typography>
                                </Box>
                            </Box>
                        :
                            <Box pt={20}  ml={16}>
                                <img src={logo} alt="main_logo" style={{ width: 200 }} />                                

                                <Box component="form" noValidate onSubmit={this.handleSubmitreset} sx={{ mt: 4, width:400 }}>
                                    <Typography 
                                        variant="subtitle1" 
                                        color="text.secondary"  
                                        sx={{
                                            mt:2
                                            //position: 'fixed', bottom:64
                                        }}
                                    >
                                        Merci de renseigner votre adresse e-mail. 
                                        Vous recevrez un e-mail contenant les instructions vous permettant de réinitialiser votre mot de passe.
                                    </Typography>

                                    <div className="card" style={{width: '100%', borderRadius:16, marginTop:32}}>
                                        <TextField
                                            //margin="normal"
                                            required
                                            fullWidth
                                            label="Adresse email"
                                            name="email"
                                            placeholder="exemple@gmail.xyz"
                                            autoComplete="email"
                                            autoFocus
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>

                                    <Card elevation={24}  sx={{p:0, mt: 5, borderRadius:3}}>
                                        <Button                                    
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ textTransform: 'none', fontSize:16, height:50, boxShadow: 12, borderRadius:2, background:'linear-gradient(to right, #7435ff, #306bff)' }}
                                            endIcon={ loader ? <CircularProgress size={24} ml={2} sx={{color:'white'}} value={100} /> : null}
                                            disabled={loader}
                                        >
                                            Générer un mot de passe
                                        </Button> 
                                    </Card>
                                </Box>
                            </Box>
                        }
                    </Grid> 
                </Grid>

                <Grid container  component={Paper} sx={{ height: '100vh' }} className="second-main">
                    <CssBaseline />

                    <Grid item xs={12} sm={12} md={12}  elevation={6} square>
                        <Box
                            sx={{
                            py: 24,
                            mx: 16,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            }}
                        >
                            <img src={logo} alt="main_logo" style={{ width: 200 }} />

                            <Typography component="h1" variant="h1" sx={{color:"black", fontWeight: 400, fontSize:24, mt:6}}>
                                Project management solutions, Un logiciel pensé pour simplifier la gestion des entreprises du Bâtiment et des Travaux Publics (BTP)
                            </Typography>

                            <Box component="form" noValidate onSubmit={this.handleSubmit} sx={{mt:4, width:'100%' }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Adresse email"
                                    name="email"
                                    placeholder="exemple@gmail.xyz"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={this.handleChange}
                                    sx={{bgcolor:'#e9eff6'}}
                                />

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="Password"
                                    name="password"
                                    placeholder="Admin2022$"
                                    onChange={this.handleChange}
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="current-password"
                                    sx={{bgcolor:'#e9eff6'}}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => this.setState({ showPassword:! this.state.showPassword})} 
                                                >
                                                    {showPassword ? <VisibilityOff sx={{color:'#94a5b9'}} /> : <Visibility sx={{color:'#94a5b9'}} />}
                                                </IconButton>
                                            </InputAdornment>                                        
                                    }}
                                />

                                <Button                                    
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ textTransform: 'none', mt: 2, fontSize:16, height:50, boxShadow: 12, borderRadius:2, background:'linear-gradient(to right, #7435ff, #306bff)' }}
                                    endIcon={ loader ? <CircularProgress size={24} ml={2} sx={{color:'white'}} value={100} /> : null}
                                    disabled={loader}

                                >
                                    Connexion
                                </Button>                                

                                <Typography variant="body2" color="text.secondary"  sx={{position: 'fixed', bottom:64}} >
                                    Copyright © {new Date().getFullYear()} Project Management Solutions. Tous droits réservés.
                                </Typography>
                            </Box>

                        </Box>

                    </Grid>
                </Grid>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isConnected} autoHideDuration={6000} onClose={() => this.setState({ isConnected: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isConnected: false })} >
                        vous êtes connecté(e)
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isError} autoHideDuration={6000} onClose={() => this.setState({ isError: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isError: false })} >
                        Email et/ou mot de passe incorrect(s)
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={emailnotexist} autoHideDuration={10000} onClose={() => this.setState({ emailnotexist: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ emailnotexist: false })} >
                        {message}
                    </Alert>
                </Snackbar>    

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={emailexist} autoHideDuration={10000} onClose={() => this.setState({ emailexist: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ emailexist: false })} >
                        {message}
                    </Alert>
                </Snackbar>                 
            </ThemeProvider>
        )
    }
}