import React, { Component } from "react";
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Dropzone from 'react-dropzone'
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProjetMenu  from "../../Layouts/ProjetMenu";
import FolderIcon from '@mui/icons-material/Folder';
import Timeline from '@mui/lab/Timeline';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import DescriptionIcon from '@mui/icons-material/Description';
import Link from '@mui/material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Filess extends Component { 

    constructor(props) {
        super(props)
        this.state = {
            projects: [],
            folders: [],
            fichiers: [],

            loading: true,

            foldername: "",
            filename: "",

            id_folder: null,

            addFolder: false,
            adddoc: false,

            addfiles: false,

            projetId: this.props.projetId,

            selectedFile: null,
            path:"",

            isDid:false,
            isNotDit:false,
            message:'',
        }
        this.idchange = this.idchange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitFile = this.handleSubmitFile.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {       
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        axios.get('https://api-gn.projectmanagementsolutions.net/api/folders', config)
        .then(response => {
            this.setState({ 
                projects: response.data.projects,
                folders: response.data.folders,
                fichiers: response.data.fichiers,
                loading: false
            });
        }).catch(error => {
            alert(error);
        });
    }

    idchange(id){ 
        this.setState({
            projetId: id
        })
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event){
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        const bodyParameters = {
            id_projet: this.state.projetId, 
            name: this.state.foldername, 
        };  

        axios.post( 
            'https://api-gn.projectmanagementsolutions.net/api/folder',
            bodyParameters,
            config
        ).then(response => {       
            console.log("return", response.data)     
            this.setState({ message:"Nouveau dossier ajouté avec succès", isDid: true, addFolder:false });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur d'enregistrement", isNotDit: true });
            console.log("error", error)
        });
    }

    handleSubmitFile(event){
        event.preventDefault()
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        if(this.state.selectedFile === null){
            this.setState({ message:"Veuillez ajouter un fichier", isNotDit: true });
        }else if(this.state.filename === ""){
            this.setState({ message:"Veuillez ajouter un nom", isNotDit: true });
        }else if(this.state.id_folder === null){
            this.setState({ message:"Veuillez selectionner un dossier", isNotDit: true });
        }else{

            const bodyParameters = new FormData();
            bodyParameters.append("file", this.state.selectedFile)
            bodyParameters.append("name", this.state.filename)
            bodyParameters.append("id_folder", this.state.id_folder)

            axios.post('https://api-gn.projectmanagementsolutions.net/api/file', bodyParameters, config)
            .then(response => {       
                console.log("return", response.data)     
                this.setState({ message:"Fichier ajouté avec succès", adddoc: false, isDid: true});
                this.componentDidMount()            
            }).catch(error => {
                this.setState({ message:"Erreur d'enregistrement", isNotDit: true });
                console.log("error", error.response.data)
            })
        }

    }

    editTable(props){        
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        const bodyParameters = {
            name: props.value, 
        }  

        axios.put( 
            'https://api-gn.projectmanagementsolutions.net/api/folder/' + props.id,
            bodyParameters,
            config
        ).then(response => {                     
            this.setState({ message:"Dossier modifié avec succès", isDid: true, folders:[]});
            this.componentDidMount()          
        }).catch(error => {
            this.setState({ message:"Erreur dans le modification", isNotDit: true });
            console.log("error", error)
        });    
        
    }

    lookthis(id) {
        const folder = this.state.folders.filter(c => c["id"] === id)
        this.setState({
            id_folder: id,
            foldername: folder[0].name,
            addfiles: true
        })
    }

    fileDrop(acceptedFiles){ this.setState({selectedFile: acceptedFiles[0],  path: acceptedFiles[0].path}) }
    
    delete(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/folder/' + id,
            config
        ).then(response => {     
            this.setState({message:"Dossier supprimé avec succès", isDid: true });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur de suppression", isNotDit: true });
            console.log("error", error)
        });    

    }

    deletefile(id){
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            'https://api-gn.projectmanagementsolutions.net/api/file/' + id,
            config
        ).then(response => {     
            this.setState({message:"Dossier supprimé avec succès", isDid: true });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message:"Erreur de suppression", isNotDit: true });
            console.log("error", error)
        });    

    }

    render() {

        const {
            folders,
            projects,
            fichiers,
            addFolder,
            path,
            adddoc,
            addfiles,
            foldername,
            filename,
            id_folder,
            loading,
            projetId,
            isDid,
            isNotDit,
            message,
        } = this.state

        const id_project = projetId

        let folder = []
        if(id_project === null){
            folder = []
        }else{
            folder = folders.filter(c => c["id_projet"] === id_project)
        }

        let fichier = []
        if(id_folder === null){
            fichier = []
        }else{
            fichier = fichiers.filter(c => c["id_folder"] === id_folder)
        }

        const columns = [
            { 
                field: 'name', 
                headerName: 'Dossier',
                editable: true,
                flex: 1,
                renderCell: (cellValues) => { 
                    return ( 
                        <React.Fragment>
                            <FolderIcon 
                                onClick={() => { this.lookthis(cellValues.row.id) }}
                                //onClick={() => this.setState({ addfiles: true })}
                                sx={{ cursor: 'pointer', fontSize: 40, color: '#4777f4', mr:1 }} 
                            /> 
                            {cellValues.row.name}
                        </React.Fragment>
                    )
                }
            },
            { 
                field: 'updated_at', 
                headerName: 'Modifié',
                headerAlign: 'center',
                align:'center',
                width: 200,
                renderCell: (cellValues) => { return new Date(cellValues.row.updated_at).toLocaleString("lookup")}
            },
            { 
                field: 'delete', 
                headerName: 'Supprimer',
                headerAlign: 'center',
                align:'center',
                width: 120,
                renderCell: (cellValues) => {
                    return (
                        <Button
                            onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.delete(cellValues.row.id) }}}
                            variant="contained"
                            sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                        >
                            Supprimer
                        </Button>
                    )
                    
                }
            }

        ]        

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1, mb:2}} container spacing={3} >
                    <Grid item md={addfiles ? 9 : 12} >
                        <Grid sx={{ flexGrow: 1}} container spacing={3} >
                            <Grid item xs={12} sm={6} md={10} >
                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50}} 
                                    startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                                    onClick={() => this.setState({ addFolder: true })}
                                >
                                    Ajouter un dossier
                                </Button>

                                <Button 
                                    variant="contained" 
                                    sx={{bgcolor:'#2f4cdd', textTransform: 'none', fontSize:16, height:50, ml:3}} 
                                    startIcon={<Avatar  sx={{ bgcolor: '#4777f4', height:28, width:28 }}><AddIcon sx={{fontSize:26}} /></Avatar>}
                                    onClick={() => this.setState({ adddoc: true })}
                                >
                                    Ajouter un document
                                </Button>
                            </Grid>                    

                            <Grid item xs={12} sm={6} md={2} >
                                <ProjetMenu projetId={id_project} onChange={this.idchange} />
                            </Grid>

                            {projetId  === null ?
                                <Grid item xs={12} sm={12} md={12} >
                                    <div className="card" style={{ height: 630 }} >
                                        <h3 style={{margin:"auto", fontSize:"32px !important"}}>Veuillez choisir un projet</h3>
                                    </div>
                                </Grid>
                            :
                                <Grid item xs={12} sm={6} md={12} >
                                    <div className="card" style={{ height: 570, width: '100%',borderRadius:16 }}>
                                        <DataGrid
                                            rows={folder}
                                            columns={columns}
                                            onCellEditCommit={(props, event) => this.editTable(props)}
                                            loading={loading}
                                            elevation={10}
                                            sx={{
                                                boxShadow: 2,
                                                borderRadius:4,
                                                '.MuiDataGrid-columnSeparator': {
                                                    display: 'none',
                                                    },
                                                    '&.MuiDataGrid-root': {
                                                    border: 'none',
                                                    },
                                                '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                                    color:'black',
                                                    fontWeight: 600,
                                                    fontSize:18
                                                }
                                            }}
                                        
                                        /> 
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    {addfiles && 
                        <Grid item md={2.8} sx={{bgcolor:'white', ml:2, borderRadius:4,  boxShadow: 2,}} >
                            <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:0}} >
                                <Box style={{display: 'flex', flexDirection:'column'}} >
                                    <FolderIcon 
                                        sx={{ cursor: 'pointer', fontSize: 75, color: '#4777f4'}} 
                                    /> 

                                    <Typography 
                                        component="h1" 
                                        variant="h1" 
                                        sx={{color:"#313339", fontSize:26}}
                                    >
                                        {foldername}
                                    </Typography>
                                </Box>

                                <IconButton onClick={() =>  this.setState({ addfiles: false })} sx={{width:20, height:20, m:2}}>   
                                    <CloseIcon /> 
                                </IconButton>
                            </Box>

                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{textAlign:'left'}}>Fichier</th>
                                        <th scope="col">Ouvrir</th>
                                        <th scope="col">Supprimer</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {fichier.map((el, index) => 
                                        <tr>
                                            <th scope="row">
                                                {el.name}
                                            </th>

                                            <td style={{textAlign:'center'}}>
                                                <a  href={"https://api-gn.projectmanagementsolutions.net/backup/docs/" + el.url} target={"_blank"} style={{textDecoration: 'none'}}>
                                                    <VisibilityIcon sx={{ fontSize: 32, color: '#4777f4' }} /> 
                                                </a>
                                            </td>
                                            
                                            <td style={{textAlign:'center'}}>
                                                <Button
                                                    onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')){ this.deletefile(el.id) }}}
                                                    variant="contained"
                                                    sx={{background:'#eff0f2', color:'black', height:30, ':hover':{background:'#e6374c', color:'white'}}}
                                                >
                                                    Supprimer
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>         
                            </table>

                            <Timeline sx={{ [`& .${timelineItemClasses.root}:before`]: { flex: 0, padding: 0 } }} >

                                
                                {/* {fichier.map((el, index) => 

                                    
                                    // <TimelineItem>
                                    //     <TimelineSeparator>
                                    //         <TimelineDot />
                                    //         {index !== (fichier.length - 1) && <TimelineConnector /> }
                                    //     </TimelineSeparator>

                                    //     <TimelineContent>
                                    //         <Link color="inherit" href={"https://api-gn.projectmanagementsolutions.net/backup/docs/" + el.url} target={"_blank"} style={{textDecoration: 'none', }}>
                                    //             {el.name}
                                    //         </Link>
                                    //     </TimelineContent>
                                    // </TimelineItem>
                                )}                    */}
                            </Timeline>
                        </Grid>
                    }
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={addFolder}
                    onClose={() => this.setState({ addFolder: false })}      
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#fafafa'}}}                  
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32, paddingBottom:0}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouveau dossier
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ addFolder: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmit}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nom du dossier"
                                            name="foldername"
                                            placeholder="Banque"
                                            style={{ width: '100%' }}
                                            value={foldername}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                                Cliquer pour enregistrer le nouveau dossier
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={adddoc}
                    onClose={() => this.setState({ adddoc: false })}      
                    sx={{'.css-tlc64q-MuiPaper-root-MuiDialog-paper':{bgcolor:'#fafafa'}}}                  
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32, paddingBottom:0}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Nouveau fichier
                        </Typography>

                        <IconButton onClick={() =>  this.setState({ adddoc: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form role="form" className="text-start" onSubmit={this.handleSubmitFile}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>                                
                                <Grid item xs={12} md={12}>
                                    <div className="input-group input-group-outline mb-3">
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Nom du fichier"
                                            name="filename"
                                            placeholder="Banque"
                                            style={{ width: '100%' }}
                                            value={filename}
                                            onChange={this.handleChange}
                                            sx={{bgcolor:'#e9eff6'}}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12}>                                    
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label">Dossier</InputLabel>
                                            <Select
                                                value={id_folder}
                                                label="Dossier"
                                                name="id_folder"
                                                style={{ width: '100%' }}
                                                onChange={this.handleChange}
                                            > 
                                                {folder.map((item) => {
                                                    return <MenuItem value={item.id}>{item.name}</MenuItem>
                                                })}
                                            </Select>
                                    </FormControl>
                                </Grid> 

                                <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                    <Dropzone onDrop={acceptedFiles  => this.fileDrop(acceptedFiles)}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {path === "" ? 
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                            <p>Faites glisser et déposez le fichier ici, ou cliquez pour sélectionner le fichier</p>
                                                        </React.Fragment>
                                                    :
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  />
                                                            <p>{path}</p>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>

                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#2f4cdd'} 
                            }}
                            endIcon={<ChevronRightIcon sx={{fontSize:20}} />}
                        >
                                Cliquer pour ajouter le nouveau fichier
                        </Button>
                    </form>
                </Dialog>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )

    }

}

